import { sharedTracker } from '../Tracker'
import events from '../events'

export const trackLearnMoreClickedInAdvanceUploadsModal = () => sharedTracker
	.trackUploadAction( { action: events.uploads.ADVANCE_UPLOADS_LEARN_MORE_CLICKED } );

export const trackCancelClickedInAdvanceUploadsModal = () => sharedTracker
	.trackUploadAction( { action: events.uploads.ADVANCE_UPLOADS_CANCEL_CLICKED } );

export const trackContinueClickedInAdvanceUploadsModal = (
	{ newAssetsCount, newRoundsCount }: { newRoundsCount: number, newAssetsCount: number }
) => sharedTracker
	.trackUploadAction( {
		action: events.uploads.ADVANCE_UPLOADS_CONTINUE_CLICKED,
		new_asssets: newAssetsCount,
		new_rounds: newRoundsCount
	} );
