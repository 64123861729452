import { useState, useEffect, useCallback, useMemo } from 'react';
import NotificationPreferences from '@notifications/models/NotificationPreferences';
import useFetchNotificationPreferencesQuery from '@notifications/queries/useFetchNotificationPreferencesQuery';
import useUpdateNotificationPreferencesMutation from '@notifications/queries/useUpdateNotificationPreferencesMutation';
import { debounce } from 'throttle-debounce';

type NotificationPreferencesProps = {
	inAppNotifications?: string[],
	emailNotifications?: string[],
	emailFrequency?: { value: string, label: string }
};

const useNotificationSettings = () => {
	const { data, isLoading } = useFetchNotificationPreferencesQuery();
	const { mutate } = useUpdateNotificationPreferencesMutation();

	const mutateWithDebounce = useCallback( debounce( 1000, mutate ), [] );

	const [ inAppActiveNotifications, setInAppActiveNotifications ] = useState<string[]>(
		data?.inAppNotifications ?? []
	);
	const [ emailActiveNotifications, setEmailActiveNotifications ] = useState<string[]>(
		data?.emailNotifications ?? []
	);
	const [ emailSendFrequency, setEmailSendFrequency ] = useState(
		data?.emailFrequency ?? NotificationPreferences.DEFAULT_EMAIL_FREQUENCY
	);

	useEffect( () => {
		if ( data ) {
			setInAppActiveNotifications( data.inAppNotifications );
			setEmailActiveNotifications( data.emailNotifications );
			setEmailSendFrequency( data.emailFrequency );
		}
	}, [ data?.id ] );

	const updateNotificationPreferences = useCallback( ( {
		inAppNotifications, emailNotifications, emailFrequency
	} : NotificationPreferencesProps ) => {
		if ( !data ) return;

		mutateWithDebounce( new NotificationPreferences( {
			id: data.id,
			notificationAlarms: inAppNotifications || data.inAppNotifications,
			emailAlarms: emailNotifications || data.emailNotifications,
			emailEvery: emailFrequency?.value || data.emailFrequency.value
		} ) );
	}, [ data?.id ] );

	const toggleInAppNotification = useCallback( ( notification: string ) => {
		setInAppActiveNotifications( ( prevNotifications ) => {
			const newNotifications = prevNotifications.includes( notification )
				? prevNotifications.filter( n => n !== notification )
				: [ ...prevNotifications, notification ];

			updateNotificationPreferences( { inAppNotifications: newNotifications } );
			return newNotifications;
		} );
	}, [ updateNotificationPreferences ] );

	const toggleEmailNotification = useCallback( ( notification: string ) => {
		setEmailActiveNotifications( ( prevNotifications ) => {
			const newNotifications = prevNotifications.includes( notification )
				? prevNotifications.filter( n => n !== notification )
				: [ ...prevNotifications, notification ];

			updateNotificationPreferences( { emailNotifications: newNotifications } );
			return newNotifications;
		} );
	}, [ updateNotificationPreferences ] );

	const onEmailFrequencyChange = useCallback( ( value: { value: string, label: string } ) => {
		setEmailSendFrequency( value );
		updateNotificationPreferences( { emailFrequency: value } );
	}, [ updateNotificationPreferences ] );

	const notificationOptions = NotificationPreferences.NOTIFICATION_OPTIONS;

	const commentsNotificationProps = useMemo( () => [
		{
			title: 'General Comments',
			description: 'When someone comments on an asset',
			inAppActive: inAppActiveNotifications.includes( notificationOptions.newComment ),
			emailActive: emailActiveNotifications.includes( notificationOptions.newComment ),
			onInAppToggle: () => toggleInAppNotification( notificationOptions.newComment ),
			onEmailToggle: () => toggleEmailNotification( notificationOptions.newComment )
		},
		{
			title: 'Comment Replies',
			description: 'When someone replies to your comment',
			inAppActive: inAppActiveNotifications.includes( notificationOptions.newReply ),
			emailActive: emailActiveNotifications.includes( notificationOptions.newReply ),
			onInAppToggle: () => toggleInAppNotification( notificationOptions.newReply ),
			onEmailToggle: () => toggleEmailNotification( notificationOptions.newReply )
		}
	], [ inAppActiveNotifications, emailActiveNotifications ] );

	const assetsNotificationProps = useMemo( () => [
		{
			title: 'Approvals',
			description: 'When an asset is marked as approved',
			inAppActive: inAppActiveNotifications.includes( notificationOptions.assetApproved ),
			emailActive: emailActiveNotifications.includes( notificationOptions.assetApproved ),
			onInAppToggle: () => toggleInAppNotification( notificationOptions.assetApproved ),
			onEmailToggle: () => toggleEmailNotification( notificationOptions.assetApproved )
		},
		{
			title: 'New Rounds',
			description: 'When new rounds are added to an asset',
			inAppActive: inAppActiveNotifications.includes( notificationOptions.newRound ),
			emailActive: emailActiveNotifications.includes( notificationOptions.newRound ),
			onInAppToggle: () => toggleInAppNotification( notificationOptions.newRound ),
			onEmailToggle: () => toggleEmailNotification( notificationOptions.newRound )
		},
		{
			title: 'Priority',
			description: 'When assets are set as a priority',
			inAppActive: inAppActiveNotifications.includes( notificationOptions.setPriority ),
			emailActive: emailActiveNotifications.includes( notificationOptions.setPriority ),
			onInAppToggle: () => toggleInAppNotification( notificationOptions.setPriority ),
			onEmailToggle: () => toggleEmailNotification( notificationOptions.setPriority )
		},
		{
			title: 'New Uploads',
			description: 'When new assets are uploaded to a project',
			inAppActive: inAppActiveNotifications.includes( notificationOptions.newUpload ),
			emailActive: emailActiveNotifications.includes( notificationOptions.newUpload ),
			onInAppToggle: () => toggleInAppNotification( notificationOptions.newUpload ),
			onEmailToggle: () => toggleEmailNotification( notificationOptions.newUpload )
		}
	], [ inAppActiveNotifications, emailActiveNotifications ] );

	const teamUpdatesNotificationProps = useMemo( () => [
		{
			title: 'Project Collaborator',
			description: 'When a new collaborator is added to a project',
			inAppActive: inAppActiveNotifications.includes( notificationOptions.newCollaborator ),
			emailActive: emailActiveNotifications.includes( notificationOptions.newCollaborator ),
			onInAppToggle: () => toggleInAppNotification( notificationOptions.newCollaborator ),
			onEmailToggle: () => toggleEmailNotification( notificationOptions.newCollaborator )
		},
		{
			title: 'Guest Link',
			description: 'When someone enters your project through a guest link',
			inAppActive: inAppActiveNotifications.includes( notificationOptions.newGuestView ),
			emailActive: emailActiveNotifications.includes( notificationOptions.newGuestView ),
			onInAppToggle: () => toggleInAppNotification( notificationOptions.newGuestView ),
			onEmailToggle: () => toggleEmailNotification( notificationOptions.newGuestView )
		}
	], [ inAppActiveNotifications, emailActiveNotifications ] );

	const emailFrequencyProps = useMemo( () => (
		{
			options: NotificationPreferences.FREQUENCY_OPTIONS,
			selected: emailSendFrequency,
			onChange: onEmailFrequencyChange
		}
	), [ emailSendFrequency ] );

	return {
		commentsNotificationProps,
		assetsNotificationProps,
		teamUpdatesNotificationProps,
		emailFrequencyProps,
		isLoading
	};
};

export default useNotificationSettings;
