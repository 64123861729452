import { QueryClient, useQuery } from '@tanstack/react-query';
import useProjectSystem from '@projects/systems/useProjectSystem';
import { QueryKeys } from './queryKeys';
import { DEFAULT_QUERY_OPTIONS } from './constants';
import FolderTree from '../models/folderTree';
import FolderTreeJson from '../models/folderTreeJson';

const useFetchFolderTree = ( projectID: number ) => {
	const system = useProjectSystem();

	return useQuery(
		QueryKeys.forFolderTree( projectID ),
		() => system.fetchFolderTree( projectID ),
		{ ...DEFAULT_QUERY_OPTIONS, select: FolderTree.fromJson }
	);
}

export default useFetchFolderTree;

export const getFolderFromTree = ( queryClient: QueryClient, projectID: number, folderID: number ) => {
	const folderTree = queryClient.getQueryData(
		QueryKeys.forFolderTree( projectID )
	) as FolderTreeJson | undefined;

	if ( !folderTree ) return null;

	return FolderTree.fromJson( folderTree ).findFolder( folderID );
}
