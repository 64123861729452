import moment from 'moment';
import { AuthProvider } from '../../modules/authentication/models';
import UserPreferences, { UserPreferencesJson } from '../userPreferences/userPreferences';

type UserParams = {
	id: number,
	name: string,
	email: string,
	avatar: string,
	intercomHash: string,
	authProvider: AuthProvider,
	preferences?: UserPreferences,
	isGuest: boolean,
	role: string,
	intendedUsage: string,
	referralSource: string,
	createdAt: Date,
	projectsOnoboardingWasClosed?: boolean
}

export type UserJson = {
	id: number,
	name: string,
	email: string,
	avatar: string,
	intercom_hash: string,
	user_preference?: UserPreferencesJson,
	auth_provider: AuthProvider,
	is_guest: boolean,
	role: string,
	intended_usage: string,
	referral_source: string,
	created_at: string,
	projects_onboarding_was_closed?: boolean
}

export default class User {
	public id: number;
	public name: string;
	public email: string;
	public avatar: string;
	public intercomHash: string;
	public preferences?: UserPreferences;
	public authProvider: AuthProvider;
	public isGuest: boolean;
	public role: string;
	public intendedUsage: string;
	public referralSource: string;
	public createdAt: Date;
	public projectsOnoboardingWasClosed: boolean;

	constructor( {
		id,
		name,
		email,
		avatar,
		intercomHash,
		preferences,
		authProvider,
		isGuest,
		role,
		intendedUsage,
		referralSource,
		createdAt,
		projectsOnoboardingWasClosed = false
	} : UserParams ) {
		this.id = id;
		this.name = name;
		this.email = email;
		this.avatar = avatar;
		this.intercomHash = intercomHash;
		this.preferences = preferences;
		this.authProvider = authProvider;
		this.isGuest = isGuest;
		this.role = role;
		this.intendedUsage = intendedUsage;
		this.referralSource = referralSource;
		this.createdAt = createdAt;
		this.projectsOnoboardingWasClosed = projectsOnoboardingWasClosed;
	}

	get is2FAEnabled() {
		return this.preferences?.is2FAEnabled;
	}

	get firstName() {
		return this.name.split( ' ' )[ 0 ];
	}

	get isNew() {
		return moment( this.createdAt ).isAfter( moment().subtract( 1, 'week' ) );
	}

	get preferedAssetDownloadsResolution() {
		return this.preferences?.assetDownloadsResolution;
	}

	get isLinkedToExternalAuthProvider() {
		return this.authProvider !== AuthProvider.Email;
	}

	get missingOnboardingAnswers() {
		return !this.role || !this.intendedUsage || !this.referralSource;
	}

	updateProjectsOrder( projectsOrder: number[] ) {
		if ( !this.preferences ) return;
		this.preferences.projectsOrder = projectsOrder;
	}

	setIs2FAEnabled( is2FAEnabled: boolean ) {
		if ( !this.preferences ) return;
		this.preferences.is2FAEnabled = is2FAEnabled;
	}

	static fromJson( properties: UserJson ) {
		return new User( {
			id: properties.id,
			name: properties.name,
			email: properties.email,
			avatar: properties.avatar,
			intercomHash: properties.intercom_hash,
			preferences: properties.user_preference
				? UserPreferences.fromJson( properties.user_preference )
				: undefined,
			authProvider: properties.auth_provider,
			isGuest: properties.is_guest,
			role: properties.role,
			intendedUsage: properties.intended_usage,
			referralSource: properties.referral_source,
			createdAt: new Date( properties.created_at ),
			projectsOnoboardingWasClosed: properties.projects_onboarding_was_closed || false
		} );
	}

	toJson() {
		return {
			id: this.id,
			name: this.name,
			email: this.email,
			avatar: this.avatar,
			intercom_hash: this.intercomHash,
			auth_provider: this.authProvider,
			is_guest: this.isGuest,
			role: this.role,
			intended_usage: this.intendedUsage,
			referral_source: this.referralSource,
			created_at: this.createdAt.toISOString(),
			projects_onboarding_was_closed: this.projectsOnoboardingWasClosed
		};
	}
}
