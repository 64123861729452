import { useCallback, useMemo } from 'react';

import { Node, TreeView, useTree } from '../../../../../components/TreeView';
import FolderTreeNode from '../FolderTreeNode/FolderTreeNode';
import { FolderTreeViewProps } from '../types';

const FolderTreeView = ( {
	folderTree,
	initiallyExpandedFolderIDs = [],
	onFolderExpandedChanged = () => {},
	selectedFolderID,
	onFolderClick = () => {},
	rightCaptionRenderer = () => null,
	renderFolderIf = () => true
}: FolderTreeViewProps ) => {
	const treeData = useMemo(
		() => folderTree.treeDataWithoutRoot( { includeFolderIf: renderFolderIf } ),
		[ folderTree, renderFolderIf ]
	);
	const tree = useTree( treeData, {
		initiallyOpenNodes: initiallyExpandedFolderIDs,
		onNodeOpenChanged: node => onFolderExpandedChanged( node.id, node.open )
	} );

	const renderNode = useCallback( ( node: Node ) => (
		<FolderTreeNode
			treeViewNode={node}
			selected={node.id === selectedFolderID}
			onClick={onFolderClick}
			rightCaption={rightCaptionRenderer( node.id )}
		/>
	), [ selectedFolderID, onFolderClick ] );

	return <TreeView tree={tree} renderNode={renderNode} />
}

export default FolderTreeView;
