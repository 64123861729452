import MatchedFilename from './MatchedFilename';

export type MatchType = 'strict' | 'suffix' | 'extension' | 'base';
export type FilenameWithMatchJSON = {
	filename: string;
	matched_by: MatchType;
	matched_with: {
		filename: string;
		id: number,
		thumbnail_url: string
	}
}

type FilenameWithMatchAttributes = {
	filename: string
	matchedBy: string
	match: { filename: string, id: number, thumbnailURL: string }
}

export default class FilenameWithMatch {
	private _filename: MatchedFilename;
	public matchedBy: string
	public match: { filename: string, id: number, thumbnailURL: string }

	constructor( {
		filename,
		matchedBy,
		match
	}: FilenameWithMatchAttributes ) {
		this._filename = new MatchedFilename( filename );
		this.matchedBy = matchedBy;
		this.match = match;
	}

	get filename() {
		return this._filename.full;
	}

	get matchedText() {
		return this.matchedBy === 'strict'
			? this._filename.full
			: this._filename.base;
	}

	get unmatchedText() {
		return this._filename.full.replace( this.matchedText, '' )
	}

	static fromJson( { filename, matched_by, matched_with }: FilenameWithMatchJSON ) {
		return new FilenameWithMatch( {
			filename,
			matchedBy: matched_by,
			match: {
				filename: matched_with.filename,
				id: matched_with.id,
				thumbnailURL: matched_with.thumbnail_url
			}
		} );
	}
}
