import { useHistory } from 'react-router';

const useNavigationPanelIsAvailable = () => {
	const { location: { pathname } } = useHistory();

	const onProjectDashboard = pathname === '/projects';
	const onHiddenProjects = pathname === '/hidden_projects';
	const onProjectView = pathname.startsWith( '/projects' ) && !pathname.includes( 'assets' );

	return onProjectDashboard || onHiddenProjects || onProjectView;
}

export default useNavigationPanelIsAvailable;
