import { useRouteMatch } from 'react-router';

const toIntOrNull = ( value?: string ) => ( value ? parseInt( value, 10 ) : null );

const useOrganizerFromPath = () => {
	// useParams is a simpler alternative but it returns
	// an empty object because the project tree is rendered
	// outside the <Switch/> with the routes.
	const projectMatch = useRouteMatch<{ projectID: string }>( '/projects/:projectID' );
	const folderMatch = useRouteMatch<{ folderID: string }>( '/projects/:projectID/folders/:folderID' );

	const projectID = toIntOrNull( projectMatch?.params?.projectID );
	const folderID = toIntOrNull( folderMatch?.params?.folderID );

	if ( !projectID ) return undefined;

	return { projectID, folderID };
}

export default useOrganizerFromPath;
