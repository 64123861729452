import { makeAutoObservable } from 'mobx';
import Node from './Node';
import { type TreeData } from './types';

export type TreeOptions = {
	initiallyOpenNodes?: number[],
	onNodeOpenChanged?: ( node: Node ) => void
}

export default class Tree {
	nodes: Node[] = [];
	onNodeOpenChanged: TreeOptions[ 'onNodeOpenChanged' ];

	private nodesById = new Map<number, Node>();

	constructor( data: TreeData, { initiallyOpenNodes, onNodeOpenChanged }: TreeOptions ) {
		this.onNodeOpenChanged = onNodeOpenChanged;
		this.update( data );

		initiallyOpenNodes?.forEach( ( nodeId ) => {
			const node = this.getNode( nodeId );
			node?.toggleOpen();
			node?.openClosedAncestors();
		} );

		makeAutoObservable( this );
	}

	getNode( id: number ) {
		return this.nodesById.get( id );
	}

	isOpen( id: number ) {
		return this.getNode( id )?.open;
	}

	update( data: TreeData ) {
		const openNodeIds = Array
			.from( this.nodesById.keys() )
			.filter( id => this.isOpen( id ) );

		this.nodes = data.map( node => new Node( node, null, this.onNodeOpenChanged ) );
		this.buildMapOfNodesById();

		// Restore the open/closed status of the previous nodes
		openNodeIds.forEach( id => this.nodesById.get( id )?.toggleOpen() );
	}

	deepForEach( callback: ( node: Node ) => void ) {
		this.nodes.forEach( node => node.deepForEach( callback ) );
	}

	private buildMapOfNodesById() {
		this.nodesById.clear();
		this.deepForEach( node => this.nodesById.set( node.id, node ) );
	}
}
