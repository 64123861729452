import FileSelection from './FileSelection';

export const openFileSelector = ( { multiple, directory, accept, onChange } ) => {
	const input = document.createElement( 'input' );
	input.type = 'file';
	input.multiple = multiple;
	input.webkitdirectory = directory;
	input.accept = accept;
	input.addEventListener( 'change', ( event ) => {
		const fileSelectionBuilder = directory
			? FileSelection.fromHTMLInputDirectory
			: FileSelection.fromHTMLInputFiles;

		onChange( fileSelectionBuilder( event.target.files ) );
	} );
	document.body.addEventListener( 'focus', () => input.parentNode.removeChild( input ) );
	input.click();
};
