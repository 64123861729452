import AsyncActionPresenter from '../presenters/AsyncActionPresenter/AsyncActionPresenter';
import SnackbarFactory from './SnackbarFactory';
import PopupFactory from './PopupFactory';
import {
	isItemNamesTakenInProjectError,
	isItemNamesTakenInFolderError
} from '../errors/assetUploads';
import { DELETION_CHANNEL } from '../actions/snackbars';

export default class AsyncActionPresenterFactory {
	static forDeleteAsset( {
		asset, assetSystem, popupSystem, snackbarSystem, onAssetDeleted
	} ) {
		return new AsyncActionPresenter( {
			action: () => assetSystem.deleteAsset( asset ),
			resetAction: assetSystem.resetDeleteAssetsRequest,
			confirmationPopup: () => PopupFactory.deleteAssetConfirmationPopup( popupSystem ),
			successMessage: () => SnackbarFactory.forDeleteItemsSuccess( { itemsCount: 1, snackbarSystem } ),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem ),
			onSuccess: onAssetDeleted
		} );
	}

	static forDeleteCurrentRound( {
		asset, deleteCurrentRound, popupSystem, snackbarSystem
	} ) {
		return new AsyncActionPresenter( {
			action: () => deleteCurrentRound( asset ),
			confirmationPopup: () => PopupFactory.deleteCurrentRoundConfirmationPopup( popupSystem ),
			successMessage: () => SnackbarFactory.forDeleteCurrentRoundSuccess( snackbarSystem ),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem )
		} );
	}

	static forEnable2FA( {
		email, userPreferencesSystem, popupSystem, snackbarSystem
	} ) {
		return new AsyncActionPresenter( {
			action: () => userPreferencesSystem.enable2FA(),
			confirmationPopup: () => PopupFactory.enable2FAConfiramtionPopup( { popupSystem, email } ),
			successMessage: () => SnackbarFactory.for2FAEnabled( snackbarSystem ),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem )
		} );
	}

	static forDisable2FA( {
		userPreferencesSystem, snackbarSystem
	} ) {
		return new AsyncActionPresenter( {
			action: () => userPreferencesSystem.disable2FA(),
			successMessage: () => SnackbarFactory.for2FADisabled( snackbarSystem ),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem )
		} );
	}

	static forBuyStorageAddOn( {
		addOn, addOnPrice, addOnSystem, popupSystem, snackbarSystem, description, formatedIncludedStorage,
		workspace
	} ) {
		return new AsyncActionPresenter( {
			action: () => addOnSystem.buyAddOn( { addOn, addOnPrice, workspace } ),
			confirmationPopup: () => PopupFactory
				.buyStorageAddOnConfirmationPopup( { popupSystem, description } ),
			successMessage: () => SnackbarFactory
				.forBuyStorageAddOnSuccess( { snackbarSystem, formatedIncludedStorage } ),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem )
		} );
	}

	static forRemoveStorageAddOn( {
		addOnPrice, addOnSystem, popupSystem, snackbarSystem,
		description, formatedStorageToBeRemoved, workspaceID, onError
	} ) {
		return new AsyncActionPresenter( {
			action: () => addOnSystem.removeAddOn( { addOnPriceID: addOnPrice.id, workspaceID } ),
			confirmationPopup: () => PopupFactory
				.removeStorageAddOnConfirmationPopup( { popupSystem, description } ),
			successMessage: () => SnackbarFactory
				.forRemoveStorageAddOnSuccess( { snackbarSystem, formatedStorageToBeRemoved } ),
			onError
		} );
	}

	static forBuyMembersAddOn( {
		addOn, addOnPrice, addOnSystem, popupSystem, snackbarSystem, description, workspace
	} ) {
		return new AsyncActionPresenter( {
			action: () => addOnSystem.buyAddOn( { addOn, addOnPrice, workspace } ),
			confirmationPopup: () => PopupFactory
				.buyMembersAddOnConfirmationPopup( { popupSystem, description } ),
			successMessage: () => SnackbarFactory.forBuyMembersAddOnSuccess( snackbarSystem ),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem )
		} );
	}

	static forRemoveMembersAddOn( {
		addOnPrice, addOnSystem, popupSystem, snackbarSystem,
		description, workspaceID, onError
	} ) {
		return new AsyncActionPresenter( {
			action: () => addOnSystem.removeAddOn( { addOnPriceID: addOnPrice.id, workspaceID } ),
			confirmationPopup: () => PopupFactory
				.removeMembersAddOnConfirmationPopup( { popupSystem, description } ),
			successMessage: () => SnackbarFactory
				.forRemoveMembersAddOnSuccess( { snackbarSystem } ),
			onError
		} );
	}

	static forLeaveProjects( {
		popupSystem, snackbarSystem, leftProjectName, projectsIDs,
		leaveProjects, isLeavingMultiple, onSuccess
	} ) {
		return new AsyncActionPresenter( {
			action: () => leaveProjects( projectsIDs ),
			confirmationPopup: () => PopupFactory
				.leaveProjectConfirmationPopup( { popupSystem, isLeavingMultiple } ),
			successMessage: () => SnackbarFactory
				.forLeaveProjectsSuccess( { snackbarSystem, leftProjectName, isLeavingMultiple } ),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem ),
			onSuccess
		} );
	}

	static forDeleteProjects( {
		popupSystem, snackbarSystem, projectsIDs,
		deleteProjects, isDeletingMultiple, onSuccess
	} ) {
		return new AsyncActionPresenter( {
			action: () => deleteProjects( projectsIDs ),
			confirmationPopup: () => PopupFactory.deleteProjectsConfirmationPopup(
				{ popupSystem, isDeletingMultiple }
			),
			successMessage: () => SnackbarFactory.forDeleteProjectsSuccess(
				{ snackbarSystem, isDeletingMultiple }
			),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem ),
			onSuccess,
			onStart: () => snackbarSystem.showLoadingMessage( {
				channel: DELETION_CHANNEL, texts: { title: `Deleting Project${isDeletingMultiple ? 's...' : '...'}` }
			} ),
			onFinish: () => snackbarSystem.dismissCurrentSnackbar( { channel: DELETION_CHANNEL } )
		} );
	}

	static forToggleProjectRushPriority( {
		toggleRushPriority, project, snackbarSystem
	} ) {
		return new AsyncActionPresenter( {
			action: () => toggleRushPriority( project.id ),
			successMessage: () => SnackbarFactory.forToggleRushPrioritySuccess( {
				snackbarSystem, hadRushPriority: project.hasRushPriority
			} ),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem )
		} );
	}

	static forToggleAssetRushPriority( {
		assetSystem, asset, snackbarSystem
	} ) {
		return new AsyncActionPresenter( {
			action: () => assetSystem.togglePriority( asset ),
			successMessage: () => SnackbarFactory.forToggleRushPrioritySuccess( {
				snackbarSystem, hadRushPriority: asset.hasRushPriority
			} ),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem )
		} );
	}

	static forCreateFolder( {
		folderSystem, onClose, params, snackbarSystem
	} ) {
		return new AsyncActionPresenter( {
			action: () => folderSystem.createFolder( params ),
			onSuccess: onClose,
			resetAction: folderSystem.resetCreateFolderRequest,
			successMessage: () => SnackbarFactory.forCreateFolderSuccess( snackbarSystem )
		} );
	}

	static forRenameFolder( {
		folderSystem, onClose, params, snackbarSystem
	} ) {
		return new AsyncActionPresenter( {
			action: () => folderSystem.renameFolder( params ),
			onSuccess: onClose,
			resetAction: folderSystem.resetRenameFolderRequest,
			successMessage: () => SnackbarFactory.forRenameFolderSuccess( snackbarSystem )
		} );
	}

	static forUnfoldItems( {
		unfoldItems, assets, folders, folder, project, popupSystem, snackbarSystem
	} ) {
		const assetCount = assets.length;
		const folderCount = folders.length;

		return new AsyncActionPresenter( {
			confirmationPopup: () => PopupFactory.unfoldItems( {
				assetCount,
				folderCount,
				folder,
				project,
				popupSystem
			} ),
			action: () => unfoldItems( { assets, folders } ),
			successMessage: ( response ) => {
				if ( !response?.payload?.meta?.duplicated_names ) {
					SnackbarFactory.forUnfoldItemsSuccess( {
						project, assetCount, folderCount, snackbarSystem
					} );
				} else {
					PopupFactory.cannotUnfoldItemsWithDuplicateNames( popupSystem );
				}
			},
			errorMessage: error => (
				isItemNamesTakenInProjectError( error.payload )
					? PopupFactory.cannotUnfoldItemsWithDuplicateNames( popupSystem )
					: SnackbarFactory.defaultErrorMessage( snackbarSystem )
			)
		} );
	}

	static forMoveItemsIntoFolder( {
		folderSystem, folder, assets, folders, snackbarSystem, onSuccess, onError, isFiltering
	} ) {
		return new AsyncActionPresenter( {
			action: () => folderSystem.moveItemsIntoFolder( { assets, folder, folders, isFiltering } ),
			successMessage: ( response ) => {
				if ( !response?.payload?.meta?.duplicate_names ) {
					SnackbarFactory.forMoveItemsSuccess( {
						destinationName: folder.name,
						assetsCount: assets.length,
						foldersCount: folders.length,
						snackbarSystem
					} )
				}
			},
			errorMessage: ( error ) => {
				if ( !isItemNamesTakenInFolderError( error.payload ) ) {
					SnackbarFactory.defaultErrorMessage( snackbarSystem );
				}
			},
			onSuccess,
			onError
		} );
	}

	static forDeleteItems( {
		projectID, folderIDs, assetIDs, folderSystem, assetSystem, popupSystem, snackbarSystem
	} ) {
		const itemsCount = folderIDs.length + assetIDs.length;

		return new AsyncActionPresenter( {
			action: () => Promise.all( [
				folderSystem.deleteFolders( { projectID, folderIDs } ),
				assetSystem.deleteAssets( { projectID, assetIDs } )
			] ),
			confirmationPopup: () => PopupFactory.deleteItemsConfirmationPopup( { itemsCount, popupSystem } ),
			successMessage: () => SnackbarFactory.forDeleteItemsSuccess( { itemsCount, snackbarSystem } ),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem ),
			onStart: () => snackbarSystem.showLoadingMessage( { channel: DELETION_CHANNEL, texts: { title: 'Deleting Items...' } } ),
			onFinish: () => snackbarSystem.dismissCurrentSnackbar( { channel: DELETION_CHANNEL } )
		} );
	}

	static forChangeFolderCover( {
		assetID, folder, folderSystem, snackbarSystem
	} ) {
		return new AsyncActionPresenter( {
			action: () => folderSystem.changeCoverImage( { assetID, folder } ),
			successMessage: () => SnackbarFactory.forChangeCoverImageSuccess( snackbarSystem ),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem )
		} );
	}

	static forDeleteContactGroup( {
		workspaceID, contactGroup, workspaceSystem, popupSystem, snackbarSystem
	} ) {
		return new AsyncActionPresenter( {
			action: () => workspaceSystem.deleteContactGroup( workspaceID, contactGroup.id ),
			confirmationPopup: () => PopupFactory.deleteContactGroupConfirmationPopup( popupSystem ),
			successMessage: () => SnackbarFactory.forDeleteContactGroupSuccess( snackbarSystem ),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem )
		} );
	}

	static forDeleteLabelGroup( {
		workspaceID, labelGroup, workspaceSystem, popupSystem, snackbarSystem
	} ) {
		return new AsyncActionPresenter( {
			action: () => workspaceSystem.deleteLabelGroup( workspaceID, labelGroup.id ),
			confirmationPopup: () => PopupFactory.deleteLabelGroupConfirmationPopup( popupSystem ),
			successMessage: () => SnackbarFactory.forDeleteLabelGroupSuccess( snackbarSystem ),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem )
		} );
	}

	static forDeleteLastRoundsOfAssets( {
		projectID, assets, assetSystem, snackbarSystem, popupSystem
	} ) {
		const assetCount = assets.length;

		return new AsyncActionPresenter( {
			action: () => assetSystem.deleteAssetsLastRound( projectID, assets ),
			confirmationPopup: () => PopupFactory.deleteAssetsLastRoundConfirmationPopup( popupSystem, assetCount ),
			successMessage: () => SnackbarFactory.forDeleteAssetsLastRoundSuccess( snackbarSystem ),
			errorMessage: () => PopupFactory.deleteAssetsLastRoundErrorPopup( popupSystem )
		} );
	}

	static forMuteNotificationsForProjects( {
		projectIDs, muteProjectsNotificationsAction, snackbarSystem
	} ) {
		return new AsyncActionPresenter( {
			action: () => muteProjectsNotificationsAction( projectIDs ),
			successMessage: () => SnackbarFactory.forMuteNotificationsForProjectsSuccess(
				{ snackbarSystem, projectsCount: projectIDs.length }
			),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem )
		} );
	}

	static forUnmuteNotificationsForProjects( {
		projectIDs, unmuteProjectsNotificationsAction, snackbarSystem
	} ) {
		return new AsyncActionPresenter( {
			action: () => unmuteProjectsNotificationsAction( projectIDs ),
			successMessage: () => SnackbarFactory.forUnmuteNotificationsForProjectsSuccess(
				{ snackbarSystem, projectsCount: projectIDs.length }
			),
			errorMessage: () => SnackbarFactory.defaultErrorMessage( snackbarSystem )
		} );
	}
}
