import Skeleton from '@material-ui/lab/Skeleton';

import './SkeletonLoader.scss';

const SkeletonLoader = () => (
	<div className="SkeletonLoader" data-testid="navigation-panel-loader">
		<Skeleton variant="rect" animation="wave" className="skeleton-header" />
		<Skeleton variant="rect" animation="wave" className="skeleton-content" />
	</div>
);

export default SkeletonLoader;
