import { sharedTracker } from '../Tracker'
import events from '../events'

export const trackGuestLinkSettingsChanged = (
	{ projectID, enabled }: { projectID: number, enabled: boolean }
) => sharedTracker
	.trackNACAction( {
		action: events.nac.GUEST_LINK_SETTINGS_CHANGED,
		project_id: projectID,
		enabled
	} );

export const trackGuestLinkOpened = (
	{ projectID, guestHash, isGuest }: { projectID: number, guestHash: string, isGuest?: boolean }
) => sharedTracker
	.trackNACAction( {
		action: events.nac.GUEST_LINK_OPENED,
		project_id: projectID,
		guest_hash: guestHash,
		is_guest: isGuest
	} );

export const trackCredentialsPromptCompleted = (
	{ name, email }: { name: string, email: string }
) => sharedTracker
	.trackNACAction( {
		action: events.nac.CREDENTIALS_PROMPT_COMPLETED,
		name,
		email
	} );
