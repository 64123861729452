import Button from 'src/new_arch/components/Button/Button';
import User from 'src/new_arch/models/user/user';
import OptionBox from './OptionBox';

import './OnboardingSurveyStep.scss';

type Option = { value: string, label: string }

type OnboardingSurveyStepProps = {
	user: User,
	stepNumber: number,
	title: string,
	description?: string,
	options: Option[],
	selectedOption: string,
	onOptionSelected: ( option: string ) => void,
	onContinue: () => void,
	isLoading: boolean,
	optionBoxWidth: number,
	optionBoxHeight: number
}

const OnboardingSurveyStep = ( {
	user,
	stepNumber,
	title,
	description,
	options,
	selectedOption,
	onOptionSelected,
	onContinue,
	isLoading,
	optionBoxWidth,
	optionBoxHeight
}: OnboardingSurveyStepProps ) => {
	const welcomeMessage = `${user.isNew ? 'Welcome' : 'Hi'} ${user.firstName}`;
	const noOptionSelectedYet = selectedOption === '';

	return (
		<div className="OnboardingSurveyStep">
			<div className="header">
				<h4 className="welcome-message">{welcomeMessage}</h4>
				<h3 className="question">{title}</h3>
				{description && <h4 className="reason">{description}</h4>}
			</div>
			<div className="options-outer">
				<div className="options">
					{
						options.map( ( { value, label } ) => (
							<OptionBox
								key={value}
								value={value}
								label={label}
								onSelect={onOptionSelected}
								isSelected={selectedOption === value}
								width={optionBoxWidth}
								height={optionBoxHeight}
							/>
						) )
					}
				</div>
			</div>
			<div className="step-and-continue-button">
				<p><b>Step 0{stepNumber}</b> of 03</p>
				<Button
					type="filled"
					color="primary"
					disabled={noOptionSelectedYet || isLoading}
					onClick={onContinue}
				>
					Continue
				</Button>
			</div>
		</div>
	);
}

export default OnboardingSurveyStep;
