import { Center, Button, Stack, Text } from '@mantine/core';

import './EmptyState.scss';

const EmptyState = ( {
	isFiltering, onResetFiltersClick
}: { isFiltering: boolean, onResetFiltersClick?: () => void } ) => {
	const text = isFiltering
		? 'You don\'t have any notifications that match the selected filters.'
		: 'You have no notifications here yet.';

	return (
		<Center className="EmptyState" w="100%" h="100%">
			<Stack maw={isFiltering ? 220 : 160}>
				<Text align="center">{text}</Text>
				{isFiltering && (
					<Button
						variant="subtle"
						size="xs"
						onClick={onResetFiltersClick}
					>
						Reset Filters
					</Button>
				)}
			</Stack>
		</Center>
	);
};

export default EmptyState;
