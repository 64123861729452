/* eslint-disable no-use-before-define */
import { makeAutoObservable } from 'mobx';
import { type NodeData } from './types';

export default class Node {
	id: number;
	name: string;
	children: Node[];
	parent: Node | null;
	onNodeOpenChanged?: ( node: Node ) => void;

	open = false;

	constructor(
		{ id, name, children = [] }: NodeData,
		parent: Node | null,
		onNodeOpenChanged?: ( node: Node ) => void
	) {
		this.id = id;
		this.name = name;
		this.children = children.map( child => new Node( child, this, onNodeOpenChanged ) );
		this.parent = parent;
		this.onNodeOpenChanged = onNodeOpenChanged;

		makeAutoObservable( this );
	}

	get hasChildren() {
		return this.children.length > 0;
	}

	deepForEach( callback: ( node: Node ) => void ) {
		callback( this );
		this.children.forEach( child => child.deepForEach( callback ) );
	}

	toggleOpen() {
		if ( this.hasChildren ) {
			this.open = !this.open;
			this.onNodeOpenChanged?.( this );
		}
	}

	openClosedAncestors() {
		if ( this.parent && !this.parent.open ) {
			this.parent.toggleOpen();
			this.parent.openClosedAncestors();
		}
	}
}
