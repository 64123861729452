import { CSSObject, ListStylesParams } from '@mantine/core';
import { MantineThemeComponent } from '../../types';

// Workaround to default styles in DesignSystem.scss that set
// ul and li list-style-type to none
const ListDefaultConfig: MantineThemeComponent = {
	defaultProps: {
		size: 'xs',
		listStyleType: 'disc'
	},
	styles: ( _theme, { listStyleType }: ListStylesParams ) => ( {
		root: {
			listStyle: `${listStyleType} !important`
		},
		item: {
			listStyle: `${listStyleType} !important`,
			listStylePosition: 'inside !important' as CSSObject[ 'listStylePosition' ]
		}
	} )
};

export default ListDefaultConfig;
