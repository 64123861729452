import Progress from '@ui/components/Progress/Progress';
import { displayTimeLeft } from 'src/lib/dateUtils';

import styles from './Header.module.scss';

type HeaderProps = {
	uploadingCount: number;
	uploadingProgress: number;
	uploadingETA: number;
};

const Header = ( {
	uploadingCount, uploadingProgress, uploadingETA
}: HeaderProps ) => (
	<div className={styles.Header}>
		{ uploadingCount === 0 ? (
			<h2>Upload Box</h2>
		) : (
			<>
				<div className={styles.Header__uploadingInfo}>
					<h2>Uploading {uploadingCount} files</h2>
					<p>{displayTimeLeft( uploadingETA )}</p>
				</div>
				<Progress value={uploadingProgress} className={styles.Header__uploadingProgress} size="sm" />
			</>
		)}
	</div>
);

export default Header;
