import { MutableRefObject, useEffect } from 'react';

const useMarginBottomOnElement = ( elementRef: MutableRefObject<HTMLDivElement | null>, marginBottom: number ) => {
	useEffect( () => {
		if ( !elementRef.current || marginBottom === 0 ) return;
		elementRef.current.style.marginBottom = `${marginBottom}px`;
	}, [ elementRef.current ] );
}

export default useMarginBottomOnElement;
