import FilenamesMatchResult from '@uploads/models/FilenamesMatchResult';
import { useCallback, useMemo, useState } from 'react';
import { trackCancelClickedInAdvanceUploadsModal, trackContinueClickedInAdvanceUploadsModal }
	from 'src/services/tracker/trackers/uploads';
import { CreateAction, FilenameWithAssetID, MatchWithAction } from './types';

type UseMatchedNewRoundsConfirmationPopup = {
	matchResult: FilenamesMatchResult,
	onConfirm?: ( selectedForNewRounds: FilenameWithAssetID[] ) => void,
	onCancel?: () => void
}

const useMatchedNewRoundsConfirmationPopup = ( {
	matchResult,
	onConfirm,
	onCancel
}: UseMatchedNewRoundsConfirmationPopup ) => {
	const partialMatchesCount = matchResult.partialMatches.length;
	const total = matchResult.totalFilenames;

	const [ matches, setMatches ] = useState<MatchWithAction[]>(
		matchResult.partialMatches.map( ( { filename, matchedText, unmatchedText, match } ) => ( {
			currentAsset: {
				id: match.id,
				filename: match.filename,
				thumbnailURL: match.thumbnailURL
			},
			newAssetFilename: filename,
			matchedText,
			unmatchedText,
			selectedAction: 'new-round'
		} ) )
	);

	const onActionChange = useCallback( ( assetFilename: string, action: CreateAction ) => {
		setMatches( prevMatches => prevMatches.map( match => (
			match.newAssetFilename === assetFilename ? { ...match, selectedAction: action } : match
		) ) )
	}, [] );

	const setAllAsNewAssets = useCallback( () => {
		setMatches( prevMatches => prevMatches.map( match => ( { ...match, selectedAction: 'new-asset' } ) ) );
	}, [] );

	const allSelectedAsNewAssets = useMemo( () => (
		matches.every( match => match.selectedAction === 'new-asset' )
	), [ matches ] );

	const onCancelation = useCallback( () => {
		trackCancelClickedInAdvanceUploadsModal();
		onCancel?.();
	}, [ onCancel ] );

	const onConfirmation = useCallback( () => {
		const newRounds: FilenameWithAssetID[] = matchResult.exactMatches.map(
			result => ( { filename: result.filename, assetID: result.match.id } )
		);

		matches.filter( match => match.selectedAction === 'new-round' ).forEach( ( match ) => {
			newRounds.push( { filename: match.newAssetFilename, assetID: match.currentAsset.id } );
		} );

		trackContinueClickedInAdvanceUploadsModal( {
			newRoundsCount: newRounds.length,
			newAssetsCount: matchResult.totalFilenames - newRounds.length
		} )
		onConfirm?.( newRounds );
	}, [ matches ] );

	return {
		partialMatchesCount,
		total,
		matches,
		onActionChange,
		onCancelation,
		setAllAsNewAssets,
		allSelectedAsNewAssets,
		onConfirmation
	};
};

export default useMatchedNewRoundsConfirmationPopup;
