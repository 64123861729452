import { Popover } from '@mantine/core';
import { usePrevious } from '@mantine/hooks';
import { Circle } from 'rc-progress';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IconButton from 'src/components/UI/IconButton/IconButton';
import Tooltip from 'src/components/UI/Tooltip/Tooltip';
import { getAssetActiveUploadsCount, getAssetsUploadsTotalProgress } from 'src/selectors/assetUploads';
import AssetUploadsBox from '../AssetUploadsBox/AssetUploadsBox';

import styles from './UploadsButton.module.scss';

type UploadsButtonProps = {
	projectID: number,
	folderID?: number
};

const UploadsButton = ( {
	projectID, folderID
}: UploadsButtonProps ) => {
	const [ isOpened, setIsOpened ] = useState( false );
	const totalProgress = useSelector( getAssetsUploadsTotalProgress );
	const assetActiveUploadsCount = useSelector( getAssetActiveUploadsCount );
	const prevActiveUploadsCount = usePrevious( assetActiveUploadsCount );

	const isUploadingAssets = assetActiveUploadsCount > 0;
	const toggle = useCallback( () => setIsOpened( prev => !prev ), [] );

	useEffect( () => {
		if ( prevActiveUploadsCount === 0 && assetActiveUploadsCount > 0 ) {
			setIsOpened( true );
		}
	}, [ assetActiveUploadsCount ] );

	return (
		<Tooltip title="Uploads" disabled={isOpened}>
			<Popover
				opened={isOpened}
				onClose={() => setIsOpened( false )}
				closeOnClickOutside
				position="bottom-end"
				withArrow
				keepMounted
				offset={10}
				radius={6}
				shadow="md"
			>
				<Popover.Target>
					<div className={styles.UploadsButton}>
						{ isUploadingAssets && (
							<Circle
								className={styles.UploadsButton__ProgressCircle}
								percent={totalProgress}
								strokeWidth={8}
								trailColor="#FFFFFF"
								strokeColor="#3F9CFF"
							/>
						) }
						<IconButton
							buttonClassName={styles.UploadsButton__Button}
							onClick={toggle}
							icon="publish_icon"
							size={18}
						/>
					</div>
				</Popover.Target>
				<Popover.Dropdown className={styles.UploadsButton__Popover}>
					<AssetUploadsBox
						projectID={projectID}
						folderID={folderID}
					/>
				</Popover.Dropdown>
			</Popover>
		</Tooltip>
	);
};

export default UploadsButton;
