type AssetRoundParams = {
	id: number,
	assetID: number,
	name: string,
	number: number,
	uploaderID: number,
	imageURL: string,
	thumbnailURL: string,
	lowQualityPlaceholderURL: string,
}

export type AssetRoundJSON = {
	id: number,
	asset_id: number,
	name: string,
	number: number,
	uploader: { id: number },
	image_url: string,
	thumbnail_url: string,
	lqip_url: string
};

class AssetRound {
	id: number;
	assetID: number;
	name: string;
	number: number;
	uploaderID: number;
	imageURL: string;
	thumbnailURL: string;
	lowQualityPlaceholderURL: string;

	constructor( params: AssetRoundParams ) {
		this.id = params.id;
		this.assetID = params.assetID;
		this.name = params.name;
		this.number = params.number;
		this.imageURL = params.imageURL;
		this.thumbnailURL = params.thumbnailURL;
		this.uploaderID = params.uploaderID;
		this.lowQualityPlaceholderURL = params.lowQualityPlaceholderURL;
	}

	static fromJSON( json: AssetRoundJSON ) {
		return new AssetRound( {
			id: json.id,
			assetID: json.asset_id,
			name: json.name,
			number: json.number,
			uploaderID: json.uploader && json.uploader.id,
			imageURL: json.image_url,
			thumbnailURL: json.thumbnail_url,
			lowQualityPlaceholderURL: json.lqip_url
		} );
	}

	toJson(): AssetRoundJSON {
		return {
			id: this.id,
			name: this.name,
			asset_id: this.assetID,
			number: this.number,
			uploader: { id: this.uploaderID },
			image_url: this.imageURL,
			thumbnail_url: this.thumbnailURL,
			lqip_url: this.lowQualityPlaceholderURL
		}
	}

	get isProcessing() {
		return !this.imageURL || !this.thumbnailURL;
	}

	get image() {
		return {
			fullQualityURL: this.imageURL,
			lowQualityPlaceholderURL: this.lowQualityPlaceholderURL
		};
	}

	belongsToAsset( assetID: number ) {
		return this.assetID === assetID;
	}
}

export default AssetRound;
