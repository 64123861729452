import { MantineTheme } from '@mantine/core';
import { MantineThemeComponent } from '../../types';

const colorFor = ( {
	variant, color, theme
}: { variant?: string, color: string, theme: MantineTheme } ) => (
	variant && [ 'light', 'outline' ].includes( variant )
		? theme.fn.themeColor( color, 7, true )
		: undefined
)

const bgColorFor = ( {
	variant, color, theme
}: { variant?: string, color: string, theme: MantineTheme } ) => (
	variant === 'light'
		? theme.fn.themeColor( color, 0, true )
		: undefined
)

const BadgeDefaultConfig: MantineThemeComponent = {
	defaultProps: {
		variant: 'light',
		size: 'sm',
		radius: 'sm'
	},
	styles: ( theme, { color }, { variant } ) => ( {
		root: {
			textTransform: 'none',
			color: colorFor( { variant, color, theme } ),
			backgroundColor: bgColorFor( { variant, color, theme } )
		},
		inner: {
			fontWeight: 400
		}
	} )
};

export default BadgeDefaultConfig;
