const Routes = Object.freeze( {
	toHome: '/',
	toSignIn: '/sign-in',
	toCreateAccount: '/create-account',
	toEmailVerification: '/email-verification',
	toProject: ( id: number | string ) => `/projects/${id}`,
	toWorkspace: ( id: number | string ) => `/user/workspaces/${id}`,
	toWorkspaceMembers: ( id: number | string ) => `/user/workspaces/${id}/members`,
	toWorkspaceSettings: ( id: number | string ) => `/user/workspaces/${id}/general`,
	toForgotPassword: '/forgot',
	toProfile: '/user/profile',
	toProfileNotifications: '/user/profile/notifications'
} );

export default Routes;
