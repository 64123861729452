import { MantineThemeComponent } from '../../types';

const TooltipDefaultConfig: MantineThemeComponent = {
	defaultProps: {
		arrowSize: 5,
		withArrow: true,
		arrowPosition: 'center',
		color: 'secondary.9',
		position: 'bottom',
		offset: 4
	}
};

export default TooltipDefaultConfig;
