import {
	ERROR_DEFAULT, DELETE_ITEMS_SUCCESS_DEFAULT, DELETE_ITEMS_SUCCESS_PLURAL, SUCCESS_DELETE_ROUND_DEFAULT
} from '../messages/assets';

export default class SnackbarFactory {
	static for2FADisabled = ( snackbarSystem ) => {
		snackbarSystem.showSuccessMessage( { title: 'Two-step verification deactivated' } );
	}

	static for2FAEnabled = ( snackbarSystem ) => {
		snackbarSystem.showSuccessMessage( { title: 'Two-step verification activated' } );
	}

	static forDownloadAssetSuccess = ( { snackbarSystem } ) => {
		snackbarSystem.showSuccessMessage( { title: 'Your download is ready!' } );
	}

	static forDeleteCurrentRoundSuccess = ( snackbarSystem ) => {
		snackbarSystem.showSuccessMessage( { title: SUCCESS_DELETE_ROUND_DEFAULT } );
	}

	static forBuyMembersAddOnSuccess = ( snackbarSystem ) => {
		snackbarSystem.showSuccessMessage( {
			title: 'Workspace seat added',
			description: 'You have added 1 seat to your workspace.'
		} );
	}

	static forBuyStorageAddOnSuccess = ( { snackbarSystem, formatedIncludedStorage } ) => {
		snackbarSystem.showSuccessMessage( {
			title: 'Additional storage added',
			description: `You have added ${formatedIncludedStorage} of storage to your workspace.`
		} );
	}

	static forRemoveStorageAddOnSuccess = ( { snackbarSystem, formatedStorageToBeRemoved } ) => {
		snackbarSystem.showSuccessMessage( {
			title: 'Additional storage removed',
			description: `You have removed ${formatedStorageToBeRemoved} of additional storage from your plan.`
		} );
	}

	static forRemoveMembersAddOnSuccess = ( { snackbarSystem } ) => {
		snackbarSystem.showSuccessMessage( {
			title: 'Workspace seat removed',
			description: 'You have removed a workspace seat from your plan.'
		} );
	}

	static forLeaveProjectsSuccess = ( { snackbarSystem, leftProjectName, isLeavingMultiple } ) => {
		snackbarSystem.showSuccessMessage( {
			title: `You have successfully left the project${isLeavingMultiple ? 's' : ''}`,
			description: `${isLeavingMultiple
				? 'The selected projects have'
				: `The project called ${leftProjectName} has`} been deleted from your projects.`
		} );
	}

	static forDeleteProjectsSuccess = ( { snackbarSystem, isDeletingMultiple } ) => {
		snackbarSystem.showSuccessMessage( {
			title: `The ${isDeletingMultiple ? 'projects were' : 'project was'} successfully deleted.`
		} );
	}

	static forSubscriptionPurchaseSuccess = ( snackbarSystem ) => {
		snackbarSystem.showSuccessMessage( {
			title: 'Successful Purchase',
			description: 'You can now enjoy our collaboration features to the fullest.'
		} );
	}

	static forDowngradeSubscriptionSuccess = ( snackbarSystem ) => {
		snackbarSystem.showSuccessMessage( {
			title: 'Subscription Renewal Stopped',
			description: 'You marked you wish to downgrade your subscription to Pro.'
		} );
	}

	static forCancelDowngradeSubscriptionSuccess = ( snackbarSystem ) => {
		snackbarSystem.showSuccessMessage( {
			title: 'Plan Restored',
			description: 'Your subscription to Team will be renewed at the end of your billing period.'
		} );
	}

	static defaultErrorMessage = ( snackbarSystem ) => {
		snackbarSystem.showErrorMessage( { title: ERROR_DEFAULT } );
	}

	static forToggleRushPrioritySuccess( { snackbarSystem, hadRushPriority } ) {
		snackbarSystem.showSuccessMessage( {
			title: hadRushPriority ? 'Priority flag removed' : 'Priority flag added'
		} );
	}

	static forCreateFolderSuccess = ( snackbarSystem ) => {
		snackbarSystem.showSuccessMessage( { title: 'The folder was successfully created' } );
	}

	static forRenameFolderSuccess = ( snackbarSystem ) => {
		snackbarSystem.showSuccessMessage( { title: 'Folder renamed' } );
	}

	static forUnfoldItemsSuccess = ( { project, assetCount, folderCount, snackbarSystem } ) => {
		const itemCount = assetCount + folderCount;

		if ( itemCount > 1 ) {
			return snackbarSystem.showSuccessMessage( { title: `Items moved up to ${project.name}` } );
		}
		return snackbarSystem.showSuccessMessage( { title: `The item moved up to ${project.name}` } );
	}

	static forMoveItemsSuccess = ( { destinationName, assetsCount = 0, foldersCount = 0, snackbarSystem } ) => {
		const itemsCount = assetsCount + foldersCount;
		const itemsText = itemsCount === 1 ? 'Item' : `${itemsCount} items`;
		const title = `${itemsText} moved to ${destinationName}`;
		return snackbarSystem.showSuccessMessage( { title } );
	}

	static forDeleteItemsSuccess = ( { itemsCount, snackbarSystem } ) => {
		snackbarSystem.showSuccessMessage( {
			title: itemsCount === 1 ? DELETE_ITEMS_SUCCESS_DEFAULT : DELETE_ITEMS_SUCCESS_PLURAL
		} );
	}

	static forChangeCoverImageSuccess = ( snackbarSystem ) => {
		snackbarSystem.showSuccessMessage( { title: 'Folder cover image changed successfully' } );
	}

	static forDeleteContactGroupSuccess = ( snackbarSystem ) => {
		snackbarSystem.showSuccessMessage( { title: 'Contact group deleted successfully' } );
	}

	static forDeleteLabelGroupSuccess = ( snackbarSystem ) => {
		snackbarSystem.showSuccessMessage( { title: 'Label group deleted successfully' } );
	}

	static forDeleteAssetsLastRoundSuccess = ( snackbarSystem ) => {
		snackbarSystem.showSuccessMessage( { title: 'The last round was successfully deleted' } );
	}

	static forMuteNotificationsForProjectsSuccess = ( { snackbarSystem, projectsCount } ) => {
		const firstSentence = projectsCount > 1 ? 'The projects were successfully muted' : 'The project was successfully muted';
		const title = `${firstSentence}. You'll be notified only when @mentioned.`;

		snackbarSystem.showSuccessMessage( { title } );
	}

	static forUnmuteNotificationsForProjectsSuccess = ( { snackbarSystem, projectsCount } ) => {
		const title = projectsCount > 1 ? 'The projects were successfully unmuted' : 'The project was successfully unmuted';

		snackbarSystem.showSuccessMessage( { title } );
	}
}
