import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePersistentState } from 'src/new_arch/hooks/usePersistentState';
import { trackNotificationsPanelOpened } from 'src/services/tracker/trackers/notifications';

import NotificationFilter from '@notifications/models/NotificationFilter';
import useMarkNotificationsAsSeenMutation from '@notifications/queries/useMarkNotificationsAsSeenMutation';

import useSelectedProjectID from './useSelectedProjectID';

type UseNotificationsSidePanelParams = {
	onClose?: () => void
}

const useNotificationsSidePanel = ( {
	onClose
}: UseNotificationsSidePanelParams ) => {
	const { selectedProjectID, onProjectClick } = useSelectedProjectID();
	const [ selectedFilter, setSelectedFilter ] = usePersistentState(
		'notifications-filter',
		NotificationFilter.none()
	);
	const {
		mutate: markNotificationsAsSeen
	} = useMarkNotificationsAsSeenMutation();

	const [ closing, setClosing ] = useState( false );
	const [ isInInitialPosition, setIsInInitialPosition ] = useState( true );

	const backdropStyle = useMemo( () => {
		if ( isInInitialPosition ) { return 'backdrop'; }
		return closing ? 'backdrop hide' : 'backdrop show';
	}, [ isInInitialPosition, closing ] );

	const panelStyle = useMemo( () => {
		if ( isInInitialPosition ) { return 'panel'; }
		return closing ? 'panel hide' : 'panel show';
	}, [ isInInitialPosition, closing ] );

	useEffect( () => {
		trackNotificationsPanelOpened();
		const initialPositionTimeout = setTimeout(
			() => { setIsInInitialPosition( false ); }, 10
		);

		return () => { clearTimeout( initialPositionTimeout ); };
	}, [] );

	useEffect( () => () => {
		if ( selectedProjectID ) { markNotificationsAsSeen( { projectID: selectedProjectID } ); }
	}, [ selectedProjectID ] );

	const onClosePanel = useCallback( () => setClosing( true ), [] );
	const onTransitionEnd = useCallback( () => { if ( closing ) { onClose?.(); } }, [ closing, onClose ] );
	const onResetFiltersClick = useCallback( () => setSelectedFilter( NotificationFilter.none ), [] );

	return {
		selectedProjectID,
		selectedFilter,
		onFilterChange: setSelectedFilter,
		onProjectClick,
		onResetFiltersClick,
		backdropStyle,
		panelStyle,
		onClosePanel,
		onTransitionEnd
	};
};

export default useNotificationsSidePanel;
