/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useCallback } from 'react';
import {
	createFileUploads as createFileUploadsAction,
	createFolderUploads as createFolderUploadsAction,
	checkAssetFilenamesMatches as checkAssetFilenamesMatchesAction
} from '../../actions/assetUploads';
import useReduxPopupSystem from './useReduxPopupSystem';
import openEmailClient from '../../services/openEmailClient';
import AssetUploadPresenter from '../../presenters/AssetUploadPresenter/AssetUploadPresenter';
import { openFileSelector } from '../../services/FileSelector/openFileSelector';

import { ASSET_ALL_EXTENSIONS } from '../modules/projectView/models/asset';
import { mapExtensionsForFileInput } from '../../lib/fileUtils';
import { getProject } from '../../selectors/projects';
import { getFolder } from '../../selectors/folders';

type UseReduxFileSelectorForAssetUploadParams = {
	projectID: number,
	folderID: number | null,
	directoryMode?: boolean
};

const useReduxFileSelectorForAssetUpload = ( {
	projectID, folderID = null
}: UseReduxFileSelectorForAssetUploadParams ) => {
	const history = useHistory();
	const popupSystem = useReduxPopupSystem();
	const dispatch = useDispatch();

	const createFileUploads = ( ...args: any[] ) => dispatch( createFileUploadsAction( ...args ) );
	const createFolderUploads = ( ...args: any[] ) => dispatch( createFolderUploadsAction( ...args ) );
	const checkAssetFilenamesMatches = ( ...args: any[] ) => dispatch( checkAssetFilenamesMatchesAction( ...args ) );
	// @ts-ignore
	const project = useSelector( ( state: any ) => getProject( state, { projectID } ) );
	// @ts-ignore
	const folder = useSelector( ( state: any ) => getFolder( state, { folderID } ) );

	return useCallback( ( { directoryMode = false } ) => {
		openFileSelector( {
			multiple: true,
			accept: mapExtensionsForFileInput( ASSET_ALL_EXTENSIONS ),
			directory: directoryMode,
			onChange: ( fileSelection: any ) => {
				new AssetUploadPresenter( {
					createFileUploads,
					createFolderUploads,
					checkAssetFilenamesMatches,
					fileSelection,
					history,
					openEmailClient,
					popupSystem,
					project,
					folder,
					uploadingRoundForAsset: false
				} as any ).trigger();
			}
		} );
	}, [ createFileUploads, createFolderUploads, history, popupSystem ] );
};

export default useReduxFileSelectorForAssetUpload;
