import PropTypes from 'prop-types';
import { Arrow as KonvaArrow } from 'react-konva';
import tinycolor from 'tinycolor2';

const MarkupArrow = ( {
	arrow, scale, opacity, strokeScale, ...props
} ) => {
	const color = tinycolor( arrow.strokeColor ).setAlpha( opacity ).toRgbString();

	return (
		<KonvaArrow
			points={[
				arrow.startPoint.x * scale.x,
				arrow.startPoint.y * scale.y,
				arrow.endPoint.x * scale.x,
				arrow.endPoint.y * scale.y
			]}
			pointerLength={10}
			pointerWidth={8}
			stroke={color}
			fill={color}
			strokeWidth={( arrow.strokeSize || 1 ) * strokeScale}
			hitStrokeWidth={20}
			perfectDrawEnabled={false}
			lineJoin="miter"
			lineCap="round"
			{...props}
		/>
	);
}

MarkupArrow.propTypes = {
	arrow: PropTypes.any.isRequired,
	scale: PropTypes.any.isRequired,
	strokeScale: PropTypes.number,
	opacity: PropTypes.number
};

MarkupArrow.defaultProps = {
	strokeScale: 1,
	opacity: 1
};

export default MarkupArrow;
