import { FilterOption } from 'src/new_arch/types';
import Notification, { NotificationType } from './Notification';

type NotificationID = 'none' | 'mentions' | 'comments' | 'replies' | 'uploaded-assets'
	| 'assets-priority' | 'assets-approved' | 'user-added' | 'round-added' | 'guest-view';

export interface NotificationFilterJSON extends Object {
	id: NotificationID;
}

type NotificationFilterAttributes = {
	id: NotificationID;
	label: string;
	type: NotificationType | null;
}

export default class NotificationFilter implements FilterOption {
	id: NotificationID;
	label: string;
	type: NotificationType | null;

	constructor( { id, type, label }: NotificationFilterAttributes ) {
		this.id = id;
		this.type = type;
		this.label = label;
	}

	get value() { return this.id; }
	get backendId() { return this.type; }
	get isBackendFilter() { return !!this.type; }

	isPassedBy( notification: Notification ) {
		return !this.type || notification.type === this.type;
	}

	static none = () => new NotificationFilter( {
		id: 'none',
		label: 'Reset filters',
		type: null
	} );

	static byMentions = () => new NotificationFilter( {
		id: 'mentions',
		label: '@ Mentions',
		type: 'mention'
	} );

	static byComments = () => new NotificationFilter( {
		id: 'comments',
		label: 'Comments',
		type: 'new_root_comment'
	} );

	static byReplies = () => new NotificationFilter( {
		id: 'replies',
		label: 'Replies',
		type: 'new_reply_comment'
	} );

	static byUploadedAssets = () => new NotificationFilter( {
		id: 'uploaded-assets',
		label: 'Uploaded Assets',
		type: 'asset_uploaded'
	} );

	static byFlaggedAssets = () => new NotificationFilter( {
		id: 'assets-priority',
		label: 'Priority',
		type: 'set_rush_priority'
	} );

	static byAssetsApproved = () => new NotificationFilter( {
		id: 'assets-approved',
		label: 'Approved Assets',
		type: 'asset_approved'
	} );

	static byUserAdded = () => new NotificationFilter( {
		id: 'user-added',
		label: 'Users Added',
		type: 'new_user'
	} );

	static byRoundAdded = () => new NotificationFilter( {
		id: 'round-added',
		label: 'Rounds Added',
		type: 'new_round'
	} );

	static byGuestView = () => new NotificationFilter( {
		id: 'guest-view',
		label: 'Guest Views',
		type: 'new_guest_view'
	} );

	static allOptions = () => [
		NotificationFilter.none(),
		NotificationFilter.byMentions(),
		NotificationFilter.byComments(),
		NotificationFilter.byReplies(),
		NotificationFilter.byUploadedAssets(),
		NotificationFilter.byFlaggedAssets(),
		NotificationFilter.byAssetsApproved(),
		NotificationFilter.byUserAdded(),
		NotificationFilter.byRoundAdded(),
		NotificationFilter.byGuestView()
	];

	static fromJSON( { id }: NotificationFilterJSON ) {
		return NotificationFilter.allOptions().find( option => option.id === id ) || NotificationFilter.none();
	}

	toJSON(): NotificationFilterJSON {
		return { id: this.id };
	}
}
