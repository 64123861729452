type NotificationPreferencesAttributes = {
	id: number,
	emailEvery: string,
	emailAlarms: string[],
	notificationAlarms: string[]
}

export type NotificationPreferencesJSON = {
	id: number,
	email_every: string,
	email_alarms: string[],
	notification_alarms: string[]
}

export default class NotificationPreferences {
	id: number;
	emailEvery: string;
	emailAlarms: string[];
	notificationAlarms: string[];

	constructor( {
		id,
		emailEvery,
		emailAlarms,
		notificationAlarms
	}: NotificationPreferencesAttributes ) {
		this.id = id;
		this.emailEvery = emailEvery;
		this.emailAlarms = this.ensureMentionsOption( emailAlarms );
		this.notificationAlarms = this.ensureMentionsOption( notificationAlarms );
	}

	get emailFrequency() {
		return NotificationPreferences.FREQUENCY_OPTIONS.find(
			option => option.value === this.emailEvery
		) || NotificationPreferences.DEFAULT_EMAIL_FREQUENCY;
	}

	get emailNotifications() {
		return this.emailAlarms;
	}

	get inAppNotifications() {
		return this.notificationAlarms;
	}

	static NOTIFICATION_OPTIONS = {
		newComment: 'new_root_comment',
		newReply: 'new_reply_comment',
		assetApproved: 'asset_approved',
		newRound: 'new_round',
		setPriority: 'set_rush_priority',
		newCollaborator: 'new_user',
		newGuestView: 'new_guest_view',
		newUpload: 'asset_uploaded',
		mentions: 'mention'
	};

	static FREQUENCY_OPTIONS = [
		{ value: 'once_per_day', label: 'Once per day' },
		{ value: 'once_per_hour', label: 'Once per hour' },
		{ value: 'every_quarter_hour', label: 'Every 15 minutes' }
	];

	static DEFAULT_EMAIL_FREQUENCY = NotificationPreferences.FREQUENCY_OPTIONS[ 0 ];

	static fromJSON( json: NotificationPreferencesJSON ): NotificationPreferences {
		return new NotificationPreferences( {
			id: json.id,
			emailEvery: json.email_every,
			emailAlarms: json.email_alarms,
			notificationAlarms: json.notification_alarms
		} );
	}

	toJSON(): NotificationPreferencesJSON {
		return {
			id: this.id,
			email_every: this.emailEvery,
			email_alarms: this.emailAlarms,
			notification_alarms: this.notificationAlarms
		};
	}

	private ensureMentionsOption( alarms: string[] ): string[] {
		const mentionOption = NotificationPreferences.NOTIFICATION_OPTIONS.mentions;

		if ( !alarms.includes( mentionOption ) ) {
			return [ ...alarms, mentionOption ];
		}

		return alarms;
	}
}
