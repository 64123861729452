type ProjectJSON = { id: number };
type AssetJSON = { id: number };
type CommentJSON = { id: number, text: string, asset_id: number };

export type RelatedObjectType = 'Asset' | 'Project' | 'Comment' | 'RootComment' | 'CommentReply';
export type RelatedObjectJSON<Type> = Type extends 'Asset'
	? AssetJSON : Type extends 'Project'
	? ProjectJSON : Type extends 'RootComment' | 'CommentReply'
	? CommentJSON : never;
type FactoryMethod<Type extends RelatedObjectType> =
	// eslint-disable-next-line no-use-before-define
	( projectID: number, relatedObjectJSON: RelatedObjectJSON<Type> ) => NotificationRelatedObject;

type NotificationRelatedObjectAttributes = {
	id: number;
	type: RelatedObjectType;
	assetID: number | null;
	text: string | null;
	url: string;
};

export default class NotificationRelatedObject {
	id: number;
	type: RelatedObjectType;
	assetID: number | null;
	text: string | null;
	url: string;

	constructor( { id, text, assetID, type, url }: NotificationRelatedObjectAttributes ) {
		this.id = id;
		this.assetID = assetID;
		this.text = text;
		this.type = type;
		this.url = url;
	}

	get isComment() {
		return this.type === 'Comment' || this.type === 'RootComment' || this.type === 'CommentReply';
	}

	toJSON() {
		return {
			id: this.id,
			type: this.type,
			text: this.text,
			url: this.url,
			asset_id: this.assetID
		};
	}

	static forProject( projectID: number ) {
		return new NotificationRelatedObject( {
			id: projectID,
			type: 'Project',
			text: null,
			assetID: null,
			url: `/projects/${projectID}`
		} );
	}

	static forAsset( projectID: number, { id }: AssetJSON ) {
		return new NotificationRelatedObject( {
			id,
			type: 'Asset',
			text: null,
			assetID: null,
			url: `/projects/${projectID}/assets/${id}`
		} );
	}

	static forComment( projectID: number, { id, text, asset_id }: CommentJSON ) {
		return new NotificationRelatedObject( {
			id,
			type: 'Comment',
			text,
			assetID: asset_id,
			url: `/projects/${projectID}/assets/${asset_id}`
		} );
	}

	static forRootComment( projectID: number, commentJSON: CommentJSON ) {
		return NotificationRelatedObject.forComment( projectID, commentJSON );
	}

	static forCommentReply( projectID: number, commentJSON: CommentJSON ) {
		return NotificationRelatedObject.forComment( projectID, commentJSON );
	}

	static fromJSON<Type extends RelatedObjectType>(
		projectID: number, relatedObjectType: Type, relatedObjectJSON: RelatedObjectJSON<Type>
	) {
		const factories: { [Key in RelatedObjectType]: FactoryMethod<Key> } = {
			Asset: NotificationRelatedObject.forAsset,
			Project: NotificationRelatedObject.forProject,
			Comment: NotificationRelatedObject.forComment,
			RootComment: NotificationRelatedObject.forRootComment,
			CommentReply: NotificationRelatedObject.forCommentReply
		};

		const factory = factories[ relatedObjectType ];
		if ( !factory ) { return null; }

		return factory( projectID, relatedObjectJSON );
	}
}
