import { useCallback } from 'react';
import { useHistory } from 'react-router';

import {
	trackNotificationFolderOpened, trackNotificationOpened, trackNotificationProjectOpened
} from 'src/services/tracker/trackers/notifications';

import Notification from '@notifications/models/Notification';
import NotificationFilter from '@notifications/models/NotificationFilter';
import useFetchProjectNotificationsQuery from '@notifications/queries/useFetchProjectNotificationsQuery';
import useMarkNotificationAsClickedMutation from '@notifications/queries/useMarkNotificationAsClickedMutation';

import { ESTIMATED_ROW_HEIGHT, heightForNotification } from './NotificationListItem/NotificationListItem';

const LOADING_SKELETON_COUNT = 3;

type UseNotificationsListParams = {
	projectID: number;
	selectedFilter: NotificationFilter;
};

const itemKeyExtractor = ( item: Notification ) => item.id;

const useNotificationsList = ( { projectID, selectedFilter }: UseNotificationsListParams ) => {
	const {
		data: notifications,
		hasNextPage,
		fetchNextPage,
		isLoading
	} = useFetchProjectNotificationsQuery( { projectID, selectedFilter } );
	const isFiltering = selectedFilter.id !== NotificationFilter.none().id;
	const loadingSkeletonCount = isLoading ? LOADING_SKELETON_COUNT : 0;
	const history = useHistory();
	const { mutate: markNotificationAsClicked } = useMarkNotificationAsClickedMutation();

	const onScrollEndReached = useCallback( () => {
		if ( hasNextPage && !isLoading ) fetchNextPage();
	}, [ hasNextPage, isLoading, fetchNextPage ] );

	const itemHeight = useCallback( ( index: number ) => (
		notifications?.[ index ]
			? heightForNotification( notifications[ index ] )
			: ESTIMATED_ROW_HEIGHT
	), [ notifications ] );

	const onNotificationClicked = useCallback(
		( notification: Notification ) => {
			trackNotificationOpened( notification );
			markNotificationAsClicked( { notification } );

			if ( notification.relatedObjectUrl ) {
				history.replace( notification.relatedObjectUrl );
			}
		},
		[ history ]
	);

	const onNotificationProjectClicked = useCallback(
		( notification: Notification ) => {
			trackNotificationProjectOpened( notification );
			history.replace( notification.projectUrl, { onOpen: true } );
		},
		[ history ]
	);

	const onNotificationFolderClicked = useCallback(
		( notification: Notification ) => {
			trackNotificationFolderOpened( notification );
			history.replace( notification.folderUrl, { onOpen: true } );
		},
		[ history ]
	);

	return {
		notifications,
		isFiltering,
		isLoading,
		loadingSkeletonCount,
		itemHeight,
		onScrollEndReached,
		itemKeyExtractor,
		onNotificationClicked,
		onNotificationProjectClicked,
		onNotificationFolderClicked
	};
};

export default useNotificationsList;
