import * as projectsActions from './projects';
import * as commentsActions from './comments';
import * as tasksActions from './tasks';
import * as authActions from './authentication';
import * as projectMembersActions from './projectMembers';
import * as workspaceMembersActions from './workspaceMembers';
import * as snackbarActions from './snackbars';
import * as markupEditorActions from './markupEditor';
import * as usersActions from './users';
import * as projectsViewOptionsActions from './projectsViewOptions';
import * as assetsViewOptionsActions from './assetsViewOptions';
import * as popupsActions from './popups';
import * as activitiesActions from './activities';
import * as assetRoundsActions from './assetRounds';
import * as assetUploads from './assetUploads';
import * as sharedProjectsActions from './sharedProjects';
import * as projectInvitationsActions from './projectInvitations';
import * as workspaceInvitationsActions from './workspaceInvitations';
import * as assetsFilterAndSortOptionsActions from './assetsFilterAndSortOptions';
import * as workspacesActions from './workspaces';
import * as workspaceProjectsActions from './workspaceProjects';
import * as workspaceInvoicesActions from './workspaceInvoices';
import * as pricingActions from './pricing';
import * as commentsFilterOptions from './commentsFilterOptions';
import * as closedBanners from './closedBanners';

export default {
	...projectsActions,
	...commentsActions,
	...tasksActions,
	...authActions,
	...projectMembersActions,
	...workspaceMembersActions,
	...snackbarActions,
	...markupEditorActions,
	...usersActions,
	...projectsViewOptionsActions,
	...assetsViewOptionsActions,
	...popupsActions,
	...activitiesActions,
	...assetRoundsActions,
	...assetUploads,
	...sharedProjectsActions,
	...projectInvitationsActions,
	...workspaceInvitationsActions,
	...assetsFilterAndSortOptionsActions,
	...workspacesActions,
	...workspaceProjectsActions,
	...workspaceInvoicesActions,
	...pricingActions,
	...commentsFilterOptions,
	...closedBanners
};
