import usePinProjectMutation from '@projects/queries/usePinProjectMutation';
import useUnpinProjectMutation from '@projects/queries/useUnpinProjectMutation';
import { ProjectTreeItem } from '@projects/models/projectTree';
import { type PinningConfig, type OrganizerAttributes, type OrganizerClickCallback } from '@navigationPanel/types';
import { trackProjectPinned, trackProjectUnpinned } from 'src/services/tracker/trackers/projects';
import { useCallback, useState } from 'react';

type UseProjectTreeNodeProps = {
	project: ProjectTreeItem,
	initiallyExpanded: boolean,
	onExpandedChanged: ( projectID: number, expanded: boolean ) => void,
	selectedOrganizer?: OrganizerAttributes,
	onOrganizerClick: OrganizerClickCallback,
	pinning: PinningConfig
}

const useProjectTreeNode = ( {
	project,
	initiallyExpanded,
	onExpandedChanged,
	selectedOrganizer,
	onOrganizerClick,
	pinning
}: UseProjectTreeNodeProps ) => {
	const [ expanded, setIsExpanded ] = useState( initiallyExpanded );
	const expanderIcon = expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right';
	const onExpanderClicked = ( event: React.MouseEvent<HTMLButtonElement, MouseEvent> ) => {
		event.stopPropagation();
		setIsExpanded( !expanded );
		onExpandedChanged( project.id, !expanded );
	}

	const { mutate: pinProject } = usePinProjectMutation( project.id );
	const { mutate: unpinProject } = useUnpinProjectMutation( project.id );
	const onPinnerClicked = ( event: React.MouseEvent<HTMLButtonElement, MouseEvent> ) => {
		if ( pinning !== 'available' ) return;
		event.stopPropagation();
		if ( project.isPinned ) {
			unpinProject();
			trackProjectUnpinned();
		} else {
			pinProject();
			trackProjectPinned();
		}
	}

	const selected = selectedOrganizer?.projectID === project.id && selectedOrganizer.folderID === null;
	const onClick = () => onOrganizerClick( { projectID: project.id, folderID: null } );

	const selectedFolderID = selectedOrganizer?.folderID || undefined;
	const onFolderClick = useCallback( ( folderID: number ) => onOrganizerClick( {
		projectID: project.id,
		folderID
	} ), [ onOrganizerClick, project.id ] );

	return {
		expanded,
		expanderIcon,
		selected,
		pinned: project.isPinned,
		onExpanderClicked,
		onPinnerClicked,
		onClick,
		selectedFolderID,
		onFolderClick
	}
}

export default useProjectTreeNode;
