import ColorView from '../../../../structures/colorView';

type LabelParams = {
	id: number
	name: string,
	labellableID: number,
	labellableType: string,
	baseColor: string,
	hoverColor: string
}

export type LabelJSON = {
	id: number,
	name: string,
	labellable_id: number,
	labellable_type: string,
	base_color: string,
	hover_color: string
}

export default class Label {
	id: number;
	name: string;
	labellableID: number;
	labellableType: string;
	baseColor: string;
	hoverColor: string;

	constructor( params: LabelParams ) {
		this.id = params.id;
		this.name = params.name;
		this.labellableID = params.labellableID;
		this.labellableType = params.labellableType;
		this.baseColor = params.baseColor;
		this.hoverColor = params.hoverColor;
	}

	static fromJson( json: LabelJSON ) {
		return new Label( {
			id: json.id,
			name: json.name,
			labellableID: json.labellable_id,
			labellableType: json.labellable_type,
			baseColor: json.base_color,
			hoverColor: json.hover_color
		} );
	}

	toJson(): LabelJSON {
		return {
			id: this.id,
			name: this.name,
			labellable_id: this.labellableID,
			labellable_type: this.labellableType,
			base_color: this.baseColor,
			hover_color: this.hoverColor
		}
	}

	get color() {
		return new ColorView( { base: this.baseColor, hovered: this.hoverColor } );
	}
}
