import { useMemo } from 'react';
import { useApi } from '../../../providers/ApiProvider';
import NotificationSystem from './NotificationSystem';

const useNotificationSystem = () => {
	const api = useApi();

	return useMemo( () => new NotificationSystem( { api } ), [ api ] );
};

export default useNotificationSystem;
