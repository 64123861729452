import { Loader } from '@mantine/core';
import classNames from 'classnames';

import useFetchFolderTree from '../../../projectView/queries/useFetchFolderTree';
import FolderTreeView from './FolderTreeView/FolderTreeView';
import { FolderTreeProps } from './types';

import './FolderTree.scss';

const FolderTree = ( {
	projectID,
	...props
}: FolderTreeProps ) => {
	const { data: folderTree } = useFetchFolderTree( projectID );

	const renderContent = () => {
		if ( folderTree && folderTree.hasFolders ) {
			return <FolderTreeView folderTree={folderTree} {...props} />
		}

		if ( folderTree ) {
			return <p className="empty-placeholder">No folders</p>
		}

		return <Loader variant="dots" size="sm" color="gray" />;
	}

	return (
		<div className={classNames( 'FolderTree', { isLoading: !folderTree } )}>
			{renderContent()}
		</div>
	);
}

export default FolderTree;
