import { createStyles } from '@mantine/core';

export interface ExpandableStylesParams {
  opened: boolean;
}

export default createStyles( ( theme, { opened }: ExpandableStylesParams ) => ( {
	root: {
		borderRadius: '4px',
		border: '1px solid #E9E9E9',
		background: '#FFF',
		width: '100%'
	},
	trigger: {
		display: 'flex',
		padding: '8px 12px',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		alignSelf: 'stretch',
		borderBottom: opened ? '1px solid #E9E9E9' : '1px solid transparent'
	},
	title: { fontSize: theme.fontSizes.xs, fontWeight: 600 },
	icon: { width: 21, height: 21 },
	content: { width: '100%' }
} ) );
