import { useQuery } from '@tanstack/react-query';
import useNotificationPreferenceSystem from '@notifications/systems/useNotificationPreferenceSystem';
import NotificationPreferences, { NotificationPreferencesJSON } from '@notifications/models/NotificationPreferences';
import { QueryKeys } from './queryKeys';

const useFetchNotificationPreferencesQuery = () => {
	const system = useNotificationPreferenceSystem();

	const select = ( data: NotificationPreferencesJSON ) => NotificationPreferences.fromJSON( data );

	return useQuery(
		QueryKeys.forNotificationPreferences(),
		() => system.fetchNotificationPreferences(),
		{
			select
		}
	);
}

export default useFetchNotificationPreferencesQuery;
