import moment from 'moment';
import ImmutableEntity from './immutableEntity';
import { AVAILABLE_ROLES_BY_TYPE } from './projectRole';
import Label from './label';
import { OrganizerType } from './folder';
import User from './user';
import GuestHash from './guestHash';

export const PROJECT_STATUS_HIDDEN = 'archived';
export const PROJECT_STATUS_ACTIVE = 'active';

export default class Project extends ImmutableEntity {
	/**
	 * @param {number} id Project id
	 * @param {string} name display name
	 * @param {string} description
	 * @param {string} clientName
	 * @param {string} primaryContact
	 * @param {string} coverImageURL
	 * @param {number} coverAssetID asset that contains the cover image url of the project
	 * @param {'active'|'archived'} status
	 * @param {string} shareHash
	 * @param {number} unresolvedCommentsCount
	 * @param {boolean} hasRushPriority
	 * @param {object} owner
	 * @param {'admin'|'user'} currentRoleId
	 * @param {Date} startDate
	 * @param {Date} dueDate
	 * @param {Date} createdAt
	 * @param {Date} updatedAt
	 * @param {Workspace} workspace
	 * @param {object} guestHash
	 * @param {boolean} notificationsMuted
	 */
	constructor(
		id,
		name,
		description,
		clientName,
		primaryContact,
		coverImageURL,
		coverAssetID,
		status,
		shareHash,
		shareHashDownloadable,
		unresolvedCommentsCount,
		hasRushPriority,
		currentRoleId,
		startDate,
		dueDate,
		createdAt,
		updatedAt,
		workspace,
		lastActivityAt,
		labels = [],
		label,
		storageUsedInBytes,
		totalAssetsCount,
		lastModifier,
		owner,
		guestHash,
		notificationsMuted
	) {
		super();

		this.id = id;
		this.name = name;
		this.description = description;
		this.clientName = clientName;
		this.primaryContact = primaryContact;
		this.coverImageURL = coverImageURL;
		this.coverAssetID = coverAssetID;
		this.status = status;
		this.shareHash = shareHash;
		this.shareHashDownloadable = shareHashDownloadable;
		this.unresolvedCommentsCount = unresolvedCommentsCount;
		this.hasRushPriority = hasRushPriority;
		this.currentRoleId = currentRoleId || '-';
		this.startDate = startDate;
		this.dueDate = dueDate;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.workspace = workspace;
		this.lastActivityAt = lastActivityAt;
		this.labels = labels;
		this.label = label;
		this.storageUsedInBytes = storageUsedInBytes;
		this.totalAssetsCount = totalAssetsCount;
		this.lastModifier = lastModifier;
		this.owner = owner;
		this.guestHash = guestHash;
		this.notificationsMuted = notificationsMuted;
	}

	get allowsDownloads() {
		return true;
	}

	get hasNotificationsMuted() {
		return this.notificationsMuted;
	}

	get hasActiveWorkspace() {
		return this.workspace.isActive;
	}

	get isOnExpiredFreeTrial() {
		return this.workspace.isOnExpiredFreeTrial;
	}

	get workspaceID() {
		return this.workspace.id;
	}

	get labellableID() {
		return this.workspaceID;
	}

	get currentUserRole() {
		return AVAILABLE_ROLES_BY_TYPE[ this.currentRoleId ];
	}

	get currentUserIsOwner() {
		return this.currentUserRole?.isOwner;
	}

	get currentUserIsGuest() {
		return this.currentUserRole?.isGuest;
	}

	get userPermissions() { return this.currentUserRole?.permissions; }

	get hasUnresolvedComments() { return this.unresolvedCommentsCount > 0; }

	get currentUserIsPart() { return !!this.currentUserRole; }

	get labelAssignedType() { return Label.type.workspace; }

	get hasAnyLabel() {
		return !!this.label;
	}

	get formattedStoragedUsed() {
		return this.storageUsedInBytes.formatBytes();
	}

	get createdAtToDisplay() {
		return this._formatDateIfPresent( this.createdAt, 'MM/DD/YY' );
	}

	get dueDateToDisplay() {
		return this._formatDateIfPresent( this.dueDate, 'MM/DD/YY' );
	}

	get lastActivityToDisplay() {
		return this._formatDateIfPresent( this.lastActivityAt, 'MM/DD, h:mmA' );
	}

	get assetsCount() { return this.totalAssetsCount; }

	get url() {
		return `/projects/${this.id}`;
	}

	get projectDashboardUrl() {
		const hiddenQueryParam = this.status === PROJECT_STATUS_HIDDEN ? '?hidden_projects' : '';

		return `/projects${hiddenQueryParam}`;
	}

	get type() {
		return OrganizerType.project;
	}

	hasLabel( label ) {
		return !!this.label && !!label && this.label.id === label.id;
	}

	fetchAssetsWith( system ) {
		return system.fetchAssetsForProject( this );
	}

	async fetchFoldersWith( system ) {
		return system.fetchFoldersForProject( this );
	}

	async fetchOverviewWith( system ) {
		return system.fetchOverviewForProject( this );
	}

	async fetchInformationWith( system ) {
		return system.fetchInformationForProject( this );
	}

	// Serialization
	static fromJSON( properties, workspace, labels ) {
		return new Project(
			properties.id,
			properties.name,
			properties.description,
			properties.client_name,
			properties.primary_contact,
			properties.cover_url,
			properties.cover_id,
			properties.status,
			properties.share_hash,
			properties.share_hash_downloadable,
			properties.unresolved_comments_count,
			properties.rush_priority,
			properties.current_user_role.type,
			properties.start_date ? new Date( properties.start_date ) : null,
			properties.due_date ? new Date( properties.due_date ) : null,
			new Date( properties.created_at ),
			new Date( properties.updated_at ),
			workspace,
			new Date( properties.last_activity_at ),
			labels,
			properties.label && Label.fromJSON( properties.label ),
			properties.storage_used_in_bytes,
			properties.total_assets_count,
			properties.last_activity_by && User.fromJSON( properties.last_activity_by ),
			properties.owner && User.fromJSON( properties.owner ),
			properties.guest_hash && GuestHash.fromJSON( properties.guest_hash ),
			properties.notifications_muted
		);
	}

	shareLink( { withDownloads } ) {
		const shareHash = withDownloads ? this.shareHashDownloadable : this.shareHash;
		return `projects/shared_view/${shareHash}`;
	}

	get isProject() {
		return true;
	}

	get isFolder() {
		return false;
	}

	get selectionID() {
		return `project-${this.id}`;
	}

	get guestHashIsPublic() {
		return this.guestHash.enabled;
	}

	get guestHashString() {
		return this.guestHash.guestHash;
	}

	_formatDateIfPresent( date, format ) {
		return date ? moment( date ).format( format ) : '';
	}
}
