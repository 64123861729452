import PropTypes from 'prop-types';
import './HideOnMobile.scss';

const HideOnMobile = ( { children } ) => (
	<div className="hide-on-mobile">{children}</div>
);

HideOnMobile.propTypes = {
	children: PropTypes.oneOfType( [
		PropTypes.arrayOf( PropTypes.any ),
		PropTypes.any
	] ).isRequired
};
export default HideOnMobile;
