import { NotificationType } from '@notifications/models/Notification';

class QueryKeys {
	static forProjects() {
		return [ 'notifications', 'projects' ]
	}

	static forProject( { projectID, filter }: { projectID: number, filter?: NotificationType | null } ) {
		const baseKey = [ 'notifications', 'project', projectID ];
		return filter !== undefined ? [ ...baseKey, filter ] : baseKey;
	}

	static forNotificationPreferences() {
		return [ 'notifications', 'preferences' ];
	}
}

export { QueryKeys };
