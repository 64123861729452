/* eslint-disable no-use-before-define */
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import TreeViewNode from './TreeViewNode/TreeViewNode';

import type Tree from './Tree';
import { type NodeRenderer } from './types';

import './TreeView.scss';

type TreeViewProps = {
	tree: Tree,
	renderNode: NodeRenderer,
	className?: string;
}

const TreeView = ( { tree, renderNode, className }: TreeViewProps ) => (
	<ul className={classNames( 'TreeView', className )}>
		{tree.nodes.map( node => (
			<TreeViewNode
				node={node}
				renderNode={renderNode}
				key={node.id}
			/>
		) )}
	</ul>
);

export default observer( TreeView );
