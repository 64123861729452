import { useMemo } from 'react';
import { useReduxTanStackSync } from 'src/new_arch/providers/ReduxTanStackSyncProvider';
import { useApi } from '../../../providers/ApiProvider'
import ProjectSystem from './ProjectSystem';

const useProjectSystem = () => {
	const api = useApi();
	const sync = useReduxTanStackSync();

	return useMemo( () => new ProjectSystem( { api, sync } ), [ api, sync ] );
}

export default useProjectSystem;
