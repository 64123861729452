import { ButtonStylesParams } from '@mantine/core';
import { MantineThemeComponent } from '../../types';

const isSmall = ( size?: string | number ) => [ 'xs', 'sm' ].includes( size?.toString() || '' );

const ButtonDefaultConfig: MantineThemeComponent = {
	defaultProps: {
		variant: 'filled',
		size: 'md',
		loaderProps: {
			size: 'xs'
		}
	},
	styles: ( _theme, _params: ButtonStylesParams, { size } ) => ( {
		root: {
			height: isSmall( size ) ? 30 : 40,
			fontSize: isSmall( size ) ? 14 : 16
		}
	} )
};

export default ButtonDefaultConfig;
