import Button from '@ui/components/Button/Button';
import styles from './Footer.module.scss';

type FooterProps = {
	hasUploads: boolean;
	isUploading: boolean;
	onClearAllClick?: () => void;
	onCancelAllClick?: () => void;
};

const Footer = ( {
	hasUploads, isUploading, onCancelAllClick, onClearAllClick
}: FooterProps ) => (
	<div className={styles.Footer}>
		{!hasUploads && !isUploading && (
			<p>Accepted files: JPG, TIF, PSD, PSB, PNG, GIF</p>
		)}
		{isUploading && (
			<Button
				variant="subtle"
				color="red.4"
				onClick={onCancelAllClick}
				size="xs"
				compact
				className={styles.Footer__Button}
			>
				Cancel All
			</Button>
		)}
		{hasUploads && !isUploading && (
			<Button
				variant="subtle"
				color="gray.9"
				onClick={onClearAllClick}
				size="xs"
				compact
				className={styles.Footer__Button}
			>
				Clear All
			</Button>
		)}
	</div>
);

export default Footer;
