export const ROLES = [
	{ value: 'retoucher', label: 'Retoucher' },
	{ value: 'art_director_or_designer', label: 'Art Director or Designer' },
	{ value: 'photographer', label: 'Photographer' },
	{ value: 'producer', label: 'Producer' },
	{ value: 'digital_tech', label: 'Digital Tech' },
	{ value: 'merchandise', label: 'Merchandise' },
	{ value: 'account_manager', label: 'Account Manager' },
	{ value: 'other', label: 'Other' }
];

export const INTENDED_USAGES = [
	{ value: 'manage_projects', label: 'I am here to *startBold*create and/or manage*endBold* projects' },
	{ value: 'collaborate', label: 'I was invited to *startBold*collaborate*endBold* in a project' }
];

export const REFERRAL_SOURCES = [
	{ value: 'internet_search', label: 'Internet Search' },
	{ value: 'word_of_mouth', label: 'Word of mouth' },
	{ value: 'linkedin', label: 'Linkedin' },
	{ value: 'instagram', label: 'Instagram' },
	{ value: 'blog_post', label: 'Blog Post' },
	{ value: 'podcast', label: 'Podcast' },
	{ value: 'other', label: 'Other' }
];

export type OnboardingAnswers = {
	role: string,
	intendedUsage: string,
	referralSource: string
}
