/* eslint-disable react/no-danger */
import classNames from 'classnames';

import './OptionBox.scss';
import { removeTags } from 'src/lib/textUtils';

type OptionBoxProps = {
	value: string,
	label: string,
	onSelect: ( role: string ) => void,
	isSelected: boolean,
	width: number,
	height: number
}

const parseBoldTags = ( text: string ) => (
	removeTags( text ).replaceAll( '*startBold*', '<b>' ).replaceAll( '*endBold*', '</b>' )
);

const OptionBox = ( { value, label, onSelect, isSelected, width, height } : OptionBoxProps ) => (
	<div
		className={classNames( 'OptionBox', { selected: isSelected } )}
		style={{ width, height }}
		onClick={() => { onSelect( value ) }}
	>
		<span dangerouslySetInnerHTML={{ __html: parseBoldTags( label ) }} />
	</div>
);

export default OptionBox;
