import NotificationRelatedObject, { RelatedObjectJSON, RelatedObjectType } from './NotificationRelatedObject';

export type NotificationType = 'mention' | 'asset_uploaded' | 'set_rush_priority' | 'asset_approved'
	| 'new_round' | 'comment' | 'new_root_comment' | 'new_reply_comment' | 'new_user' | 'new_guest_view';

type NotificationAttributes = {
	id: number,
	type: NotificationType,
	projectID: number,
	projectName: string,
	folderID: number | null,
	folderName: string | null,
	folderHasAncestors: boolean | null,
	title: string,
	message: string,
	image: string,
	wasRead: boolean,
	wasClicked: boolean,
	createdAt: Date,
	relatedObject: NotificationRelatedObject | null
}

export type NotificationJSON = {
	id: number,
	notify_type: NotificationType,
	project_id: number,
	project_name: string,
	folder_id: number | null,
	folder_name: string | null,
	folder_has_ancestors: boolean | null,
	title: string,
	message: string,
	image: string,
	was_read: boolean,
	was_clicked: boolean,
	created_at: string,
	related_object_type?: RelatedObjectType,
	related_object?: RelatedObjectJSON<RelatedObjectType>
}

export default class Notification {
	id: number;
	type: NotificationType;
	projectID: number;
	projectName: string;
	folderID: number | null;
	folderName: string | null;
	folderHasAncestors: boolean | null;
	title: string;
	message: string;
	image: string;
	wasRead: boolean;
	wasClicked: boolean;
	createdAt: Date;
	relatedObject: NotificationRelatedObject | null;

	constructor( {
		id, type, projectID, title, message, image, wasRead,
		wasClicked, createdAt, relatedObject,
		projectName, folderID, folderName, folderHasAncestors
	}: NotificationAttributes ) {
		this.id = id;
		this.type = type;
		this.projectID = projectID;
		this.title = title;
		this.message = message;
		this.image = image;
		this.wasRead = wasRead;
		this.wasClicked = wasClicked;
		this.createdAt = createdAt;
		this.relatedObject = relatedObject;
		this.projectName = projectName;
		this.folderID = folderID;
		this.folderName = folderName;
		this.folderHasAncestors = folderHasAncestors;
	}

	get projectUrl() {
		return `/projects/${this.projectID}`;
	}

	get folderUrl() {
		return `/projects/${this.projectID}/folders/${this.folderID}`;
	}

	get relatedObjectUrl() {
		return this.relatedObject?.url;
	}

	get hasRelatedObjectText() {
		return !!this.relatedObject?.text;
	}

	get relatedObjectText() {
		return this.relatedObject?.text;
	}

	get isForComment() {
		return this.type === 'comment' || this.type === 'new_root_comment'
			|| this.type === 'new_reply_comment' || this.type === 'mention';
	}

	get messageContainsEmails() {
		return /\S+@\S+\.\S+/.test( this.message );
	}

	static allFromJson( notifications: NotificationJSON[] ) {
		return notifications.map( Notification.fromJSON );
	}

	static fromJSON( properties: NotificationJSON ) {
		return new Notification( {
			id: properties.id,
			type: properties.notify_type,
			title: properties.title,
			message: properties.message,
			image: properties.image,
			wasRead: properties.was_read,
			wasClicked: properties.was_clicked,
			projectID: properties.project_id,
			projectName: properties.project_name,
			folderID: properties.folder_id,
			folderName: properties.folder_name,
			folderHasAncestors: properties.folder_has_ancestors,
			createdAt: new Date( properties.created_at ),
			relatedObject: properties.related_object_type && properties.related_object ? (
				NotificationRelatedObject.fromJSON(
					properties.project_id,
					properties.related_object_type,
					properties.related_object
				)
			) : null
		} );
	}

	toJSON(): NotificationJSON {
		return {
			id: this.id,
			notify_type: this.type,
			project_id: this.projectID,
			title: this.title,
			message: this.message,
			image: this.image,
			was_read: this.wasRead,
			was_clicked: this.wasClicked,
			created_at: this.createdAt.toISOString(),
			related_object_type: this.relatedObject?.type,
			related_object: this.relatedObject?.toJSON(),
			project_name: this.projectName,
			folder_id: this.folderID,
			folder_name: this.folderName,
			folder_has_ancestors: this.folderHasAncestors
		};
	}
}
