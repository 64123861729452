import { getFileExtension, removeExtension } from 'src/lib/fileUtils';

const SUFFIX_REGEX = /[._\-+*](r|v|ver|round|version|final)\d*/i;

export default class MatchedFilename {
	constructor( private filename: string ) {
		this.filename = filename;
	}

	get full() { return this.filename; }

	get base() {
		if ( !this.suffix ) { return this.withoutExtension }

		return this.withoutExtension.replace( this.suffix, '' );
	}

	get extension() {
		return getFileExtension( this.filename );
	}

	get suffix() {
		return this.withoutExtension.match( SUFFIX_REGEX )?.[ 0 ];
	}

	private get withoutExtension() {
		return removeExtension( this.filename );
	}
}
