import Select from 'src/components/UI/Select/Select';
import WhiteBox from 'src/components/WhiteBox/WhiteBox';
import { WHITE } from 'src/styles/colors';
import Tooltip from 'src/components/UI/Tooltip/Tooltip';
import Checkbox from 'src/components/UI/Checkbox/Checkbox';
import NotificationOptionText from './NotificationOptionText/NotificationOptionText';
import NotificationOption from './NotificationOption/NotificationOption';
import NotificationSettingsSkeleton from './NotificationSettingsSkeleton/NotificationSettingsSkeleton';
import useNotificationSettings from './hooks/useNotificationSettings';

import './NotificationSettings.scss';

const NotificationSettings = () => {
	const {
		commentsNotificationProps,
		assetsNotificationProps,
		teamUpdatesNotificationProps,
		emailFrequencyProps,
		isLoading
	} = useNotificationSettings();

	const renderSectionHeader = (
		sectionName: string,
		{ includeColumnNames = false }: { includeColumnNames?: boolean } = {}
	) => (
		<div className="section-header">
			<h3>{sectionName}</h3>
			{includeColumnNames && <h3>In app</h3>}
			{includeColumnNames && <h3>Email</h3>}
		</div>
	)
	const renderEmailFrequencySection = () => (
		<>
			<NotificationOptionText
				icon="email-outlined"
				title="Email frequency"
				description="Receive notifications from projects"
			/>
			<Select
				{...emailFrequencyProps}
				aria-label="email-frequency-select"
			/>
		</>
	);

	const renderNotificationSettingsHeader = () => (
		<div className="settings-header">
			<NotificationOptionText
				title="Notification Type"
				description="Receive notifications from unmuted projects you are part of"
				icon="notifications-outlined"
				className="option"
			/>
			<h3 className="in-app">In app</h3>
			<h3 className="email">Email</h3>
		</div>
	);

	const renderMentionTooltip = () => (
		<div style={{ textAlign: 'left' }}>
			<p style={{ color: WHITE }}>@Mentions cannot <br /> be turned off</p>
		</div>
	);

	const renderCommentsNotificationSection = () => (
		<div className="section">
			{renderSectionHeader( 'Comments' )}
			<div className="setting" data-testid="@Mentions">
				<NotificationOptionText
					title="@Mentions"
					description="When someone @mentions you in a comment"
					className="option"
				/>
				<Tooltip html={renderMentionTooltip()} position="right">
					<div className="in-app">
						<Checkbox value disabled dataTestId="in-app-checkbox" />
					</div>
					<div className="email">
						<Checkbox value disabled dataTestId="email-checkbox" />
					</div>
				</Tooltip>
			</div>
			{commentsNotificationProps.map( props => ( <NotificationOption {...props} /> ) )}
		</div>
	);

	const renderAssetsNotificationSection = () => (
		<div className="section">
			{renderSectionHeader( 'Assets', { includeColumnNames: true } )}
			{assetsNotificationProps.map( props => ( <NotificationOption {...props} /> ) )}
		</div>
	);

	const renderTeamUpdatesNotificationSection = () => (
		<div className="section">
			{renderSectionHeader( 'Team Updates', { includeColumnNames: true } )}
			{teamUpdatesNotificationProps.map( props => ( <NotificationOption {...props} /> ) )}
		</div>
	);

	return (
		<div className="NotificationSettings">
			{ isLoading ? (
				<NotificationSettingsSkeleton />
			) : (
				<>
					<WhiteBox className="email-frequency-container">
						{renderEmailFrequencySection()}
					</WhiteBox>
					<WhiteBox className="notification-settings-container">
						{renderNotificationSettingsHeader()}
						{renderCommentsNotificationSection()}
						{renderAssetsNotificationSection()}
						{renderTeamUpdatesNotificationSection()}
					</WhiteBox>
				</>
			) }
		</div>
	);
};

export default NotificationSettings;
