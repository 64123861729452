import Skeleton from '@material-ui/lab/Skeleton';

import './NotificationSettingsSkeleton.scss';

const NotificationSettingsSkeleton = () => {
	const EMAIL_FREQUENCY_SKELETON_HEIGHT = 90;
	const NOTIFICATION_PREFERENCE_SKELETON_HEIGHT = 400;

	return (
		<>
			<div className="WhiteBoxSkeleton" data-testid="email-frequency-skeleton">
				<Skeleton variant="rect" animation="wave" width="100%" height={EMAIL_FREQUENCY_SKELETON_HEIGHT} />
			</div>
			<div className="WhiteBoxSkeleton" data-testid="notification-preference-skeleton">
				<Skeleton variant="rect" animation="wave" width="100%" height={NOTIFICATION_PREFERENCE_SKELETON_HEIGHT} />
			</div>
		</>
	)
};

export default NotificationSettingsSkeleton;
