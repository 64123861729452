import { useMutation, useQueryClient } from '@tanstack/react-query';
import useNotificationPreferenceSystem from '@notifications/systems/useNotificationPreferenceSystem';
import NotificationPreferences, { NotificationPreferencesJSON } from '@notifications/models/NotificationPreferences';
import { QueryKeys } from './queryKeys';

const useUpdateNotificationPreferencesMutation = () => {
	const system = useNotificationPreferenceSystem();
	const queryClient = useQueryClient();

	const onSuccess = ( data: NotificationPreferencesJSON ) => {
		queryClient.setQueryData( QueryKeys.forNotificationPreferences(), data );
	}

	const { mutate } = useMutation(
		( preferences: NotificationPreferences ) => system.updateNotificationPreferences( preferences.toJSON() ),
		{
			onSuccess
		}
	);

	return {
		mutate
	}
};

export default useUpdateNotificationPreferencesMutation;
