export class AssetsPermissions {
	constructor( {
		see = false,
		upload = false,
		destroy = false,
		approve = false,
		seeVersions = false,
		filter = false,
		editTitle = false,
		moveItemsAcrossAllLevels = false
	} ) {
		this.see = see;
		this.upload = upload;
		this.destroy = destroy;
		this.approve = approve;
		this.seeVersions = seeVersions;
		this.filter = filter;
		this.editTitle = editTitle;
		this.moveItemsAcrossAllLevels = moveItemsAcrossAllLevels;
	}
}

export const ownerAssetsPermissions = new AssetsPermissions( {
	see: true,
	upload: true,
	destroy: true,
	approve: true,
	seeVersions: true,
	filter: true,
	editTitle: true,
	moveItemsAcrossAllLevels: true
} );

export const projectCollaboratorAssetsPermissions = new AssetsPermissions( {
	see: true,
	upload: true,
	destroy: true,
	approve: true,
	seeVersions: true,
	filter: true,
	editTitle: true,
	moveItemsAcrossAllLevels: true
} );

export const projectGuestAssetsPermissions = new AssetsPermissions( {
	see: true,
	upload: true,
	destroy: true,
	approve: true,
	seeVersions: true,
	filter: true,
	editTitle: false,
	moveItemsAcrossAllLevels: false
} );
