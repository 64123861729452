import { ComponentType, memo } from 'react';
import { ListChildComponentProps } from 'react-window';
import { ListContext } from './ListContext';

export type ListRowProps<TItem> = ListChildComponentProps<ListContext<TItem>>

const ListRow = ( { index, data, style }: ListRowProps<unknown> ) => {
	const {
		itemAt,
		itemCount,
		loaderCount,
		renderItem,
		renderLoader
	} = data;
	const item = itemAt( index );

	if ( item ) return renderItem( item, style, index );
	if ( index < itemCount + loaderCount ) return renderLoader( style );

	return null;
};

export default memo( ListRow as ComponentType<ListRowProps<unknown>> );
