import { Anchor, Button, Group, List, Tooltip, useMantineTheme } from '@mantine/core';
import Text from '@ui/components/Text/Text';
import { useCallback } from 'react';
import Icon from 'src/new_arch/components/Icon/Icon';
import { trackLearnMoreClickedInAdvanceUploadsModal } from 'src/services/tracker/trackers/uploads';

type FooterProps = {
	onCancel?: () => void
	onConfirm?: () => void
};

const LIST_STYLE = { color: 'white' };
const TooltipContent = (
	<Text variant="caption-regular">
		<strong>Upload Information:</strong><br />
		<List style={LIST_STYLE} spacing={-2} size="xs">
			<List.Item style={LIST_STYLE}>
				<Text variant="caption-regular" color="white">Duplicated names are uploaded as new rounds.</Text>
			</List.Item>
			<List.Item style={LIST_STYLE}>
				<Text variant="caption-regular" color="white">Unmatched assets are uploaded as new assets.</Text>
			</List.Item>
		</List>
	</Text>
)

const Footer = ( {
	onCancel, onConfirm
}: FooterProps ) => {
	const theme = useMantineTheme();
	const onLearnMoreClick = useCallback( () => trackLearnMoreClickedInAdvanceUploadsModal(), [] );

	return (
		<Group p="8px 16px" position="apart">
			<Group spacing={12} h="100%">
				<Tooltip
					label={TooltipContent}
					offset={10}
				>
					<Group spacing={5} style={{ cursor: 'default' }}>
						<Icon icon="info_outlined" color={theme.fn.primaryColor()} />
						<Text variant="footnote2">What happens to the other assets?</Text>
					</Group>
				</Tooltip>
				<Anchor
					href="https://support.verybusy.io/en/"
					target="_blank"
					color="primary.5"
					onClick={onLearnMoreClick}
				>
					<Text variant="footnote-semibold">Learn More</Text>
				</Anchor>
			</Group>
			<Group spacing={12}>
				<Button
					variant="subtle"
					color="gray.9"
					onClick={onCancel}
				>
					Cancel
				</Button>
				<Button
					size="md"
					onClick={onConfirm}
				>
					Continue upload
				</Button>
			</Group>
		</Group>
	);
}

export default Footer;
