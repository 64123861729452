import useProjectsWithUnseenNotificationsQuery from '@notifications/queries/useProjectsWithUnseenNotificationsQuery';
import { useEffect, useState } from 'react';

const useSelectedProjectID = () => {
	const { data: projects } = useProjectsWithUnseenNotificationsQuery();
	const [ selectedProjectID, setSelectedProjectID ] = useState<number | null>( null );

	useEffect( () => {
		if ( !selectedProjectID && projects && projects.length > 0 ) {
			setSelectedProjectID( projects[ 0 ].id );
		}
	}, [ projects ] );

	return {
		selectedProjectID,
		onProjectClick: setSelectedProjectID
	};
}

export default useSelectedProjectID;
