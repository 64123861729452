import { fileNameTextTruncate } from 'src/lib/textTruncate';

const FILENAME_REGEX = /[|].+.[|]/i;

export const truncateFilePaths = ( text?: string ) => {
	const matches = text && text.match( FILENAME_REGEX );
	if ( !matches ) { return <span>{text}</span>; }
	const matchWithoutPipes = matches[ 0 ].slice( 1, matches[ 0 ].length - 1 );
	const splitMessage = text.split( matches[ 0 ] );
	return (
		<span>
			{!!splitMessage[ 0 ] && splitMessage[ 0 ]}
			<span className="name">{fileNameTextTruncate( matchWithoutPipes, 20, 12 )}</span>
			{!!splitMessage[ 1 ] && splitMessage[ 1 ]}
		</span>
	);
};

export const truncateFilePathsText = ( text?: string ) => {
	const matches = text && text.match( FILENAME_REGEX );
	if ( !matches ) { return text; }
	const matchWithoutPipes = matches[ 0 ].slice( 1, matches[ 0 ].length - 1 );
	const splitMessage = text.split( matches[ 0 ] );

	return [
		splitMessage[ 0 ] ? splitMessage[ 0 ] : '',
		fileNameTextTruncate( matchWithoutPipes, 20, 12 ),
		splitMessage[ 1 ] ? splitMessage[ 1 ] : ''
	].join( ' ' ).trim();
};
