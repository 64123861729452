import { observer } from 'mobx-react-lite';
import useNavigationPanelStore from '@navigationPanel/hooks/useNavigationPanelStore';
import useNavigationPanelIsAvailable from '@navigationPanel/hooks/useNavigationPanelIsAvailable';
import IconButton from 'src/components/UI/IconButton/IconButton';
import { trackToggledNavigationPanel } from 'src/services/tracker/trackers/navigation';

import './NavigationPanelButton.scss';

type NavigationPanelButtonProps = {
	action: 'open' | 'close',
	color: string
};

const NavigationPanelButton = ( { action, color } : NavigationPanelButtonProps ) => {
	const navigationPanelStore = useNavigationPanelStore();
	const panelIsAvailable = useNavigationPanelIsAvailable();

	const onButtonClick = () => {
		trackToggledNavigationPanel( {
			toggleType: action
		} );
		navigationPanelStore.togglePanelOpen();
	}

	const icon = action === 'open' ? 'menu' : 'menu-open';
	const actionHasBeenDone = action === 'open' ? navigationPanelStore.isOpen : !navigationPanelStore.isOpen;

	return (
		panelIsAvailable && !actionHasBeenDone ? (
			<IconButton
				icon={icon}
				size={20}
				color={color}
				onClick={onButtonClick}
				ariaLabel="navigation-panel-button"
			/>
		) : null
	)
}

export default observer( NavigationPanelButton );
