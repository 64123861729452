import { useEffect, useState, type CSSProperties, type ReactNode } from 'react';

import './PanelContainer.scss';

type PanelContainerProps = {
	width: number,
	element: ReactNode,
	keepMounted: boolean,
	isActive: boolean,
	containerStyle: CSSProperties
}

const PANEL_UNMOUNT_TIMEOUT = 200;

const PanelContainer = ( { width, element, keepMounted, isActive, containerStyle }: PanelContainerProps ) => {
	const [ isMounted, setIsMounted ] = useState( isActive || keepMounted );

	useEffect( () => {
		let timeout: NodeJS.Timeout | null = null;

		if ( keepMounted ) return () => {};
		if ( isActive ) {
			setIsMounted( true );
		} else {
			timeout = setTimeout( () => setIsMounted( false ), PANEL_UNMOUNT_TIMEOUT );
		}

		return () => {
			if ( timeout ) clearTimeout( timeout );
		};
	}, [ isActive, keepMounted ] );

	return (
		<div
			className="PanelContainer"
			style={{ width, ...containerStyle }}
		>
			{isMounted && element}
		</div>
	);
}

export default PanelContainer;
