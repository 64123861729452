import AutoSizer from 'react-virtualized-auto-sizer';
import { ListProps, ListSizeProps } from './types';
import List from './List';

const AutoSizedList = <TItem, >( props: ListProps<TItem> ) => (
	<AutoSizer className={props.className}>
		{( { width, height }: ListSizeProps ) => <List width={width} height={height} {...props} />}
	</AutoSizer>
);

export default AutoSizedList;
