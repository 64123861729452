type ProjectWithNotificationCountAttributes = {
	id: number,
	name: string,
	coverURL: string,
	totalNotificationCount: number,
	unseenNotificationCount: number,
	lastNotificationAt: Date | null
};

export type ProjectWithNotificationCountJSON = {
	project: {
		id: number,
		name: string,
		cover_url: string,
	},
	total_notifications_count: number,
	unseen_notifications_count: number,
	last_notification_at: string | null
};

export default class ProjectWithNotificationCount {
	id: number;
	name: string;
	coverURL: string;
	totalNotificationCount: number;
	unseenNotificationCount: number;
	lastNotificationAt: Date | null

	constructor( {
		id, name, coverURL, totalNotificationCount, unseenNotificationCount, lastNotificationAt
	}: ProjectWithNotificationCountAttributes ) {
		this.id = id;
		this.name = name;
		this.coverURL = coverURL;
		this.totalNotificationCount = totalNotificationCount;
		this.unseenNotificationCount = unseenNotificationCount;
		this.lastNotificationAt = lastNotificationAt;
	}

	get hasUnseenNotifications() {
		return this.unseenNotificationCount > 0;
	}

	static fromJSON( {
		project: {
			id,
			name,
			cover_url: coverURL
		},
		total_notifications_count: totalNotificationCount,
		unseen_notifications_count: unseenNotificationCount,
		last_notification_at: lastNotificationAt
	}: ProjectWithNotificationCountJSON ) {
		return new ProjectWithNotificationCount( {
			id,
			name,
			coverURL,
			totalNotificationCount,
			unseenNotificationCount,
			lastNotificationAt: lastNotificationAt ? new Date( lastNotificationAt ) : null
		} );
	}

	toJSON() {
		return {
			project: {
				id: this.id,
				name: this.name,
				cover_url: this.coverURL
			},
			total_notifications_count: this.totalNotificationCount,
			unseen_notifications_count: this.unseenNotificationCount,
			last_notification_at: this.lastNotificationAt?.toISOString() || null
		}
	}
}
