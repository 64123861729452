import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { Indicator } from '@mantine/core';

import ShowElementToggler from 'src/components/ShowElementToggler/ShowElementToggler';
import IconButton from 'src/components/UI/IconButton/IconButton';
import Tooltip from 'src/components/UI/Tooltip/Tooltip';

import NotificationsSidePanel
	from '@notifications/components/NotificationsSidePanel/NotificationsSidePanel';
import useHasUnseenNotifications from './useHasUnseenNotifications';

const NotificationsButton = () => {
	const ref = useRef<ShowElementToggler | null>( null );
	const history = useHistory<{ onOpen: boolean }>();
	const hasUnseenNotifications = useHasUnseenNotifications();

	useEffect( () => {
		if ( history.location?.state?.onOpen ) ref.current?.show();
	}, [ history.location?.state?.onOpen ] )

	const renderToggler = useCallback( ( onOpen: () => void, isModalOpen: boolean ) => (
		<Tooltip
			title="Notifications"
			disabled={isModalOpen}
		>
			<Indicator
				disabled={!hasUnseenNotifications}
				color="red"
				processing
				size={8}
				offset={-2}
				data-testid="unseen-notifications-indicator"
			>
				<IconButton
					buttonClassName="notifcations"
					onClick={onOpen}
					size={18}
					icon="notifications"
					color="white"
					ariaLabel="Notifications"
				/>
			</Indicator>
		</Tooltip>
	), [ hasUnseenNotifications ] );

	return (
		<ShowElementToggler
			ref={ref}
			className="NotificationsButton"
			renderToggler={renderToggler}
			renderComponent={( onClose: () => void ) => (
				<NotificationsSidePanel onClose={onClose} />
			)}
		/>
	);
};

export default NotificationsButton;
