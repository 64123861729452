import moment from 'moment';
import { AuthProvider } from '../new_arch/modules/authentication/models';
import ImmutableEntity from './immutableEntity';

export default class User extends ImmutableEntity {
	constructor(
		id,
		name,
		email,
		avatar,
		intercomHash,
		preferences,
		authProvider,
		isGuest,
		role,
		createdAt,
		internalUsage,
		referralSource
	) {
		super();
		this.id = id;
		this.name = name;
		this.email = email;
		this.avatar = avatar;
		this.intercomHash = intercomHash;
		this.preferences = preferences;
		this.authProvider = authProvider;
		this.isGuest = isGuest;
		this.role = role;
		this.createdAt = createdAt;
		this.internalUsage = internalUsage;
		this.referralSource = referralSource;
	}

	get is2FAEnabled() {
		return this.preferences?.is2FAEnabled;
	}

	get firstName() {
		return this.name.split( ' ' )[ 0 ];
	}

	get isNew() {
		return moment( this.createdAt ).isAfter( moment().subtract( 1, 'week' ) );
	}

	get preferedAssetDownloadsResolution() {
		return this.preferences?.assetDownloadsResolution;
	}

	get isLinkedToExternalAuthProvider() {
		return this.authProvider !== AuthProvider.Email;
	}

	addPreferences( preferences ) {
		return this.set( 'preferences', preferences );
	}

	static fromJSON( properties, preferences ) {
		return new User(
			properties.id,
			properties.name,
			properties.email,
			properties.avatar,
			properties.intercom_hash,
			preferences,
			properties.auth_provider,
			properties.is_guest,
			properties.role,
			new Date( properties.created_at ),
			properties.internal_usage,
			properties.referral_source
		);
	}

	toJSON() {
		return {
			id: this.id,
			name: this.name,
			email: this.email,
			avatar: this.avatar,
			intercom_hash: this.intercomHash,
			auth_provider: this.authProvider,
			is_guest: this.isGuest,
			role: this.role,
			created_at: this.createdAt.toISOString(),
			internal_usage: this.internalUsage,
			referral_source: this.referralSource
		};
	}

	clone() {
		return new User(
			this.id,
			this.name,
			this.email,
			this.avatar,
			this.intercomHash,
			this.preferences,
			this.authProvider,
			this.isGuest,
			this.role,
			this.createdAt,
			this.internalUsage,
			this.referralSource
		);
	}
}
