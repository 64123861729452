import { MouseEvent, useCallback, useMemo } from 'react';
import Notification from '@notifications/models/Notification';
import { htmlForMentions } from 'src/lib/mentionsUtils';
import { removeTags } from 'src/lib/textUtils';
import { truncateFilePaths } from './utils';

type UseNotificationListItemParams = {
	notification: Notification,
	onNotificationClicked?: ( notification: Notification ) => void,
	onNotificationProjectClicked?: ( notification: Notification ) => void,
	onNotificationFolderClicked?: ( notification: Notification ) => void
};

const useNotificationListItem = ( {
	notification, onNotificationClicked, onNotificationProjectClicked, onNotificationFolderClicked
}: UseNotificationListItemParams ) => {
	const notificationClicked = useCallback( () => onNotificationClicked?.( notification ),
		[ notification, onNotificationClicked ]
	);
	const notificationProjectClicked = useCallback( ( event: MouseEvent<HTMLDivElement> ) => {
		event.stopPropagation();
		onNotificationProjectClicked?.( notification );
	}, [ notification, onNotificationProjectClicked ] );
	const notificationFolderClicked = useCallback( ( event: MouseEvent<HTMLDivElement> ) => {
		event.stopPropagation();
		onNotificationFolderClicked?.( notification );
	}, [ notification, onNotificationFolderClicked ] );

	const isNew = !notification.wasRead;
	const { wasClicked } = notification;
	const contentHtml = truncateFilePaths( notification.message );
	const relatedObjectHtml = useMemo(
		() => htmlForMentions( removeTags( notification.relatedObjectText ) ),
		[ notification.relatedObjectText ]
	);
	const breakMessageWords = notification.messageContainsEmails;

	return {
		isNew,
		wasClicked,
		breakMessageWords,
		contentHtml,
		relatedObjectHtml,
		notificationClicked,
		notificationProjectClicked,
		notificationFolderClicked
	}
};

export default useNotificationListItem;
