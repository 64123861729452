import ItemUpload from './itemUpload';
import { ITEM_UPLOAD_STATUS } from '../services/DirectFilesUploaderCoordinator';

class FolderUpload extends ItemUpload {
	constructor( {
		id,
		status,
		bytesUploaded,
		projectID,
		organizerID,
		organizerType,
		directory,
		parentUploadID
	} ) {
		super();
		this.id = id;
		this.status = status;
		this.bytesUploaded = bytesUploaded;
		this.projectID = projectID;
		this.organizerID = organizerID;
		this.organizerType = organizerType;
		this.directory = directory;
		this.parentUploadID = parentUploadID;
	}

	clone() {
		return new FolderUpload( {
			id: this.id,
			status: this.status,
			bytesUploaded: this.bytesUploaded,
			projectID: this.projectID,
			organizerID: this.organizerID,
			organizerType: this.organizerType,
			directory: this.directory,
			parentUploadID: this.parentUploadID
		} );
	}

	get isFolderUpload() {
		return true;
	}

	get name() {
		return this.directory.name;
	}

	get description() {
		return '';
	}

	get progress() {
		return Math.floor( ( this.bytesUploaded * 100 ) / this.size );
	}

	get size() {
		return this.directory.size;
	}

	get formattedETA() {
		return 'Upload in Progress';
	}

	get errorMessage() {
		if ( this.status === ITEM_UPLOAD_STATUS.FAILED ) {
			return 'An unexpected error occurred';
		}

		return '';
	}
}

export default FolderUpload;
