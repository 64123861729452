import { useMemo } from 'react';
import useProjectsWithUnseenNotificationsQuery
	from '@notifications/queries/useProjectsWithUnseenNotificationsQuery';

const useHasUnseenNotifications = () => {
	const { data: projects } = useProjectsWithUnseenNotificationsQuery();
	return useMemo(
		() => projects?.some( project => project.hasUnseenNotifications ) || false,
		[ projects ]
	);
};

export default useHasUnseenNotifications;
