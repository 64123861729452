import { useCallback } from 'react';
import { ListContext } from './ListContext';

const useItemKey = <TItem>() => useCallback( ( index: number, data: ListContext<TItem> ) => {
	const item = data.itemAt( index );
	if ( !item ) return `list-empty-cell-${index}`;
	return data.itemKeyExtractor( item, index );
}, [ ] );

export default useItemKey;
