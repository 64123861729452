import { ITEM_UPLOAD_STATUS } from 'src/services/DirectFilesUploaderCoordinator';
import { IconProps } from 'src/new_arch/components/Icon/Icon';
import { ERROR, SUCCESS } from 'src/styles/colors';
import { Upload, UploadStatus } from './types';

export const hasFailed = ( upload: Upload ): boolean => (
	upload.status === ITEM_UPLOAD_STATUS.FAILED
);

export const hasSucceded = ( upload: Upload ): boolean => (
	upload.status === ITEM_UPLOAD_STATUS.SUCCEEDED
);

export const isUploading = ( upload: Upload ): boolean => (
	[
		ITEM_UPLOAD_STATUS.WAITING,
		ITEM_UPLOAD_STATUS.UPLOADING
	].includes( upload.status )
)

type IconConfig = { icon: IconProps[ 'icon' ], color?: string };
const ICON_CONFIG_BY_STATUS: Partial<Record<UploadStatus, IconConfig>> = {
	[ ITEM_UPLOAD_STATUS.ABORTED ]: { icon: 'remove_circle' },
	[ ITEM_UPLOAD_STATUS.FAILED ]: { icon: 'error', color: ERROR },
	[ ITEM_UPLOAD_STATUS.CREATING_ASSET ]: { icon: 'access_time' },
	[ ITEM_UPLOAD_STATUS.SUCCEEDED ]: { icon: 'check_circle', color: SUCCESS }
};

export const iconConfigFor = ( upload: Upload ): IconConfig | undefined => (
	ICON_CONFIG_BY_STATUS[ upload.status as UploadStatus ]
);

type StatusConfig = { text: string, className?: string };
const STATUS_CONFIG_BY_STATUS: Partial<Record<UploadStatus, StatusConfig>> = {
	[ ITEM_UPLOAD_STATUS.ABORTED ]: { text: 'Canceled', className: 'canceled' },
	[ ITEM_UPLOAD_STATUS.FAILED ]: { text: 'Error uploading', className: 'failed' },
	[ ITEM_UPLOAD_STATUS.CREATING_ASSET ]: { text: '' },
	[ ITEM_UPLOAD_STATUS.SUCCEEDED ]: { text: '' }
};

export const statusConfigFor = ( upload: Upload ): StatusConfig => {
	if ( isUploading( upload ) ) { return { text: upload.progressText }; }
	if ( hasSucceded( upload ) ) { return { text: upload.description }; }

	return STATUS_CONFIG_BY_STATUS[ upload.status as UploadStatus ] || { text: '' };
};
