import FolderTreeNode from './folderTreeNode';
import type FolderTreeJson from './folderTreeJson';

export default class FolderTree {
	root: FolderTreeNode;

	constructor( root: FolderTreeNode ) {
		this.root = root;
	}

	static fromJson( json: FolderTreeJson ) {
		return new FolderTree( FolderTreeNode.fromJson( json, 'project' ) );
	}

	get hasFolders() {
		return this.root.children.length > 0;
	}

	treeDataWithoutRoot( {
		includeFolderIf = () => true
	}: {
		includeFolderIf?: ( folderID: number ) => boolean
	} = {} ) {
		return this.root.treeDataWithoutRoot( includeFolderIf );
	}

	findFolder( folderID: number ) {
		return this.root.findFolderNode( folderID );
	}
}
