import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useNotificationSystem from '@notifications/systems/useNotificationSystem';
import Notification from '@notifications/models/Notification';

import { QueryKeys } from './queryKeys';
import { updateNotificationsInPaginatedData } from './updaters';

const useMarkNotificationAsClickedMutation = () => {
	const queryClient = useQueryClient();
	const notificationSystem = useNotificationSystem();

	const onSuccess = useCallback( ( _data: void, { notification }: { notification: Notification } ) => {
		const { projectID } = notification;

		queryClient.invalidateQueries( {
			queryKey: QueryKeys.forProject( { projectID } ), refetchType: 'none'
		} );
		queryClient.invalidateQueries( QueryKeys.forProjects() );

		queryClient.setQueriesData(
			QueryKeys.forProject( { projectID } ),
			updateNotificationsInPaginatedData(
				[ notification.id ],
				notificationJSON => ( { ...notificationJSON, was_clicked: true } )
			)
		);
	}, [ queryClient ] );

	return useMutation(
		( { notification }: { notification: Notification } ) => (
			notificationSystem.markNotificationAsClicked( { notificationID: notification.id } )
		),
		{ onSuccess }
	);
};

export default useMarkNotificationAsClickedMutation;
