import FilenameWithMatch, { FilenameWithMatchJSON } from './FilenameWithMatch';

type FilenamesMatchResultJSON = {
	matched: FilenameWithMatchJSON[];
	unmatched: string[];
};

export default class FilenamesMatchResult {
	constructor( public matched: FilenameWithMatch[], public unmatched: string[] ) {
		this.matched = matched;
		this.unmatched = unmatched;
	}

	get hasPartialMatches() {
		return this.partialMatches.length > 0;
	}

	get exactMatches() {
		return this.matched.filter( match => match.matchedBy === 'strict' );
	}

	get partialMatches() {
		return this.matched.filter( match => match.matchedBy !== 'strict' );
	}

	get totalFilenames() {
		return this.matched.length + this.unmatched.length;
	}

	static fromJson( json: FilenamesMatchResultJSON ) {
		return new FilenamesMatchResult(
			json.matched.map( match => FilenameWithMatch.fromJson( match ) ),
			json.unmatched
		);
	}
}
