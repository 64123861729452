import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useProjectsWithUnseenNotificationsQuery
	from '@notifications/queries/useProjectsWithUnseenNotificationsQuery';
import ProjectWithNotificationCount from '@notifications/models/ProjectWithNotificationCount';

const MIN_PROJECT_ON_SCREEN = 4;
const PROJECT_THUMBNAIL_SHADOW = 8;
const PROJECT_THUMBNAIL_HEIGHT = 56;
const PROJECT_THUMBNAIL_BOTTOM_MARGIN = 0;
const BUTTONS_SPACE = 18 * 2 + 4;

type UseNotificationsProjectsSidebarParams = {
	selectedProjectID: number | null
};

const useNotificationsProjectsSidebar = (
	{ selectedProjectID }: UseNotificationsProjectsSidebarParams
) => {
	const containerRef = useRef<HTMLDivElement>( null );
	const result = useProjectsWithUnseenNotificationsQuery();
	const projectsWithUnseenNotificationsCount = result.data || [];
	const [ currentPage, setCurrentPage ] = useState( 1 );
	const [ containerHeight, setContainerHeight ] = useState<number | undefined>( undefined );

	const itemsPerPage = useMemo( () => {
		const thumbnailWithMargin = PROJECT_THUMBNAIL_HEIGHT + PROJECT_THUMBNAIL_BOTTOM_MARGIN;
		if ( containerHeight ) {
			const minProjects = Math.floor( containerHeight / thumbnailWithMargin );
			const surplus = containerHeight - ( thumbnailWithMargin * minProjects );
			return surplus > PROJECT_THUMBNAIL_HEIGHT + PROJECT_THUMBNAIL_SHADOW
				? minProjects + 1 : minProjects;
		}
		return Math.min( projectsWithUnseenNotificationsCount.length, MIN_PROJECT_ON_SCREEN );
	}, [ containerHeight ] );

	const totalPages = useMemo( () => (
		Math.ceil( projectsWithUnseenNotificationsCount.length / itemsPerPage )
	), [ itemsPerPage, projectsWithUnseenNotificationsCount.length ] );

	const setElementsHeight = useCallback( () => {
		if ( !containerRef.current ) { return; }
		setContainerHeight( containerRef.current.offsetHeight - BUTTONS_SPACE );
	}, [] );

	const upButtonIsEnabled = currentPage > 1;
	const downButtonIsEnabled = currentPage < totalPages;

	const onUpButtonClicked = useCallback( () => {
		setCurrentPage( currentPage - 1 );
	}, [ currentPage ] );

	const onDownButtonClicked = useCallback( () => {
		setCurrentPage( currentPage + 1 );
	}, [ currentPage ] );

	useEffect( () => {
		const observer = new ResizeObserver( setElementsHeight );
		if ( containerRef.current ) { observer.observe( containerRef.current ); }
		return () => { observer.disconnect(); };
	}, [] );

	useEffect( () => { setElementsHeight(); }, [ containerRef.current ] );

	useEffect( () => {
		const selectedProjectIndex = projectsWithUnseenNotificationsCount.findIndex(
			element => element.id === selectedProjectID
		);

		const correctPage = Math.ceil( ( selectedProjectIndex + 1 ) / itemsPerPage ) || 1;
		setCurrentPage( correctPage );
	}, [ selectedProjectID ] );

	useEffect( () => {
		if ( currentPage > totalPages ) { setCurrentPage( totalPages ); }
		if ( currentPage < 1 ) { setCurrentPage( 1 ); }
	}, [ totalPages ] );

	const currentPageProjects = useMemo<ProjectWithNotificationCount[]>(
		() => projectsWithUnseenNotificationsCount
			.slice(
				( currentPage * itemsPerPage ) - itemsPerPage,
				currentPage * itemsPerPage
			),
		[ currentPage, itemsPerPage, projectsWithUnseenNotificationsCount ]
	);

	return {
		containerRef,
		currentPageProjects,
		upButtonIsEnabled,
		downButtonIsEnabled,
		onUpButtonClicked,
		onDownButtonClicked
	};
};

export default useNotificationsProjectsSidebar;
