import classNames from 'classnames';
import { Indicator, Avatar } from '@mantine/core';
import ProjectWithNotificationCount from '@notifications/models/ProjectWithNotificationCount';

import Tooltip from '../../../../../../../components/UI/Tooltip/Tooltip';
import { textEndEllipsis } from '../../../../../../../lib/textTruncate';

import './NotificationsProjectThumbnail.scss';

type NotificationsProjectThumbnailProps = {
	project: ProjectWithNotificationCount;
	highlighted: boolean;
	onClick?: ( projectID: number ) => void;
}

const NotificationsProjectThumbnail = ( {
	project, highlighted, onClick
}: NotificationsProjectThumbnailProps ) => {
	const notificationsCount = Math.min( project.unseenNotificationCount, 99 );
	const indicatorLabel = notificationsCount.toString();
	const showIndicator = notificationsCount > 0;

	return (
		<Tooltip position="right" title={textEndEllipsis( project.name )}>
			<button
				className={classNames( 'NotificationsProjectThumbnail', { highlighted } )}
				onClick={() => onClick?.( project.id )}
			>
				<Indicator
					color="red"
					label={indicatorLabel}
					disabled={!showIndicator}
					offset={2}
					zIndex={200}
				>
					<Avatar
						src={project.coverURL}
						alt={project.name}
						size="md"
						radius="sm"
					/>
				</Indicator>
				<div className="thumbnail-highlight" />
			</button>
		</Tooltip>
	);
};

export default NotificationsProjectThumbnail;
