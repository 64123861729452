/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Expandable, { ExpandableRef } from '@ui/components/Expandable/Expandable';
import { Divider } from '@mantine/core';
import { usePrevious } from '@mantine/hooks';

import { getProject } from 'src/selectors/projects';
import { getMainAssetsUploadsForProject } from 'src/selectors/assetUploads';
import { getMainFolderUploadsForProject } from 'src/selectors/folderUploads';
import FolderUpload from 'src/entities/folderUpload';
import AssetUpload from 'src/entities/assetUpload';
import { cancelAssetUpload } from 'src/actions/assetUploads';
import { ITEM_UPLOAD_STATUS } from 'src/services/DirectFilesUploaderCoordinator';

import UploadItem from './UploadItem/UploadItem';

type ProjectUploadsListProps = {
	projectID: number
};

const hasActiveStatus = ( upload: AssetUpload | FolderUpload ) => (
	[ ITEM_UPLOAD_STATUS.UPLOADING, ITEM_UPLOAD_STATUS.WAITING ].includes( upload.status )
);

const ProjectUploadsList = ( { projectID }: ProjectUploadsListProps ) => {
	const expandableRef = useRef<ExpandableRef>( null );

	// @ts-ignore
	const project = useSelector( state => getProject( state, { projectID } ) );
	// @ts-ignore
	const assetUploads = useSelector( state => getMainAssetsUploadsForProject( state, { projectID } ) );
	// @ts-ignore
	const folderUploads = useSelector( state => getMainFolderUploadsForProject( state, { projectID } ) );

	const uploads = useMemo( () => (
		[ ...folderUploads, ...assetUploads ].sort( ( a, b ) => ( a.statusOrder - b.statusOrder ) )
	), [ assetUploads, folderUploads ] );
	const hasActiveUploads = useMemo( () => uploads.some( hasActiveStatus ), [ uploads ] );
	const prevHadActiveUploads = usePrevious( hasActiveUploads );

	const dispatch = useDispatch();
	const onCancelClick = useCallback(
		( uploadID: number ) => dispatch( cancelAssetUpload( uploadID ) ),
		[ dispatch ]
	);

	useEffect( () => {
		if ( hasActiveUploads && !prevHadActiveUploads ) {
			expandableRef.current?.open();
		}
	}, [ hasActiveUploads ] );

	return (
		<Expandable ref={expandableRef} title={project?.name || 'Project'} initiallyOpened={hasActiveUploads}>
			{uploads.map( ( upload, index ) => (
				<>
					<UploadItem key={upload.id} upload={upload} onCancelClick={onCancelClick} />
					{index < ( uploads.length - 1 ) && (
						<Divider size="sm" color="gray.0" />
					)}
				</>
			) )}
		</Expandable>
	);
};

export default ProjectUploadsList;
