import { PRIMARY_BASE } from 'src/styles/colors';
import Checkbox from 'src/components/UI/Checkbox/Checkbox';
import NotificationOptionText from '../NotificationOptionText/NotificationOptionText';

type NotificationOptionProps = {
	title: string,
	description: string,
	inAppActive: boolean,
	emailActive: boolean,
	onInAppToggle: () => void,
	onEmailToggle: () => void
}

const NotificationOption = ( {
	title,
	description,
	inAppActive,
	emailActive,
	onInAppToggle,
	onEmailToggle
}: NotificationOptionProps ) => (
	<div className="setting" key={title} data-testid={title}>
		<NotificationOptionText
			title={title}
			description={description}
			className="option"
		/>
		<div className="in-app">
			<Checkbox
				value={inAppActive}
				onChange={onInAppToggle}
				checkedColor={PRIMARY_BASE}
				dataTestId="in-app-checkbox"
			/>
		</div>
		<div className="email">
			<Checkbox
				value={emailActive}
				onChange={onEmailToggle}
				checkedColor={PRIMARY_BASE}
				dataTestId="email-checkbox"
			/>
		</div>
	</div>
);

export default NotificationOption;
