import * as popupActions from '../actions/popups';

export default class PopupSystem {
	constructor( dispatch ) {
		this.dispatch = dispatch;
	}

	hideConfirmationDialog = () => this.dispatch( popupActions.hideConfirmationDialog() )

	showDialog( {
		title, description, acceptText, bigTitle, icon, descriptionAlignment
	} ) {
		return this.dispatch( popupActions.showDialog(
			title, description, acceptText, bigTitle, icon, descriptionAlignment
		) );
	}

	showErrorDialog( {
		title, description, acceptText, buttonType
	} ) {
		return this.dispatch( popupActions.showErrorDialog(
			title, description, acceptText, buttonType
		) );
	}

	showDefaultConfirmationDialog( {
		title, description, acceptText, cancelText, descriptionAlignment, acceptButtonColor
	} ) {
		return this.dispatch( popupActions.showDefaultConfirmationDialog(
			title, description, acceptText, cancelText, descriptionAlignment, acceptButtonColor
		) );
	}

	showDangerConfirmationDialog( {
		title, description, checkboxDescription, acceptText, cancelText, descriptionAlignment
	} ) {
		return this.dispatch( popupActions.showDangerConfirmationDialog(
			title, description, checkboxDescription, acceptText, cancelText, descriptionAlignment
		) );
	}

	showDialogWithIcon( {
		title, description, acceptText, isError, descriptionAlignment
	} ) {
		return this.dispatch( popupActions.showDialogWithIcon(
			title, description, acceptText, isError, descriptionAlignment
		) );
	}

	showDialogWithWarningIcon( {
		title, description, acceptText, isError, descriptionAlignment
	} ) {
		return this.dispatch( popupActions.showDialogWithWarningIcon( {
			title, description, acceptText, isError, descriptionAlignment
		} ) );
	}

	showRadioConfirmationDialog = (
		title, description, radioOptions, onRadioOptionSelect, acceptText, cancelText
	) => this.dispatch( popupActions.showRadioConfirmationDialog(
		title, description, radioOptions, onRadioOptionSelect, acceptText, cancelText
	) )

	showCustomPopup = ( { renderPopup } ) => (
		this.dispatch( dispatch => new Promise(
			( resolve ) => {
				const closeAndResolve = ( wasAccepted ) => {
					dispatch( popupActions.hidePopup() );
					return resolve( wasAccepted );
				};
				dispatch(
					popupActions.showPopup( {
						renderCustomPopup: () => renderPopup( {
							onConfirm: () => closeAndResolve( true ),
							onCancel: () => closeAndResolve( false )
						} )
					} )
				);
			}
		) )
	)

	showCustomPopupReturningValue = ( { renderPopup } ) => (
		this.dispatch( dispatch => new Promise(
			( resolve, reject ) => {
				const closeAndResolve = ( result ) => {
					dispatch( popupActions.hidePopup() );
					return resolve( result );
				};
				const closeAndReject = () => {
					dispatch( popupActions.hidePopup() );
					return reject( new Error( 'Cancelled' ) );
				};
				dispatch(
					popupActions.showPopup( {
						renderCustomPopup: () => renderPopup( {
							onConfirm: result => closeAndResolve( result ),
							onCancel: () => closeAndReject()
						} )
					} )
				);
			}
		) )
	)
}
