import User from '../../new_arch/models/user/user';
import SnackbarSystem from '../../systems/SnackbarSystem';
import OnboardingSurveyStep from './components/OnboardingSurveyStep';
import useOnboardingSurveyPopup from './hooks/useOnboardingSurveyPopup';

import './OnboardingSurveyPopup.scss';

type OnboardingSurveyPopupProps = {
	user: User,
	snackbarSystem: SnackbarSystem,
	onClose: () => void
}

const OnboardingSurveyPopup = ( { user, snackbarSystem, onClose }: OnboardingSurveyPopupProps ) => {
	const {
		currentField,
		isLoading
	} = useOnboardingSurveyPopup( { userId: user.id, snackbarSystem, onClose } );

	return (
		<div className="OnboardingSurveyPopup">
			<OnboardingSurveyStep
				user={user}
				isLoading={isLoading}
				{...currentField}
			/>
		</div>
	)
}

export default OnboardingSurveyPopup;
