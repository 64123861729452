import { type ReactNode } from 'react';
import classNames from 'classnames';

import PanelContainer from './PanelContainer/PanelContainer';

import './PaneledView.scss';

type PanelLocation = 'left' | 'right';

type PanelConfig = {
	name: string,
	location: PanelLocation,
	width: number,
	element: ReactNode,
	keepMounted: boolean
}

type PaneledViewProps = {
	panels: PanelConfig[],
	activeLeft?: string,
	activeRight?: string,
	className?: string,
	mainViewClassName?: string,
	renderPanelsIf?: boolean,
	children: ReactNode
}

const PaneledView = ( {
	panels,
	activeLeft,
	activeRight,
	className,
	mainViewClassName,
	renderPanelsIf = true,
	children
}: PaneledViewProps ) => {
	const renderPanels = ( location: PanelLocation ) => {
		if ( !renderPanelsIf ) return null;

		const activePanelName = location === 'left' ? activeLeft : activeRight;
		const cssPositioningProp = location === 'left' ? 'right' : 'left';

		const availablePanels = panels.filter( panel => panel.location === location );
		const activePanel = availablePanels.find( panel => panel.name === activePanelName );
		const activePanelWidth = activePanel?.width || 0;

		return (
			<div className="panels-container" style={{ width: activePanelWidth }}>
				{availablePanels.map(
					( { name, width, element, keepMounted } ) => {
						const isActive = name === activePanelName;
						const containerStyle = { [ cssPositioningProp ]: isActive ? 0 : activePanelWidth };

						return (
							<PanelContainer
								key={name}
								width={width}
								element={element}
								keepMounted={keepMounted}
								isActive={isActive}
								containerStyle={containerStyle}
							/>
						);
					}
				)}
			</div>
		);
	}

	return (
		<div className={classNames( 'PaneledView', className )}>
			{renderPanels( 'left' )}
			<div className={classNames( 'main-view', mainViewClassName )}>
				{children}
			</div>
			{renderPanels( 'right' )}
		</div>
	);
}

export default PaneledView;
