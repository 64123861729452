import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import * as projectsReducers from './projects';
import * as assetsReducers from './assets';
import * as commentsReducers from './comments';
import * as usersReducers from './users';
import * as tasksReducers from './tasks';
import * as currentUserReducers from './currentUser';
import * as projectsMembersReducers from './projectsMembers';
import * as workspacesMembersReducers from './workspacesMembers';
import * as snackbarReducers from './snackbar';
import * as markupEditorReducers from './markupEditor';
import * as projectsViewOptionsReducers from './projectsViewOptions';
import * as assetsViewOptions from './assetsViewOptions';
import * as popupsReducers from './popups';
import * as activitiesReducers from './activities';
import * as assetRoundsReducers from './assetRounds';
import * as assetUploads from './assetUploads';
import * as folderUploads from './folderUploads';
import * as sharedProjectReducers from './sharedProjects';
import * as workspaceProjectReducers from './workspaceProjects';
import * as projectInvitationsReducers from './projectInvitations';
import * as workspaceInvitationsReducers from './workspaceInvitations';
import * as assetsFilterAndSortOptionsReducers from './assetsFilterAndSortOptions';
import * as workspacesReducers from './workspaces';
import * as pricingPlans from './pricingPlans';
import * as pricingPlanPrices from './pricingPlanPrices';
import * as workspaceInvoicesReducers from './workspaceInvoices';
import * as workspacesBillingInformation from './workspacesBillingInformation';
import * as creditCardsReducers from './creditCards';
import * as projectsMetricsReducers from './projectsMetrics';
import * as assetCommentLastSeenByUsers from './assetCommentLastSeenByUsers';
import * as commentsFilterOptions from './commentsFilterOptions';
import * as subscriptions from './subscriptions';
import * as closedBanners from './closedBanners';
import * as overlay from './overlay';
import * as labels from './labels';
import * as userPreferences from './userPreferences';
import * as addOns from './addOns';
import * as rotateMobileScreen from './rotateMobileScreen';
import * as folders from './folders';
import * as modals from './modals';
import * as contactGroups from './contactGroups';
import * as labelGroups from './labelGroups';

export default history => combineReducers( {
	router: connectRouter( history ),
	...projectsReducers,
	...assetsReducers,
	...commentsReducers,
	...usersReducers,
	...tasksReducers,
	...currentUserReducers,
	...projectsMembersReducers,
	...workspacesMembersReducers,
	...snackbarReducers,
	...markupEditorReducers,
	...projectsViewOptionsReducers,
	...assetsViewOptions,
	...popupsReducers,
	...activitiesReducers,
	...assetRoundsReducers,
	...assetUploads,
	...folderUploads,
	...sharedProjectReducers,
	...workspaceProjectReducers,
	...projectInvitationsReducers,
	...workspaceInvitationsReducers,
	...assetsFilterAndSortOptionsReducers,
	...workspacesReducers,
	...pricingPlans,
	...pricingPlanPrices,
	...workspaceInvoicesReducers,
	...workspacesBillingInformation,
	...creditCardsReducers,
	...projectsMetricsReducers,
	...assetCommentLastSeenByUsers,
	...commentsFilterOptions,
	...subscriptions,
	...closedBanners,
	...overlay,
	...labels,
	...userPreferences,
	...addOns,
	...rotateMobileScreen,
	...folders,
	...modals,
	...contactGroups,
	...labelGroups
} );
