import UploadPlaceholderImage from 'src/assets/img/upload-placeholder.svg';
import Button from '@ui/components/Button/Button';

import styles from './EmptyPlaceholder.module.scss';

type EmptyPlaceholderProps = {
	isInsideAproject: boolean;
	onStartUploadClick?: () => void;
};

const EmptyPlaceholder = ( {
	isInsideAproject,
	onStartUploadClick
}: EmptyPlaceholderProps ) => (
	<div className={styles.EmptyPlaceholder}>
		<div className={styles.EmptyPlaceholder__content}>
			<img
				className="download-icon"
				src={UploadPlaceholderImage}
				alt="download icon"
			/>
			<div className={styles.EmptyPlaceholder__details}>
				{isInsideAproject ? (
					<>
						<p>Drag and drop files on the screen</p>
						<p>or</p>
						<Button
							size="sm"
							onClick={onStartUploadClick}
						>
							Start Upload
						</Button>
					</>
				) : (
					<>
						<p>No uploads yet</p>
						<p>Start adding assets to your projects to see them displayed here.</p>
					</>
				)}
			</div>
		</div>
	</div>
);

export default EmptyPlaceholder;
