import { Badge, MantineColor } from '@mantine/core';
import Select, { components } from 'react-select';
import { StylesConfig } from 'react-select/src/styles';

interface Option {
	value: string;
	label: string;
	color: MantineColor;
}

type BadgeSelectProps = {
	options: Option[],
	onChange: ( selectedOption: any ) => void
	value: string,
	containerRef?: React.RefObject<HTMLElement>,
	label?: string
};

const styles: StylesConfig = {
	menuList: provided => ( {
		...provided,
		padding: 4
	} ),
	option: provided => ( {
		...provided,
		'backgroundColor': 'white',
		'cursor': 'pointer',
		'&:not(:last-child)': {
			borderBottom: '1px solid var(--mantine-color-gray-1)'
		},
		'&:active': {
			backgroundColor: 'white'
		},
		'padding': '5px'
	} ),
	singleValue: provided => ( {
		...provided,
		backgroundColor: 'white',
		padding: 0
	} ),
	control: provided => ( {
		...provided,
		padding: '0',
		border: 'none',
		boxShadow: 'none',
		cursor: 'pointer'
	} ),
	indicatorSeparator: provided => ( {
		...provided,
		display: 'none'
	} )
};

const BadgeOption = ( props: any ) => {
	const { data } = props;
	return (
		<components.Option {...props}>
			<Badge variant="light" radius="xs" size="md" color={data.color}>
				{data.label}
			</Badge>
		</components.Option>
	);
};

const BadgeSingleValue = ( props: any ) => {
	const { data } = props;
	return (
		<components.SingleValue {...props}>
			<Badge variant="light" radius="xs" size="md" color={data.color}>
				{data.label}
			</Badge>
		</components.SingleValue>
	);
};

const BadgeSelect: React.FC<BadgeSelectProps> = ( {
	options,
	onChange,
	value,
	containerRef,
	label
}: BadgeSelectProps ) => (
	<Select
		value={options.find( option => option.value === value )}
		options={options}
		styles={styles}
		components={{ Option: BadgeOption, SingleValue: BadgeSingleValue }}
		onChange={onChange}
		placeholder="Select a color..."
		menuPortalTarget={containerRef?.current}
		menuShouldBlockScroll
		menuPosition="fixed"
		aria-label={label || 'BadgeSelect'}
	/>
);

export default BadgeSelect;
