import { ProjectStatus } from '@projects/models/projectTree';
import useFetchProjectTreeQuery from '@projects/queries/useFetchProjectTreeQuery';
import {
	type OrganizerClickCallback,
	type OrganizerAttributes,
	type PinningConfig,
	type RightCaptionRenderer
} from '@navigationPanel/types';
import ProjectTreeNode from './ProjectTreeNode/ProjectTreeNode';

import './ProjectTree.scss';

type ProjectTreeProps = {
	status: ProjectStatus,
	initiallyExpandedProjectIDs?: number[],
	initiallyExpandedFolderIDs?: number[],
	onProjectExpandedChanged?: ( projectID: number, expanded: boolean ) => void,
	onFolderExpandedChanged?: ( folderID: number, expanded: boolean ) => void,
	selectedOrganizer?: OrganizerAttributes,
	onOrganizerClick?: OrganizerClickCallback,
	pinning: PinningConfig,
	rightCaptionRenderer?: RightCaptionRenderer
	renderFolderIf?: ( folderID: number ) => boolean
}

const ProjectTree = ( {
	status,
	initiallyExpandedProjectIDs = [],
	initiallyExpandedFolderIDs = [],
	onProjectExpandedChanged = () => {},
	onFolderExpandedChanged = () => {},
	selectedOrganizer,
	onOrganizerClick = () => {},
	pinning,
	rightCaptionRenderer = () => null,
	renderFolderIf = () => true
}: ProjectTreeProps ) => {
	const { data: projectTree } = useFetchProjectTreeQuery();
	const projects = projectTree?.[ status ] || [];

	return (
		<div className="ProjectTree">
			{projects.map( project => (
				<ProjectTreeNode
					key={project.id}
					project={project}
					initiallyExpanded={initiallyExpandedProjectIDs.includes( project.id )}
					initiallyExpandedFolderIDs={initiallyExpandedFolderIDs}
					onExpandedChanged={onProjectExpandedChanged}
					onFolderExpandedChanged={onFolderExpandedChanged}
					selectedOrganizer={selectedOrganizer}
					onOrganizerClick={onOrganizerClick}
					pinning={pinning}
					rightCaptionRenderer={rightCaptionRenderer}
					renderFolderIf={renderFolderIf}
				/>
			) )}
		</div>
	)
}

export default ProjectTree;
