import { type Comparator, comparatorBy, compose, flip } from 'src/new_arch/lib/comparators';

export type ProjectTreeItem = {
	id: number,
	name: string,
	isPinned: boolean,
	isSuspended: boolean
};

export type ProjectTreeItemJSON = {
	id: number,
	name: string,
	is_suspended: boolean,
	is_pinned: boolean
};

export type ProjectStatus = 'active' | 'hidden';

export type ProjectTreeJSON = {
	active: ProjectTreeItemJSON[];
	archived: ProjectTreeItemJSON[];
}

const notSuspended = ( project: ProjectTreeItemJSON ) => !project.is_suspended;
const byName = comparatorBy( 'name', { natural: true } );
const byPinnedAndName = compose<ProjectTreeItemJSON>(
	flip( comparatorBy( 'is_pinned' ) ),
	byName
);
const projectTreeItemFromJson = ( { id, name, is_pinned, is_suspended }: ProjectTreeItemJSON ) => ( {
	id,
	name,
	isPinned: is_pinned,
	isSuspended: is_suspended
} );

export default class ProjectTree {
	active: ProjectTreeItem[];
	hidden: ProjectTreeItem[];

	constructor( active: ProjectTreeItem[], hidden: ProjectTreeItem[] ) {
		this.active = active;
		this.hidden = hidden;
	}

	static fromJson( json: ProjectTreeJSON ) {
		const projectsFromJson = (
			projects: ProjectTreeItemJSON[],
			sortBy: Comparator<ProjectTreeItemJSON>
		) => projects
			.filter( notSuspended )
			.sort( sortBy )
			.map( projectTreeItemFromJson );

		return new ProjectTree(
			projectsFromJson( json.active, byPinnedAndName ),
			projectsFromJson( json.archived, byName )
		);
	}

	get hasActiveProjects() {
		return this.active.length > 0;
	}

	get hasHiddenProjects() {
		return this.hidden.length > 0;
	}

	get isEmpty() {
		return !this.hasActiveProjects && !this.hasHiddenProjects;
	}

	findProject( projectID: number ) {
		return this.active.find( project => project.id === projectID )
			|| this.hidden.find( project => project.id === projectID )
			|| null;
	}
}
