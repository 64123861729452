import { type ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { useHotkeys } from '@mantine/hooks';
import useNavigationPanelIsAvailable from '@navigationPanel/hooks/useNavigationPanelIsAvailable';
import classNames from 'classnames';
import { PaneledView } from '../../../../components/PaneledView';
import useNavigationPanelStore from '../../hooks/useNavigationPanelStore';
import NavigationPanel from '../NavigationPanel/NavigationPanel';

import './WithNavigationPanel.scss';

type WithNavigationPanelProps = {
	children: ReactNode,
	childrenContainerClassName?: string
}

const WithNavigationPanel = ( { children, childrenContainerClassName }: WithNavigationPanelProps ) => {
	const { isOpen, togglePanelOpen } = useNavigationPanelStore();
	const panelIsAvailable = useNavigationPanelIsAvailable();

	useHotkeys( [ [ 'mod+P', togglePanelOpen ] ] );

	return (
		<PaneledView
			panels={[ {
				name: 'navigation',
				location: 'left',
				width: 240,
				element: <NavigationPanel />,
				keepMounted: false
			} ]}
			activeLeft={isOpen ? 'navigation' : undefined}
			className={classNames( 'WithNavigationPanel', { panelIsAvailable } )}
			mainViewClassName={childrenContainerClassName}
			renderPanelsIf={panelIsAvailable}
		>
			{children}
		</PaneledView>
	);
}

export default observer( WithNavigationPanel );
