import { CSSProperties } from 'react';

export const headingsSizes = {
	h1: { fontSize: '24px', fontWeight: 600, lineHeight: 'normal', letterSpacing: '-0.45px' },
	h2: { fontSize: '20px', fontWeight: 700, lineHeight: 'normal', letterSpacing: '-0.375px' },
	h3: { fontSize: '16px', fontWeight: 700, lineHeight: 'normal', letterSpacing: 'normal' },
	h4: { fontSize: '16px', fontWeight: 600, lineHeight: 'normal', letterSpacing: 'normal' },
	h5: { fontSize: '14px', fontWeight: 600, lineHeight: 'normal', letterSpacing: 'normal' },
	h6: { fontSize: '14px', fontWeight: 400, lineHeight: 'normal', letterSpacing: 'normal' }
};

export const textVariants = {
	'body': { fontSize: '14px', fontWeight: 400, lineHeight: '19px', letterSpacing: 0.14 },
	'body-bold': { fontSize: '14px', fontWeight: 600, lineHeight: '19px', letterSpacing: 0.14 },
	'footnote': { fontSize: '12px', fontWeight: 400, lineHeight: '16px', letterSpacing: 0.13 },
	'footnote-semibold': { fontSize: '12px', fontWeight: 600, lineHeight: 1.33, letterSpacing: 0.13 },
	'caption': { fontSize: '10px', fontWeight: 700, lineHeight: '14px', letterSpacing: 0.11 },
	'caption-semibold': { fontSize: '10px', fontStyle: 'normal', fontWeight: 600, lineHeight: '14px', letterSpacing: 0.108 },
	'caption-regular': { fontSize: '10px', fontStyle: 'normal', fontWeight: 400, lineHeight: '14px', letterSpacing: 0.108 }
} as Record<string, CSSProperties>;
