import assetType from './assetType';
import FilterOption from './filterOption';
import Filters from './filters';

export default class AssetFilters {
	constructor( { filterByAttribute = FilterOption.none, filterByName = '', filterByType = assetType.IMAGE } ) {
		this.filterByAttribute = filterByAttribute;
		this.filterByName = filterByName;
		this.filterByType = filterByType;
	}

	get filterByAttributeID() {
		return this.filterByAttribute?.id;
	}

	get isSearching() {
		return ( this.filterByName?.length ?? 0 ) > 0;
	}

	get isFiltering() {
		return this.filterByAttribute?.isFiltering;
	}

	get isFilteringOrSearching() {
		return this.isFiltering || this.isSearching;
	}

	match( item ) {
		return this._filters.match( item );
	}

	apply( collection ) {
		return collection.filter( item => this.match( item ) );
	}

	toJSON() {
		return {
			...(
				this.filterByAttribute?.isFiltering
					? { [ this.filterByAttribute.filter ]: this.filterByAttribute.value }
					: {}
			),
			name: this.filterByName,
			type: this.filterByType
		};
	}

	encode( { omitDefaults = true } = {} ) {
		if ( omitDefaults && this._usingDefaultFilters ) return undefined;

		return encodeURIComponent( JSON.stringify( this.toJSON() ) );
	}

	get _filterByNameCondition() {
		return { field: 'name', value: this.filterByName, type: 'includes' };
	}

	get filterByTypeCondition() {
		return { field: 'isImage', value: assetType.IMAGE === this.filterByType, type: 'equal' };
	}

	get _usingDefaultFilters() {
		return this.filterByAttribute === FilterOption.none && this.filterByName === '';
	}

	get _filters() {
		return new Filters( [
			this.filterByAttribute?.filterCondition,
			this.filterByTypeCondition,
			this._filterByNameCondition
		] );
	}
}
