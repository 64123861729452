/*
The purpose of this hook is to provide an instance of the PopupSystem from
the old code/architecture, which manages modals with Redux, so that it can
be used to show existing modals inside new arch's code and therefore avoid
the refactor of the modals with the development cost it carries. It should
only be used temporarily.
*/

import { useDispatch } from 'react-redux';
import PopupSystem from '../../systems/PopupSystem';

const useReduxPopupSystem = () => new PopupSystem( useDispatch() );

export default useReduxPopupSystem;
