import { CSSProperties, memo } from 'react';
import className from 'classnames';
import { Avatar, Box, Group, Indicator, Stack } from '@mantine/core';
import { textEndEllipsis } from 'src/lib/textTruncate';
import Notification from '@notifications/models/Notification';

import TimePassed from '../../../../../../../components/TimePassed/TimePassed';
import useNotificationListItem from './useNotificationListItem';
import { truncateFilePathsText } from './utils';

import './NotificationListItem.scss';

export const ESTIMATED_ROW_HEIGHT = 100;
const ONE_LINE_CONTENT_ROW_HEIGHT = 78;
const TWO_LINES_CONTENT_ROW_HEIGHT = 90;
const COMMENT_PREVIEW_EXTRA_HEIGHT = 24;
const MAX_CHARACTERS_IN_ONE_LINE = 44;

export const heightForNotification = ( notification: Notification ) => {
	const titleText = notification.title;
	const messageText = truncateFilePathsText( notification.message );
	const text = `${titleText} ${messageText}`;

	const contentHeight = (
		text.length > MAX_CHARACTERS_IN_ONE_LINE ? TWO_LINES_CONTENT_ROW_HEIGHT : ONE_LINE_CONTENT_ROW_HEIGHT
	);
	const commentPreviewExtraHeight = (
		notification.isForComment ? COMMENT_PREVIEW_EXTRA_HEIGHT : 0
	);

	return contentHeight + commentPreviewExtraHeight;
}

type NotificationListItemProps = {
	notification: Notification,
	style: CSSProperties,
	onNotificationClicked?: ( notification: Notification ) => void,
	onNotificationProjectClicked?: ( notification: Notification ) => void,
	onNotificationFolderClicked?: ( notification: Notification ) => void
};

const NotificationListItem = ( {
	notification, onNotificationClicked, onNotificationProjectClicked, onNotificationFolderClicked, style
}: NotificationListItemProps ) => {
	const {
		isNew,
		wasClicked,
		breakMessageWords,
		contentHtml,
		relatedObjectHtml,
		notificationClicked,
		notificationProjectClicked,
		notificationFolderClicked
	} = useNotificationListItem( {
		notification,
		onNotificationClicked,
		onNotificationProjectClicked,
		onNotificationFolderClicked
	} );

	return (
		<Group
			className={className( 'NotificationListItem', { isNew, wasClicked, breakMessageWords } )}
			style={style}
			onClick={notificationClicked}
			spacing={8}
		>
			<Box className="avatar">
				<Indicator color="red" position="top-start" disabled={!isNew}>
					<Avatar size={38} radius="xl" src={notification.image} alt={notification.title} />
				</Indicator>
			</Box>
			<Stack className="content" justify="space-between" spacing={8}>
				<Box className="message">
					<span className="name">{notification.title}</span>
					{' '}
					{contentHtml}
					{notification.hasRelatedObjectText && (
						<p
							className="related-text"
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: (
									`"${relatedObjectHtml}"`
								)
							}}
						/>
					)}
				</Box>
				<Group align="center" position="apart" spacing={2}>
					<Group className="breadcrumbs">
						<div className="project-redirection" onClick={notificationProjectClicked}>
							<p>{textEndEllipsis( notification.projectName, notification.folderID ? 13 : 35 )}</p>
						</div>
						{notification.folderID && (
							<>{notification.folderHasAncestors && ( <p>/...</p> )}
								/
								<div className="folder-redirection" onClick={notificationFolderClicked}>
									<p>{textEndEllipsis( notification.folderName, 15 )}</p>
								</div>
							</>
						)}
					</Group>
					<TimePassed date={notification.createdAt} />
				</Group>
			</Stack>
		</Group>
	);
};

export default memo( NotificationListItem );
