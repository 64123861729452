import { observer } from 'mobx-react-lite';

import type Node from '../Node';
import { type NodeRenderer } from '../types';

import './TreeViewNode.scss';

type TreeViewNodeProps = {
	node: Node,
	renderNode: NodeRenderer
}

const TreeViewNode = observer( ( { node, renderNode }: TreeViewNodeProps ) => (
	<li className="TreeViewNode">
		{renderNode( node )}
		{node.open && (
			<ul>
				{node.children.map( child => (
					<TreeViewNode
						node={child}
						renderNode={renderNode}
						key={child.id}
					/>
				) )}
			</ul>
		)}
	</li>
) );

export default TreeViewNode;
