import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useNotificationSystem from '@notifications/systems/useNotificationSystem';

import { QueryKeys } from './queryKeys';
import { updateAllNotificationsInPaginatedData } from './updaters';

const useMarkNotificationsAsSeenMutation = () => {
	const queryClient = useQueryClient();
	const notificationSystem = useNotificationSystem();

	const onSuccess = useCallback( ( _data: void, { projectID }: { projectID: number } ) => {
		queryClient.invalidateQueries( {
			queryKey: QueryKeys.forProject( { projectID } ), refetchType: 'none'
		} );
		queryClient.invalidateQueries( QueryKeys.forProjects() );

		queryClient.setQueriesData(
			QueryKeys.forProject( { projectID } ),
			updateAllNotificationsInPaginatedData(
				notification => ( { ...notification, was_read: true } )
			)
		);
	}, [ queryClient ] );

	return useMutation(
		( { projectID }: { projectID: number } ) => (
			notificationSystem.markNotificationsAsSeen( { projectID } )
		),
		{ onSuccess }
	);
};

export default useMarkNotificationsAsSeenMutation;
