import { QueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { Map } from 'immutable';
import { Dispatch } from 'redux';
import { CURRENT_USER_KEY } from '../modules/authentication/queries/keys';
import User, { UserJson } from '../models/user/user';
import Label from '../../entities/label';
import { QueryFilters, QueryKeys } from '../modules/projectView/queries/queryKeys';
import {
	addItem,
	addOrUpdateItemPaginated,
	QueryDataUpdater,
	removeItems,
	removeItemsPaginated,
	updateItemProperty,
	updateItems,
	updateItemsPaginated,
	updateItemsPropertyPaginated,
	updateQueriesData,
	type PaginatedDataUpdater
} from '../queries/updaters';
import { AssetJSON } from '../modules/projectView/models/asset';
import { AssetRoundJSON } from '../modules/projectView/models/assetRound';
import { queryClient as globalQueryClient } from '../../store/store';
import Asset from '../../entities/asset';
import Folder from '../../entities/folder';
import { FolderJSON } from '../modules/projectView/models/folder';

export type currentUserJson = {
	userID: number,
	userPreferencesID: number,
	notificationsPreferencesID: number
};

type Reducers = {
	currentUser?: currentUserJson,
	users?: Map<number, UserJson>,
	userPreferences?: Map<string, unknown>,
	projectsViewOptions?: Map<string, unknown>,
};

export type ReduxStore = {
	getState: () => Reducers,
	dispatch: Dispatch,
	subscribe: ( callback: () => void ) => () => void
};

type QueryLocation = {
	projectID: number,
	folderID?: number | null
}

type InvalidationParams = QueryLocation & { refetchActiveQueries?: boolean }
type ItemsType = 'assets' | 'folders';
type ParentUploadsData = Array<{ uploadedFolderID: number, uploadedAtFolderID: number | null }>;

const idsOf = ( items: Asset[] | Folder[] ) => items.map( i => i.id as number );
const asItemsWithId = ( ids: number[] ) => ids.map( id => ( { id } ) );

/*
* This class is responsible for updating the query client when the redux store changes.
*/

export default class QueryClientUpdater {
	store: ReduxStore;
	queryClient: QueryClient;
	reducers: Reducers;

	constructor( { store, queryClient } : { store: ReduxStore, queryClient: QueryClient } ) {
		this.store = store;
		this.queryClient = queryClient;
		this.reducers = {};
	}

	start() {
		this.observeReducer( 'currentUser', this.updateCurrentUser );
		this.observeReducer( 'users', this.updateCurrentUser );
		this.observeReducer( 'userPreferences', this.updateUserPreferences );
		this.observeReducer( 'projectsViewOptions', this.updateProjectViewOptions );
	}

	static createdFolder( folderJson: FolderJSON ) {
		const projectID = folderJson.project_id;
		const folderID = folderJson.organizer_type === 'Folder' ? folderJson.organizer_id : null;
		this.updateFolders( { projectID, folderID, updater: addItem( folderJson ) } );
		this.invalidateFolders( { projectID, folderID } );
		this.invalidateFoldersTree( { projectID } );
	}

	static addedLabelToAssets( { assets, label }: { assets: Asset[], label: Label } ) {
		this.changedAssetsLabel( { assets, newLabelJson: label.toJSON() } );
	}

	static removedLabelFromAssets( { assets }: { assets: Asset[] } ) {
		this.changedAssetsLabel( { assets, newLabelJson: null } );
	}

	static updatedOrDeletedLabel( { labelJson }: { labelJson: ReturnType<Label[ 'toJSON' ]> } ) {
		if ( labelJson.labellable_type === 'Project' ) {
			this.invalidateAssets( {
				projectID: labelJson.labellable_id,
				refetchActiveQueries: true
			} );
		}
	}

	static createdOrUpdatedAsset( {
		assetJson, projectID, parentUploadsData
	}: {
		assetJson: AssetJSON, projectID: number, parentUploadsData: ParentUploadsData
	} ) {
		const { folder_id: folderID } = assetJson;

		this.updateAssets( {
			projectID,
			folderID,
			updater: addOrUpdateItemPaginated<AssetJSON>( assetJson )
		} );
		this.invalidateAssets( { projectID, folderID } );
		this.invalidateFolders( { projectID } );

		/* The parentUploadsData parameter provides information about the folder uploads
		under which the asset creation happened (in case it was indeed created through a
		folder upload). The first element of the array describes the upload of the folder
		directly containing the asset; the second one describes the parent upload of the
		first one, and so on and so forth. The last element is the root folder being uploaded.
		Each folder upload description comes with the ID of the folder being uploaded, and
		the ID of the folder containing that folder.

		This information is used here to synchronously update the folders affected by this
		asset creation –updating asset count and cover if needed–, as an alternative to just
		invalidating and refetching folder queries, which would be innefficient if many assets
		are being uploaded at once.
		*/
		const { isDocument } = Asset.fromJSON( assetJson );

		parentUploadsData.forEach( ( { uploadedFolderID, uploadedAtFolderID } ) => {
			const updateFolderWithNewAsset = ( folder: FolderJSON ) => {
				const countToUpdate = isDocument ? 'documents_count' : 'images_count';
				const shouldSetNewAssetAsCover = folder.id === folderID && !folder.cover_url;
				const coverUrl = shouldSetNewAssetAsCover
					? assetJson.current_version.thumbnail_url
					: folder.cover_url;

				return {
					...folder,
					[ countToUpdate ]: folder[ countToUpdate ] + 1,
					cover_url: coverUrl
				}
			}

			this.updateFolders( {
				projectID,
				folderID: uploadedAtFolderID,
				updater: updateItems( [ uploadedFolderID ], updateFolderWithNewAsset )
			} );
		} );
	}

	static createdAssetRound( {
		newRoundJson, assetID, projectID, folderID = null
	}: {
		newRoundJson: AssetRoundJSON,
		assetID: number,
		projectID: number,
		folderID?: number | null
	} ) {
		const lastActivityAt = Date();

		this.updateAssets( {
			projectID,
			folderID,
			updater: updateItemsPaginated<AssetJSON>(
				[ assetID ],
				asset => ( {
					...asset,
					name: newRoundJson.name,
					current_version: newRoundJson,
					version_id: newRoundJson.id,
					asset_versions_count: asset.asset_versions_count + 1,
					last_activity_at: lastActivityAt,
					updated_at: lastActivityAt
				} )
			)
		} );
		this.invalidateAssets( { projectID, folderID } );
	}

	static updatedAssetCurrentVersion( {
		currentVersionJson, assetID, projectID, folderID = null
	}: {
		currentVersionJson: AssetRoundJSON,
		assetID: number,
		projectID: number,
		folderID?: number | null
	} ) {
		this.updateAssets( {
			projectID,
			folderID,
			updater: updateItemsPaginated(
				[ assetID ],
				asset => (
					asset.current_version.id === currentVersionJson.id ? ( {
						...asset,
						name: currentVersionJson.name,
						current_version: currentVersionJson
					} ) : asset
				)
			)
		} );
		this.invalidateAssets( { projectID, folderID } );
	}

	static deletedAssetsLastRound( { assets }: { assets: Asset[] } ) {
		this.extractingQueryLocation( { assets }, ( { projectID, folderID } ) => {
			this.invalidateAssets( { projectID, folderID, refetchActiveQueries: true } );
		} );
	}

	static changedAssetsApprovalStatus( { assets, toApproved }: { assets: Asset[], toApproved: boolean } ) {
		this.extractingQueryLocation( { assets }, ( { projectID, folderID } ) => {
			this.updateAssets( {
				projectID,
				folderID,
				updater: updateItemsPropertyPaginated(
					idsOf( assets ),
					'approved_at',
					toApproved ? Date() : null
				)
			} );
			this.invalidateAssets( { projectID, folderID } );
			this.invalidateFolders( { projectID } );
		} );
	}

	static assetsWereDeleted( { assetIDs, projectID }: { assetIDs: number[], projectID: number } ) {
		this.updateAssets( {
			projectID,
			updater: removeItemsPaginated( asItemsWithId( assetIDs ) )
		} );
		this.invalidateAssetsAndFolders( { projectID } );
	}

	static foldersWereDeleted( { folderIDs, projectID }: { folderIDs: number[], projectID: number } ) {
		this.updateFolders( {
			projectID,
			updater: removeItems( asItemsWithId( folderIDs ) )
		} );
		this.invalidateFolders( { projectID } );
		this.invalidateFoldersTree( { projectID } );
	}

	static itemsWereUnfolded( { assets, folders }: { assets: Asset[], folders: Folder[] } ) {
		this.extractingQueryLocation( { assets, folders }, ( { projectID, folderID } ) => {
			this.updateAssets( { projectID, folderID, updater: removeItemsPaginated( assets ) } );
			this.updateFolders( { projectID, folderID, updater: removeItems( folders ) } );

			this.invalidateAssets( { projectID, folderID } );
			this.invalidateAssets( { projectID, folderID: null } );
			this.invalidateFolders( { projectID } );

			if ( folders.length > 0 ) {
				this.invalidateFoldersTree( { projectID } );
			}
		} );
	}

	static folderCoverChanged( folder: Folder ) {
		const { projectID, folderID } = folder;
		this.invalidateFolders( { projectID, folderID } )
	}

	static folderRenamed(
		{ folderID, projectID, newName }: { folderID: number, projectID: number, newName: string }
	) {
		this.updateFolders( {
			projectID,
			updater: updateItemProperty( folderID, 'name', newName )
		} );

		this.invalidateFolders( { projectID } );
		this.invalidateFoldersTree( { projectID } );
	}

	static commentCreated( { forAsset }: { forAsset: Asset } ) {
		this.invalidateAssetsAndFoldersForCommentInAsset( forAsset );
	}

	static commentCompletionToggled( { forAsset }: { forAsset: Asset } ) {
		this.invalidateAssetsAndFoldersForCommentInAsset( forAsset );
	}

	private static invalidateAssetsAndFoldersForCommentInAsset( asset: Asset ) {
		const { projectID, folderID } = asset;
		this.invalidateAssets( { projectID, folderID } );
		this.invalidateFolders( { projectID } );
	}

	private updateCurrentUser = ( _: unknown, { currentUser, users }: Reducers ) => {
		this.queryClient.setQueryData(
			CURRENT_USER_KEY,
			( oldUserJson?: UserJson ) => {
				if ( !oldUserJson || !currentUser || !users ) return undefined;
				const newUserJson = users.get( currentUser.userID );
				return { ...oldUserJson, ...newUserJson };
			}
		);
	}

	private updateProjectViewOptions = ( projectsViewOptionsReducer: Reducers['projectsViewOptions'] ) => {
		this.queryClient.setQueryData(
			CURRENT_USER_KEY,
			( oldUserJson? : UserJson ) => {
				if ( !oldUserJson ) return undefined;
				const user = User.fromJson( oldUserJson );
				user.updateProjectsOrder( projectsViewOptionsReducer?.get( 'customSort' ) as number[] );
				return user.toJson();
			}
		);
	}

	private updateUserPreferences = ( userPreferencesReducer: Reducers['userPreferences'] ) => {
		this.queryClient.setQueryData(
			CURRENT_USER_KEY,
			( oldUserJson? : UserJson ) => {
				if ( !oldUserJson ) return undefined;
				return {
					...oldUserJson,
					user_preference: userPreferencesReducer?.first() as UserJson['user_preference']
				};
			}
		);
	}

	private observeReducer<ReducerName extends keyof Reducers>(
		reducerName: ReducerName, callback: ( reducer: Reducers[ReducerName], state: Reducers ) => void
	) {
		return this.store.subscribe( () => {
			const allReducers = this.store.getState();
			const nextState = allReducers[ reducerName ];
			const previousState = this.reducers[ reducerName ];
			if ( isEqual( nextState, previousState ) ) return;
			this.reducers[ reducerName ] = nextState;
			callback( nextState, allReducers );
		} );
	}

	// --- Helper methods for updating and invalidating asset and folder queries ---
	private static changedAssetsLabel( {
		assets, newLabelJson
	}: {
		assets: Asset[], newLabelJson: ReturnType<Label[ 'toJSON' ]> | null
	} ) {
		this.extractingQueryLocation( { assets }, ( { projectID, folderID } ) => {
			this.updateAssets( {
				projectID,
				folderID,
				updater: updateItemsPropertyPaginated(
					idsOf( assets ),
					'label',
					newLabelJson
				)
			} );
			this.invalidateAssets( { projectID, folderID } );
			this.invalidateFolders( { projectID } );
		} );
	}

	private static updateAssets( params: QueryLocation & { updater: PaginatedDataUpdater<AssetJSON> } ) {
		this.updateItems( { ...params, itemsType: 'assets' } );
	}

	private static updateFolders( params: QueryLocation & { updater: ( data: FolderJSON[] ) => FolderJSON[] } ) {
		this.updateItems( { ...params, itemsType: 'folders' } );
	}

	private static updateItems<T>( {
		projectID, folderID, itemsType, updater
	}: QueryLocation & {
		itemsType: ItemsType,
		updater: QueryDataUpdater<T>
	} ) {
		updateQueriesData(
			globalQueryClient,
			QueryFilters.forAssetAndFolderLists( {
				projectID,
				folderID,
				only: itemsType,
				queryType: 'active'
			} ),
			updater
		);
	}

	private static invalidateAssets( params: InvalidationParams ) {
		this.invalidateItems( { ...params, itemsType: 'assets' } );
	}

	private static invalidateFolders( params: InvalidationParams ) {
		this.invalidateItems( { ...params, itemsType: 'folders' } );
	}

	private static invalidateAssetsAndFolders( params: InvalidationParams ) {
		this.invalidateItems( params );
	}

	private static invalidateItems( {
		projectID, folderID, itemsType, refetchActiveQueries = false
	}: InvalidationParams & { itemsType?: ItemsType } ) {
		globalQueryClient.invalidateQueries( {
			...QueryFilters.forAssetAndFolderLists( { projectID, folderID, only: itemsType } ),
			refetchType: refetchActiveQueries ? 'active' : 'none'
		} );

		globalQueryClient.invalidateQueries( {
			queryKey: QueryKeys.forItemsCount( { projectID, folderID: folderID || null } ),
			refetchType: 'active'
		} );
	}

	private static invalidateFoldersTree( { projectID } : { projectID: number } ) {
		globalQueryClient.invalidateQueries( {
			queryKey: QueryKeys.forFolderTree( projectID ),
			refetchType: 'active'
		} );
	}

	private static extractingQueryLocation(
		{ assets = [], folders = [] }: { assets?: Asset[], folders?: Folder[] },
		callback: ( location: QueryLocation ) => void
	) {
		if ( assets.length === 0 && folders.length === 0 ) return;

		const { projectID, folderID } = assets[ 0 ] || folders[ 0 ];

		callback( { projectID, folderID } );
	}
}
