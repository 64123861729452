import { useState } from 'react';

type Option = {
	label: string,
	value: string
}

type UseOnboardingSurveyFieldParams = {
	stepNumber: number,
	title: string,
	description?: string,
	options: Option[],
	onContinue: () => void,
	optionBoxWidth: number,
	optionBoxHeight: number
}

const useOnboardingSurveyField = ( {
	stepNumber, title, description, options, onContinue, optionBoxWidth, optionBoxHeight
}: UseOnboardingSurveyFieldParams ) => {
	const [ selectedOption, setSelectedOption ] = useState<string>( '' );
	const onOptionSelected = ( option: string ) => setSelectedOption( prev => ( prev === option ? '' : option ) );

	return {
		stepNumber,
		title,
		description,
		options,
		selectedOption,
		onOptionSelected,
		onContinue,
		optionBoxWidth,
		optionBoxHeight
	}
}

export default useOnboardingSurveyField;
