import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OnboardingAnswers } from 'src/new_arch/modules/authentication/models/onboardingAnswers';
import useAuthSystem from '../../../new_arch/modules/authentication/system/useAuthSystem';
import { UserJson } from '../../../new_arch/models/user/user';
import { CURRENT_USER_KEY } from '../../../new_arch/modules/authentication/queries/keys';
import { useReduxTanStackSync } from '../../../new_arch/providers/ReduxTanStackSyncProvider';

const useUpdateCurrentUserOnboardingAnswers = ( userId: number ) => {
	const authSystem = useAuthSystem();
	const queryClient = useQueryClient();
	const sync = useReduxTanStackSync();

	return useMutation(
		( answers: OnboardingAnswers ) => authSystem.updateCurrentUserOnboardingAnswers( userId, answers ),
		{
			onSuccess: ( data: UserJson ) => {
				queryClient.setQueryData( CURRENT_USER_KEY, data );
				sync.currentUser( data );
			}
		}
	);
}

export default useUpdateCurrentUserOnboardingAnswers;
