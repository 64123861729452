import { Box, Group, Skeleton, Stack } from '@mantine/core';
import { CSSProperties } from 'react';

import './NotificationSkeleton.scss';

const NotificationSkeleton = ( { style }: { style: CSSProperties } ) => (
	<Group
		className="NotificationSkeleton"
		style={style}
		spacing={8}
	>
		<Box className="avatar">
			<Skeleton radius="xl" width={48} height={48} />
		</Box>
		<Stack className="content" justify="space-between" spacing={8}>
			<Stack className="content" justify="flex-start" spacing={8}>
				<Skeleton width={200} height={15} />
				<Skeleton width={120} height={15} />
			</Stack>
			<Group align="center" position="apart" spacing={2}>
				<Skeleton width={150} height={12} />
				<Skeleton width={50} height={12} />
			</Group>
		</Stack>
	</Group>
);

export default NotificationSkeleton;
