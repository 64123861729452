/* eslint-disable no-use-before-define */
import { comparatorBy } from 'src/new_arch/lib/comparators';
import { TreeData } from '../../../components/TreeView/types';
import type FolderTreeJson from './folderTreeJson';

type FolderTreeNodeParams = {
	id: number,
	type: 'project' | 'folder',
	name: string,
	children: FolderTreeNode[]
}

export default class FolderTreeNode {
	id: FolderTreeNodeParams[ 'id' ];
	type: FolderTreeNodeParams[ 'type' ];
	name: FolderTreeNodeParams[ 'name' ];
	children: FolderTreeNodeParams[ 'children' ];

	constructor( params: FolderTreeNodeParams ) {
		this.id = params.id;
		this.type = params.type;
		this.name = params.name;
		this.children = params.children;
	}

	static fromJson( json: FolderTreeJson, type: FolderTreeNodeParams[ 'type' ] ): FolderTreeNode {
		return new FolderTreeNode( {
			id: json.id,
			type,
			name: json.name,
			children: json.folders
				.sort( comparatorBy( 'name', { natural: true } ) )
				.map( folderJson => FolderTreeNode.fromJson( folderJson, 'folder' ) )
		} );
	}

	treeDataWithoutRoot( includeFolderIf: ( folderID: number ) => boolean ): TreeData {
		return this.children
			.filter( child => includeFolderIf( child.id ) )
			.map( child => ( {
				id: child.id,
				name: child.name,
				children: child.treeDataWithoutRoot( includeFolderIf )
			} ) );
	}

	findFolderNode( id: number ): FolderTreeNode | null {
		if ( this.id === id && this.type === 'folder' ) return this;

		// eslint-disable-next-line no-restricted-syntax
		for ( const child of this.children ) {
			const found = child.findFolderNode( id );
			if ( found ) return found;
		}

		return null;
	}
}
