import { createSelector } from 'reselect';
import AssetRound from '../entities/assetRound';
import { compareAsc } from '../lib/comparators';
import {
	getAssetIDFromProps, getAssetsWithCurrentRound, getSelectedAssets, getSelectedAssetsIDs
} from './assets';
import { getCurrentUserID } from './currentUser';

const getRawAssetRounds = state => state.assetRounds;

export const getAssetRounds = createSelector(
	[ getRawAssetRounds ],
	assetRoundsSerialization => assetRoundsSerialization
		.map( AssetRound.fromJSON )
);

export const getRoundsForAsset = createSelector(
	[ getAssetIDFromProps, getAssetRounds ],
	( assetID, assetRounds ) => assetRounds
		.valueSeq()
		.filter( round => round.belongsToAsset( assetID ) )
		.sortBy( round => round.number, compareAsc )
		.toArray()
);

export const getCurrentRoundForAsset = createSelector(
	[ getAssetIDFromProps, getAssetsWithCurrentRound ],
	( assetID, assets ) => {
		const asset = assets.get( assetID );
		return asset ? asset.currentRound : undefined;
	}
);

export const getCurrentRoundForSelectedAssets = createSelector(
	[ getSelectedAssetsIDs, getAssetsWithCurrentRound ],
	( assetsIDs, assets ) => ( assetsIDs
		? assetsIDs.map( ( id ) => {
			const asset = assets.get( id );
			return asset && asset.currentRound;
		} ).filter( round => round !== undefined )
		: [] )
);

const getSelectedAssetRoundID = state => state.selectedAssetRoundID;

export const getSelectedAssetRound = createSelector(
	[ getAssetIDFromProps, getSelectedAssetRoundID, getAssetRounds ],
	( assetID, selectedAssetRoundID, assetRounds ) => {
		if ( !selectedAssetRoundID ) { return undefined; }

		const round = assetRounds.get( selectedAssetRoundID );
		return round && round.belongsToAsset( assetID ) ? round : undefined;
	}
);

export const getSelectedAssetRoundOrCurrent = createSelector(
	[ getSelectedAssetRound, getCurrentRoundForAsset ],
	( selectedAssetRound, assetCurrentRound ) => (
		selectedAssetRound || assetCurrentRound
	)
);

export const getSelectedAssetRoundOrCurrentID = createSelector(
	[ getSelectedAssetRoundOrCurrent ],
	selectedOrCurrentAssetRound => (
		selectedOrCurrentAssetRound && selectedOrCurrentAssetRound.id
	)
);

export const getSelectedRoundIsCurrentRound = createSelector(
	[ getSelectedAssetRoundOrCurrent, getCurrentRoundForAsset ],
	( selectedAssetRound, assetCurrentRound ) => (
		selectedAssetRound && assetCurrentRound && selectedAssetRound.id === assetCurrentRound.id
	)
);

export const getCurrentUserUploadedCurrentRound = createSelector(
	[ getCurrentUserID, getCurrentRoundForAsset, getCurrentRoundForSelectedAssets ],
	( currentUserID, currentRound, currentRounds ) => (
		currentRound
			? currentRound.uploaderID === currentUserID
			: currentRounds.length > 0
			&& currentRounds.every( round => round.uploaderID === currentUserID )
	)
);

export const getSelectedAssetHasMoreThanOneRound = createSelector(
	[ getSelectedAssets ],
	selectedAssets => selectedAssets.every( asset => asset.roundsCount > 1 )
);

export const getUserIsComparingRounds = state => state.userIsViewingPreviousRound;

export const getPreviousAssetRoundFromSelectedAssetRound = createSelector(
	[ getRoundsForAsset, getSelectedAssetRoundOrCurrent ],
	( assetRounds, selectedAssetRoundOrCurrent ) => {
		const selectedRoundIndex = selectedAssetRoundOrCurrent
			? assetRounds.findIndex(
				round => round.id === selectedAssetRoundOrCurrent.id
			) : -1;
		return selectedRoundIndex > 0
			? assetRounds[ selectedRoundIndex - 1 ]
			: undefined;
	}
);

export const getPreviousAssetRoundIDFromSelectedAssetRound = createSelector(
	[ getPreviousAssetRoundFromSelectedAssetRound ],
	previousAssetRound => previousAssetRound && previousAssetRound.id
);

export const getShowingAssetRound = createSelector(
	[
		getSelectedAssetRoundOrCurrent,
		getPreviousAssetRoundFromSelectedAssetRound,
		getUserIsComparingRounds ],
	(
		selectedAssetRound,
		previousAssetRound,
		userIsViewingPreviousRound
	) => ( userIsViewingPreviousRound ? previousAssetRound : selectedAssetRound )
);

export const getShowingAssetRoundID = createSelector(
	[ getShowingAssetRound ],
	showingAssetRound => showingAssetRound && showingAssetRound.id
);

export const getShowingAssetRoundIsCurrentRound = createSelector(
	[ getShowingAssetRound, getCurrentRoundForAsset ],
	( showingAssetRound, assetCurrentRound ) => (
		showingAssetRound && assetCurrentRound && showingAssetRound.id === assetCurrentRound.id
	)
);
