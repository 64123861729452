import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useProjectSystem from '@projects/systems/useProjectSystem';
import { QueryKeys } from '@projects/queries/queryKeys';
import { updateQueryData } from 'src/new_arch/queries/updaters';
import { pinnedStatusUpdaterForProjectTree } from './utils';

const useProjectPinningMutation = ( projectID: number, action: 'pin' | 'unpin' ) => {
	const system = useProjectSystem();
	const queryClient = useQueryClient();
	const projectTreeKey = QueryKeys.forProjectTree();

	const onMutate = useCallback( async () => {
		await queryClient.cancelQueries( { queryKey: projectTreeKey } );

		const prevProjectTreeData = queryClient.getQueryData( projectTreeKey );

		updateQueryData(
			queryClient,
			projectTreeKey,
			pinnedStatusUpdaterForProjectTree( { projectID, isPinned: action === 'pin' } )
		);

		return { prevProjectTreeData };
	}, [ projectID, action ] );

	const onError = useCallback(
		( _error: unknown, _params: void, context?: { prevProjectTreeData: unknown } ) => {
			queryClient.setQueryData( projectTreeKey, context?.prevProjectTreeData );
		},
		[]
	);

	return useMutation(
		() => ( action === 'pin'
			? system.pinProject( projectID )
			: system.unpinProject( projectID ) ),
		{ onMutate, onError }
	);
}

export default useProjectPinningMutation;
