import { ProjectTreeItemJSON, ProjectTreeJSON } from '@projects/models/projectTree';
import { updateItems } from 'src/new_arch/queries/updaters';

export const pinnedStatusUpdaterForProjectTree = (
	{ projectID, isPinned }: { projectID: number, isPinned: boolean }
) => {
	const updatePinnedStatusIn = updateItems<ProjectTreeItemJSON>(
		[ projectID ],
		item => ( { ...item, is_pinned: isPinned } )
	);

	return ( data: ProjectTreeJSON ) => ( {
		...data,
		active: updatePinnedStatusIn( data.active )
	} );
};
