import ReactDOM from 'react-dom';
import { configure } from 'react-hotkeys';
import { Elements } from '@stripe/react-stripe-js';
import { Location } from 'history';

import 'react-tippy/dist/tippy.css';
import './index.css';
import App from './App';
import history from './navigation/history';
import { store } from './store/store';
import { sharedTracker } from './services/tracker/Tracker';
import initializeDirectFilesUploader from './services/directFilesUploaderInitializer';
import { initializeStripe } from './lib/stripe';

import './extensions/array';
import './extensions/file';
import './extensions/map';
import './extensions/math';
import './extensions/number';
import './extensions/object';
import './extensions/string';
import AppProvider from './new_arch/providers/AppProvider';
import GoogleTagManager from './new_arch/services/GoogleTagManager';

const isTargetTextAreaOrInputWithContent = ( event: KeyboardEvent ) => {
	const { target } = event;
	const isTextAreaOrInput = target instanceof HTMLTextAreaElement || target instanceof HTMLInputElement;

	return isTextAreaOrInput && target.value !== '';
}

const isArrowLeftOrRight = ( event: KeyboardEvent ) => (
	[ 'ArrowLeft', 'ArrowRight' ].includes( event.key )
);

GoogleTagManager.initialize();

configure( {
	ignoreTags: [],
	ignoreEventsCondition: event => (
		isTargetTextAreaOrInputWithContent( event ) && isArrowLeftOrRight( event )
	)
} );

initializeDirectFilesUploader( store );

sharedTracker.initialize();
const stripePromise = initializeStripe();

history.listen(
	( location: Location ) => sharedTracker.trackLocation( location )
);
sharedTracker.trackLocation( history.location );

ReactDOM.render(
	<Elements
		stripe={stripePromise}
		options={{
			fonts: [
				{ cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700' }
			]
		}}
	>
		<AppProvider>
			<App />
		</AppProvider>
	</Elements>,
	document.getElementById( 'root' )
);
