import { useState } from 'react';
import { INTENDED_USAGES, REFERRAL_SOURCES, ROLES } from 'src/new_arch/modules/authentication/models/onboardingAnswers';
import SnackbarSystem from '../../../systems/SnackbarSystem';
import useUpdateCurrentUserOnboardingAnswers from './useUpdateCurrentUserRole';
import useOnboardingSurveyField from './useOnboardingSurveyField';

type UseOnboardingSurveyPopupParams = {
	userId: number,
	snackbarSystem: SnackbarSystem,
	onClose: () => void
}

const useOnboardingSurveyPopup = ( { userId, snackbarSystem, onClose } : UseOnboardingSurveyPopupParams ) => {
	const { mutate, isLoading } = useUpdateCurrentUserOnboardingAnswers( userId );
	const [ currentStep, setCurrentStep ] = useState<number>( 1 );
	const advanceToNextStep = () => setCurrentStep( prev => prev + 1 );

	const roleField = useOnboardingSurveyField( {
		stepNumber: 1,
		title: 'Which best describes your role?',
		options: ROLES,
		onContinue: advanceToNextStep,
		optionBoxWidth: 186,
		optionBoxHeight: 100
	} );

	const intendedUsageField = useOnboardingSurveyField( {
		stepNumber: 2,
		title: 'What brings you to verybusy.io?',
		options: INTENDED_USAGES,
		onContinue: advanceToNextStep,
		optionBoxWidth: 388,
		optionBoxHeight: 156
	} );

	const referralSourceField = useOnboardingSurveyField( {
		stepNumber: 3,
		title: 'How did you hear about us?',
		options: REFERRAL_SOURCES,
		onContinue: () => {
			mutate(
				{
					role: roleField.selectedOption,
					intendedUsage: intendedUsageField.selectedOption,
					referralSource: referralSourceField.selectedOption
				},
				{
					onError: () => snackbarSystem.showErrorMessage( {
						title: 'Unknown Error',
						description: 'There was an error while saving your role.'
					} ),
					onSettled: onClose
				}
			);
		},
		optionBoxWidth: 186,
		optionBoxHeight: 100
	} );

	const currentField = [ roleField, intendedUsageField, referralSourceField ][ currentStep - 1 ];

	return {
		currentStep,
		currentField,
		isLoading
	};
}

export default useOnboardingSurveyPopup;
