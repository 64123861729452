import { ReactNode } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { Notifications } from '@mantine/notifications';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { MantineProvider } from '@mantine/core';
import { ApiProvider } from './ApiProvider';
import RootStoreProvider from './RootStoreProvider';
import PersistQueryClientProvider from './PersistQueryClientProvider';
import { persistor, store } from '../../store/store';
import history from '../../navigation/history';
import ReduxTanStackSyncProvider from './ReduxTanStackSyncProvider';

import theme from '../modules/ui/theme';

const AppProvider = ( { children }: { children: ReactNode } ) => (
	<RootStoreProvider>
		<ReduxProvider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConnectedRouter history={history}>
					<PersistQueryClientProvider>
						<ReduxTanStackSyncProvider>
							<Notifications />
							<ApiProvider>
								<MantineProvider
									withCSSVariables
									theme={theme}
								>
									{children}
								</MantineProvider>
							</ApiProvider>
						</ReduxTanStackSyncProvider>
						<ReactQueryDevtools initialIsOpen={false} />
					</PersistQueryClientProvider>
				</ConnectedRouter>
			</PersistGate>
		</ReduxProvider>
	</RootStoreProvider>
);

export default AppProvider;
