import { ComponentType, useCallback, useRef } from 'react'
import { FixedSizeList } from 'react-window';
import useMarginBottomOnElement from '../../../hooks/useMarginBottomOnElement';
import { ListProps, ListSizeProps } from './types';
import ListRow, { ListRowProps } from './ListRow';
import useItemKey from './useItemKey';
import useListContext from './useListContext';

/*
TODO: refactor this component to make it support the 'gap' prop just like Grid does.

A component for rendering a list of arbitrary items.

Keep in mind:
- It is virtualized (with react-window).
- It grows vertically.
- Exact row height must be provided.
- It automatically covers the entire width and height it has available.
- The `List` itself does not render any element for the rows; you must do
it in the `renderItem` callback applying the `cssStyle` received.
- If the components you render inside the rows are expensive, it is
recommended to memoize them, since the `renderItem` callbacks are called
constantly while scrolling.
*/
const List = <TItem, >( {
	items,
	renderItem,
	itemKeyExtractor,
	loaderCount,
	renderLoader,
	width,
	height,
	rowHeight: unroundedRowHeight,
	overscanRowCount,
	onScrollEndReached,
	bottomEmptySpace = 0,
	scrollableContainerRef
}: ListProps<TItem> & ListSizeProps ) => {
	// We round row height to avoid false positives or negatives on
	// scroll end detection.
	// TODO: scroll end detection method is currently too basic. When improved,
	// consider removing this rounding operation.
	const rowHeight = Math.round( unroundedRowHeight );

	const rowCount = Math.ceil( items.length + loaderCount );
	const fullListHeight = rowHeight * rowCount;

	const listContext = useListContext<TItem>( {
		items,
		loaderCount,
		renderItem,
		renderLoader,
		itemKeyExtractor
	} );

	const itemKey = useItemKey<TItem>();

	const onScroll = useCallback( ( { scrollOffset }: { scrollOffset: number } ) => {
		if ( scrollOffset + height >= fullListHeight ) {
			onScrollEndReached?.();
		}
	}, [ onScrollEndReached, height, fullListHeight ] );

	const innerContainerRef = useRef<HTMLDivElement | null>( null );
	useMarginBottomOnElement( innerContainerRef, bottomEmptySpace );

	return (
		<FixedSizeList
			width={width}
			height={height}
			itemCount={rowCount}
			itemSize={rowHeight}
			itemData={listContext}
			itemKey={itemKey}
			overscanCount={overscanRowCount}
			onScroll={onScroll}
			outerRef={scrollableContainerRef}
			innerRef={innerContainerRef}
		>
			{ListRow as ComponentType<ListRowProps<TItem>>}
		</FixedSizeList>
	);
}

export default List;
