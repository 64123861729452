import { QueryClient, useQuery } from '@tanstack/react-query';
import ProjectTree, { ProjectTreeJSON } from '@projects/models/projectTree';
import { queryClient as globalQueryClient } from 'src/store/store';
import useProjectSystem from '../systems/useProjectSystem';
import { QueryKeys } from './queryKeys';
import { DEFAULT_QUERY_OPTIONS } from './constants';

const useFetchProjectTreeQuery = () => {
	const system = useProjectSystem();

	return useQuery(
		QueryKeys.forProjectTree(),
		() => system.fetchProjectTree(),
		{ ...DEFAULT_QUERY_OPTIONS, select: ProjectTree.fromJson }
	);
};

export const invalidateProjectTreeQuery = () => {
	globalQueryClient.invalidateQueries( {
		queryKey: QueryKeys.forProjectTree(),
		refetchType: 'active'
	} );
}

export const getProjectFromTree = ( queryClient: QueryClient, projectID: number ) => {
	const projectTree = queryClient.getQueryData(
		QueryKeys.forProjectTree()
	) as ProjectTreeJSON | undefined;

	if ( !projectTree ) return null;

	return ProjectTree.fromJson( projectTree ).findProject( projectID );
}

export default useFetchProjectTreeQuery;
