import { MantineColor } from '@mantine/core';

export default {
	primary: [
		'#E3F2FF', '#BBDDFF', '#8FC8FF', '#60B2FF', '#3DA1FF',
		'#1C91FF', '#2183F0', '#2171DC', '#215FCA', '#1F40AB'
	],
	secondary: [
		'#E4EBFE', '#BFCEE3', '#9DACC5', '#788BA7', '#5F7191',
		'#455C7E', '#384F6E', '#293D59', '#1B2C43', '#061324'

	],
	gray: [
		'#F5F5F5', '#E9E9E9', '#D9D9D9', '#C4C4C4', '#9D9D9D',
		'#7B7B7B', '#555555', '#434343', '#262626', '#000000'
	],
	red: [
		'#FFECEF', '#FFD0D5', '#F49FA0', '#EC7A7A', '#F85B58',
		'#FD4B3E', '#EF433E', '#DD3938', '#D03231', '#C12722'
	],
	green: [
		'#E6F9EE', '#B3EECC', '#80e3aa', '#4dd787', '#1acc65',
		'#00C654', '#009e43', '#008B3B', '#007732', '#004F22'
	],
	orange: [
		'#FFF4E4', '#FFE3BB', '#FFD091', '#FFBD67', '#FFAF40',
		'#FFA23F', '#FA973B', '#F38837', '#EB7A34', '#DF6431'
	]
} as Partial<Record<MantineColor, string[]>>;
