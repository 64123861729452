import { useRef } from 'react';
import { Box, Group, Table, Image, useMantineTheme, MantineColor } from '@mantine/core';
import Text from '@ui/components/Text/Text';
import Icon from 'src/new_arch/components/Icon/Icon';

import BadgeSelect from './BadgeSelect/BadgeSelect';
import { CreateAction, MatchWithAction } from './types';

import styles from './MatchedNewRoundsConfirmationPopup.module.scss';

type MachesTableProps = {
	matches: MatchWithAction[],
	onActionChange?: ( assetFilename: string, action: CreateAction ) => void
}

type CreateActionOption = {
	value: CreateAction,
	label: string,
	color: MantineColor
};

const CREATE_ACTION_OPTIONS: CreateActionOption[] = [
	{ value: 'new-round', label: 'New round', color: 'green' },
	{ value: 'new-asset', label: 'New asset', color: 'orange' }
];

const MachesTable = ( {
	matches, onActionChange
}: MachesTableProps ) => {
	const scrollableRef = useRef<HTMLDivElement>( null );
	const theme = useMantineTheme();

	return (
		<Box className={styles.MatchedNewRoundsConfirmationPopup__Table} ref={scrollableRef}>
			<Table>
				<thead>
					<tr>
						<th><Text variant="caption-semibold" color="gray.5">Current Asset</Text></th>
						<th><Text variant="caption-semibold" color="gray.5">New Asset</Text></th>
						<th><Text variant="caption-semibold" color="gray.5">Upload as</Text></th>
					</tr>
				</thead>
				<tbody>
					{matches.map( match => (
						<tr key={match.newAssetFilename}>
							<td>
								<Group p={4} spacing={4} position="apart" noWrap>
									<Group spacing={4} noWrap>
										<Image fit="contain" radius="sm" width={30} height={30} src={match.currentAsset.thumbnailURL} alt={match.currentAsset.filename} />
										<Text variant="footnote" truncate="start" color="gray.5">{match.currentAsset.filename}</Text>
									</Group>
									<Icon icon="keyboard_arrow_right" color={theme.fn.themeColor( 'gray', 2 )} />
								</Group>
							</td>
							<td>
								<Text variant="footnote" truncate="start">
									{match.matchedText}
									{match.newAssetFilename !== match.matchedText && (
										<strong style={{ fontWeight: 600 }}>{match.unmatchedText}</strong>
									)}
								</Text>
							</td>
							<td>
								<BadgeSelect
									options={CREATE_ACTION_OPTIONS}
									value={match.selectedAction || 'new-round'}
									onChange={option => onActionChange?.( match.newAssetFilename, option.value )}
									containerRef={scrollableRef}
									label="Upload as"
								/>
							</td>
						</tr>
					) )}
				</tbody>
			</Table>
		</Box>
	);
};

export default MachesTable;
