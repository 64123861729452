import Button from 'src/new_arch/components/Button/Button';
import Icon from 'src/new_arch/components/Icon/Icon';
import NotificationsProjectThumbnail
	from './NotificationsProjectThumbnail/NotificationsProjectThumbnail';

import { BLACK } from '../../../../../../styles/colors';

import './NotificationsProjectsSidebar.scss';
import useNotificationsProjectsSidebar from './useNotificationsProjectsSidebar';

type NotificationsProjectsSidebarProps = {
	selectedProjectID: number | null,
	onProjectClick: ( projectID: number ) => void,
};

const NotificationsProjectsSidebar = ( {
	selectedProjectID, onProjectClick
}: NotificationsProjectsSidebarProps ) => {
	const {
		containerRef,
		currentPageProjects,
		upButtonIsEnabled,
		downButtonIsEnabled,
		onUpButtonClicked,
		onDownButtonClicked
	} = useNotificationsProjectsSidebar( {
		selectedProjectID
	} );

	return (
		<div className="NotificationsProjectsSidebar" ref={containerRef}>
			<Button
				type="ghost"
				disabled={!upButtonIsEnabled}
				onClick={onUpButtonClicked}
			>
				<Icon icon="keyboard_arrow_up" size={16} color={BLACK} />
			</Button>
			<div className="projects-container">
				{ currentPageProjects.map(
					project => (
						<NotificationsProjectThumbnail
							key={project.id}
							project={project}
							highlighted={project.id === selectedProjectID}
							onClick={onProjectClick}
						/>
					) )}
			</div>
			<Button
				type="ghost"
				disabled={!downButtonIsEnabled}
				onClick={onDownButtonClicked}
			>
				<Icon icon="keyboard_arrow_down" size={16} color={BLACK} />
			</Button>
		</div>
	);
};

export default NotificationsProjectsSidebar;
