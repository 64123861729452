import FilenamesMatchResult from '@uploads/models/FilenamesMatchResult';
import { ASSETS_URL } from 'src/config/urls';
import {
	UPDATE_ASSET_UPLOAD_PROGRESS,
	UPDATE_ASSET_UPLOAD_STATUS,
	CREATE_FILE_UPLOADS,
	CANCEL_ALL_ASSET_UPLOADS,
	CREATE_FOLDER_UPLOADS,
	UPDATE_FOLDER_UPLOAD_STATUS,
	CLEAR_FINISHED_UPLOADS,
	CHECK_ASSET_FILENAMES_MATCHES
} from './types';
import { ITEM_UPLOAD_STATUS } from '../services/DirectFilesUploaderCoordinator';
import { directFilesUploader, directFilesUploaderSubscriber } from '../services/directFilesUploaderInitializer';
import { renameFile, getParsedFileName } from '../lib/fileUtils';

const parseFile = file => renameFile( file, getParsedFileName( { filename: file.name } ) );

export const createFileUploads = ( {
	projectID, folderID, parentUploadID, files, newRounds = []
} ) => {
	const parsedFiles = files.map( parseFile );

	return ( {
		type: CREATE_FILE_UPLOADS,
		payload: directFilesUploader
			.addFiles( parsedFiles )
			.map( ( upload ) => {
				const uploadingRoundForAsset = newRounds.find(
					round => getParsedFileName( { filename: round.filename } ) === upload.file.name
				);

				return ( {
					id: upload.id,
					projectID,
					folderID,
					status: ITEM_UPLOAD_STATUS.WAITING,
					file: upload.file,
					bytesUploaded: 0,
					parentUploadID,
					uploadingRoundForAsset: !!uploadingRoundForAsset,
					assetID: uploadingRoundForAsset?.assetID
				} );
			} )
	} );
};

export const createFolderUploads = params => ( {
	type: CREATE_FOLDER_UPLOADS,
	payload: directFilesUploaderSubscriber
		.createFolderUploads( params )
		.map( upload => ( {
			id: upload.id,
			status: upload.status,
			bytesUploaded: 0,
			projectID: upload.projectID,
			organizerID: upload.organizerID,
			organizerType: upload.organizerType,
			directory: upload.directory,
			parentUploadID: upload.parentUploadID
		} ) )
} );

export const checkAssetFilenamesMatches = ( {
	projectID, folderID, files
} ) => ( dispatch, _, api ) => {
	const filenames = files.map( file => getParsedFileName( { filename: file.name } ) );

	return dispatch( {
		type: CHECK_ASSET_FILENAMES_MATCHES,
		promise: api
			.post(
				`${ASSETS_URL}/match`,
				{ filenames, project_id: projectID, folder_id: folderID }
			)
			.then( response => FilenamesMatchResult.fromJson( response ) )
	} );
};

export const updateAssetUploadProgress = ( id, { totalBytesUploaded, newBytesUploaded, parentUploadID } ) => ( {
	type: UPDATE_ASSET_UPLOAD_PROGRESS,
	payload: { totalBytesUploaded, newBytesUploaded },
	meta: { id, parentUploadID }
} );

export const updateUploadStatus = ( id, status ) => ( {
	type: UPDATE_ASSET_UPLOAD_STATUS,
	payload: status,
	meta: { id }
} );

export const updateFolderUploadStatus = ( id, status ) => ( {
	type: UPDATE_FOLDER_UPLOAD_STATUS,
	payload: status,
	meta: { id }
} );

export const cancelAssetUpload = ( uploadID ) => {
	directFilesUploader.cancelUpload( uploadID );
	return updateUploadStatus( uploadID, ITEM_UPLOAD_STATUS.ABORTED );
};

export const cancelAllAssetUploads = () => {
	directFilesUploaderSubscriber.cancelAll();
	return { type: CANCEL_ALL_ASSET_UPLOADS };
};

export const clearFinishedUploads = () => ( { type: CLEAR_FINISHED_UPLOADS } );
