import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

import ProjectWithNotificationCount, { ProjectWithNotificationCountJSON }
	from '@notifications/models/ProjectWithNotificationCount';

import { QueryKeys } from './queryKeys';

import useNotificationSystem from '../systems/useNotificationSystem';
import { DEFAULT_QUERY_OPTIONS } from './constants';

export type ProjectWithNotificationCountFetchResult = ProjectWithNotificationCountJSON[];

const sortByLastNotificationAt = (
	aProject: ProjectWithNotificationCount, otherProject: ProjectWithNotificationCount
) => {
	if ( aProject.lastNotificationAt === null && otherProject.lastNotificationAt === null ) return 0;
	if ( aProject.lastNotificationAt === null ) return 1;
	if ( otherProject.lastNotificationAt === null ) return -1;
	return otherProject.lastNotificationAt.getTime() - aProject.lastNotificationAt.getTime();
};

const useProjectsWithUnseenNotificationsQuery = () => {
	const system = useNotificationSystem();

	const select = useCallback( ( data: ProjectWithNotificationCountFetchResult ) => (
		data
			.map( ProjectWithNotificationCount.fromJSON )
			.sort( sortByLastNotificationAt )
	), [] );

	return useQuery(
		QueryKeys.forProjects(),
		() => system.fetchProjectsWithUnseenNotifications(),
		{
			select,
			...DEFAULT_QUERY_OPTIONS
		}
	);
}

export default useProjectsWithUnseenNotificationsQuery;
