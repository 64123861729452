import { useState, useEffect } from 'react';

export interface Serializable<SerializationType extends object = object> {
	toJSON(): SerializationType;
}

export interface SerializableClass<
SerializationType extends object, Type extends Serializable<SerializationType>
> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	new( ...args: any[] ): Type;
	fromJSON( serialization: object ): Type;
}

export function usePersistentState<
	SerializationType extends object,
	Type extends Serializable<SerializationType>,
	TypeClass extends SerializableClass<SerializationType, Type>
>(
	key: string,
	initialState: Type | ( () => Type )
): [Type, React.Dispatch<React.SetStateAction<Type>>] {
	const SerializableClass: TypeClass = initialState.constructor as TypeClass;
	const prefixedKey = `persisted-state/${key}`

	const [ value, setValue ] = useState<Type>( () => {
		const storedValue = localStorage.getItem( prefixedKey );
		if ( storedValue === null ) {
			if ( typeof initialState === 'function' ) {
				return ( initialState as () => Type )();
			}
			return initialState;
		}
		return SerializableClass.fromJSON(
			JSON.parse( storedValue ) as SerializationType
		);
	} );

	useEffect( () => {
		localStorage.setItem(
			prefixedKey, JSON.stringify( value.toJSON() )
		);
	}, [ value, prefixedKey ] );

	return [ value, setValue ];
}
