import { sharedTracker } from '../Tracker';
import events from '../events';

export const trackNotificationsPanelOpened = () => sharedTracker
	.trackNotificationAction( {
		action: events.notification.NOTIFICATIONS_PANEL_OPENED
	} );

export const trackNotificationSettingsOpenedFromPanel = () => sharedTracker
	.trackNotificationAction( {
		action: events.notification.NOTIFICATION_SETTINGS_OPENED_FROM_PANEL
	} );

export const trackNotificationOpened = notification => sharedTracker
	.trackNotificationAction( {
		action: events.notification.NOTIFICATION_OPENED,
		type: notification.type
	} );

export const trackNotificationProjectOpened = notification => sharedTracker
	.trackNotificationAction( {
		action: events.notification.NOTIFICATION_PROJECT_OPENED,
		project_id: notification.projectID,
		type: notification.type
	} );

export const trackNotificationFolderOpened = notification => sharedTracker
	.trackNotificationAction( {
		action: events.notification.NOTIFICATION_FOLDER_OPENED,
		project_id: notification.projectID,
		folder_id: notification.folderID,
		type: notification.type
	} );
