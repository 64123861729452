import { CSSProperties, ReactNode, useMemo } from 'react';

type UseListContextParams<TItem> = {
	items: TItem[],
	loaderCount: number,
	renderItem: ( item: TItem, rowStyle: CSSProperties, index: number ) => ReactNode,
	renderLoader: ( cellStyle: React.CSSProperties ) => ReactNode,
	itemKeyExtractor: ( item: TItem, index: number ) => string | number
};

const useListContext = <TItem>( {
	items, loaderCount, renderItem, renderLoader, itemKeyExtractor
}: UseListContextParams<TItem> ) => useMemo( () => ( {
		itemAt: ( index: number ) => items[ index ],
		itemCount: items.length,
		loaderCount,
		renderItem,
		renderLoader,
		itemKeyExtractor
	} ), [
		items,
		loaderCount,
		renderItem,
		renderLoader,
		itemKeyExtractor
	] );

export default useListContext;
