import { useMemo } from 'react';
import { useApi } from '../../../providers/ApiProvider';
import NotificationPreferenceSystem from './NotificationPreferenceSystem';

const useNotificationPreferenceSystem = () => {
	const api = useApi();

	return useMemo( () => new NotificationPreferenceSystem( { api } ), [ api ] );
};

export default useNotificationPreferenceSystem;
