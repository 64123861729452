import ItemUpload from './itemUpload';
import { displayTimeLeft } from '../lib/dateUtils';
import { ASSET_UPLOAD_ERRORS, ITEM_UPLOAD_STATUS } from '../services/DirectFilesUploaderCoordinator';

class AssetUpload extends ItemUpload {
	constructor(
		id,
		projectID,
		file,
		status,
		bytesUploaded,
		startedAt,
		assetID,
		uploadingRoundForAsset,
		folderID,
		parentUploadID,
		path,
		errorName
	) {
		super();
		this.id = id;
		this.projectID = projectID;
		this.file = file;
		this.status = status;
		this.bytesUploaded = bytesUploaded;
		this.startedAt = startedAt;
		this.assetID = assetID;
		this.uploadingRoundForAsset = uploadingRoundForAsset;
		this.folderID = folderID;
		this.parentUploadID = parentUploadID;
		this.path = path;
		this.errorName = errorName;
	}

	get name() {
		return this.file.name;
	}

	get description() {
		return this.uploadingRoundForAsset ? 'New round' : 'New asset';
	}

	get size() {
		return this.file.size;
	}

	get progress() {
		if (
			this.status === ITEM_UPLOAD_STATUS.SUCCEEDED
			|| this.status === ITEM_UPLOAD_STATUS.CREATING_ASSET
		) { return 100; }

		if (
			!Number.isNaN( this.size )
			&& this.status === ITEM_UPLOAD_STATUS.UPLOADING
		) {
			return Math.floor( ( this.bytesUploaded * 100 ) / this.size );
		}

		return 0;
	}

	get isInProgress() {
		return this.status === ITEM_UPLOAD_STATUS.UPLOADING
			&& this.startedAt
			&& this.bytesUploaded !== 0;
	}

	get formattedETA() {
		const { ETA } = this;
		return displayTimeLeft( ETA );
	}

	get elapsedTime() {
		if ( !this.isInProgress ) {
			return 0;
		}
		return new Date().getTime() - this.startedAt.getTime();
	}

	get uploadSpeedAvailable() {
		return this.elapsedTime > 500;
	}

	get uploadSpeed() {
		if ( !this.isInProgress ) {
			return 0;
		}
		return this.uploadSpeedAvailable ? this.bytesUploaded / this.elapsedTime : undefined;
	}

	get ETA() {
		if ( !this.isInProgress ) {
			return 0;
		}

		const elapsedTime = new Date().getTime() - this.startedAt.getTime();
		const bytesUploadedPerMilisecond = this.bytesUploaded / elapsedTime;
		const estimatedTotalTime = this.size / bytesUploadedPerMilisecond;

		return estimatedTotalTime - elapsedTime;
	}

	get errorMessage() {
		if ( this.status !== ITEM_UPLOAD_STATUS.FAILED ) {
			return '';
		}

		if ( this.errorName === ASSET_UPLOAD_ERRORS.APPROVED_ASSET ) {
			return 'This asset is already approved';
		}

		return 'An unexpected error occurred';
	}

	get isFolderUpload() {
		return false;
	}

	clone() {
		return new AssetUpload(
			this.id,
			this.projectID,
			this.file,
			this.status,
			this.bytesUploaded,
			this.startedAt,
			this.assetID,
			this.uploadingRoundForAsset,
			this.folderID,
			this.parentUploadID,
			this.path,
			this.errorName
		);
	}

	withPath( path ) {
		return this.set( 'path', path );
	}
}

export default AssetUpload;
