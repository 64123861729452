import { useEffect, useState } from 'react';
import Tree, { type TreeOptions } from './Tree';
import { type TreeData } from './types';

const useTree = ( data: TreeData, { initiallyOpenNodes, onNodeOpenChanged }: TreeOptions ) => {
	const [ tree ] = useState( () => new Tree( data, { initiallyOpenNodes, onNodeOpenChanged } ) );

	useEffect( () => tree.update( data ), [ data ] );

	return tree;
}

export default useTree;
