import { NotificationPreferencesJSON } from '@notifications/models/NotificationPreferences';
import { NOTIFICATIONS_PREFERENCES_URL } from 'src/config/urls';
import Api from 'src/services/Api';

export default class NotificationPreferenceSystem {
	private api: Api;

	constructor( { api }: { api: Api } ) {
		this.api = api;
	}

	async fetchNotificationPreferences(): Promise<NotificationPreferencesJSON> {
		const { response } = await (
			this.api.get( NOTIFICATIONS_PREFERENCES_URL ) as Promise<{ response: NotificationPreferencesJSON }>
		);

		return response;
	}

	async updateNotificationPreferences(
		preferences: NotificationPreferencesJSON
	): Promise<NotificationPreferencesJSON> {
		await (
			this.api.put( NOTIFICATIONS_PREFERENCES_URL, preferences ) as Promise<void>
		)

		return preferences;
	}
}
