import { NotificationJSON } from '@notifications/models/Notification';
import { NotificationsFetchResult } from '@notifications/systems/NotificationSystem';
import { InfiniteData } from '@tanstack/react-query';

type NotificationsQueryData = InfiniteData<NotificationsFetchResult> | undefined;

export const updateNotificationsInPaginatedData = (
	idsToUpdate: number[],
	updater: ( notification: NotificationJSON ) => NotificationJSON
) => ( data: NotificationsQueryData ): NotificationsQueryData => (
	data ? ( {
		...data,
		pages: data.pages.map(
			page => ( {
				...page,
				notifications: page.notifications.map(
					notification => (
						idsToUpdate.includes( notification.id )
							? updater( notification )
							: notification
					)
				)
			} )
		)
	} ) : data );

export const updateAllNotificationsInPaginatedData = (
	updater: ( notification: NotificationJSON ) => NotificationJSON
) => ( data: NotificationsQueryData ): NotificationsQueryData => (
	data ? ( {
		...data,
		pages: data.pages.map(
			page => ( {
				...page,
				notifications: page.notifications.map(
					notification => updater( notification )
				)
			} )
		)
	} ) : data );
