import { Divider, Stack, Title, Group, Checkbox } from '@mantine/core';
import Text from '@ui/components/Text/Text';

import FilenamesMatchResult from '../../models/FilenamesMatchResult';
import styles from './MatchedNewRoundsConfirmationPopup.module.scss';
import MatchesTable from './MatchesTable';
import Header from './Header';
import Footer from './Footer';

import useMatchedNewRoundsConfirmationPopup from './useMatchedNewRoundsConfirmationPopup.ts';
import { FilenameWithAssetID } from './types';

export type MatchedNewRoundsConfirmationPopupProps = {
	matchResult: FilenamesMatchResult,
	onConfirm?: ( selectedForNewRounds: FilenameWithAssetID[] ) => void,
	onCancel?: () => void
};

const MatchedNewRoundsConfirmationPopup = ( {
	matchResult,
	onCancel,
	onConfirm
}: MatchedNewRoundsConfirmationPopupProps ) => {
	const {
		partialMatchesCount,
		total,
		matches,
		onActionChange,
		onCancelation,
		setAllAsNewAssets,
		allSelectedAsNewAssets,
		onConfirmation
	} = useMatchedNewRoundsConfirmationPopup( { matchResult, onCancel, onConfirm } );

	return (
		<Stack justify="flex-start" spacing={0} className={styles.MatchedNewRoundsConfirmationPopup}>
			<Header />
			<Divider color="gray.1" />
			<Stack p={16} spacing={12}>
				<Group spacing={8} position="apart" align="flex-end">
					<Stack spacing={7}>
						<Title order={5}>{partialMatchesCount} Matches</Title>
						<Text color="gray">
							{partialMatchesCount} out of {total} assets have similar names to existing files. <br />
							Choose to upload each as a new file or as a new round to an existing one.
						</Text>
					</Stack>
					<Checkbox
						checked={allSelectedAsNewAssets}
						onChange={setAllAsNewAssets}
						label="Upload all as new assets"
						size="xs"
					/>
				</Group>
				<MatchesTable matches={matches} onActionChange={onActionChange} />
			</Stack>
			<Divider color="gray.1" />
			<Footer onCancel={onCancelation} onConfirm={onConfirmation} />
		</Stack>
	)
};

export default MatchedNewRoundsConfirmationPopup;
