import useNotificationsSidePanel from './useNotificationsSidePanel';
import NotificationsProjectsSidebar from './NotificationsProjectsSidebar/NotificationsProjectsSidebar';
import NotificationsList from './NotificationsList/NotificationsList';
import NotificationsHeader from './NotificationsHeader/NotificationsHeader';

import './NotificationsSidePanel.scss';

export type NotificationsSidePanelProps = {
	onClose?: () => void
}

const NotificationsSidePanel = ( {
	onClose
}: NotificationsSidePanelProps ) => {
	const {
		selectedProjectID,
		selectedFilter,
		onFilterChange,
		onProjectClick,
		backdropStyle,
		panelStyle,
		onResetFiltersClick,
		onClosePanel,
		onTransitionEnd
	} = useNotificationsSidePanel( { onClose } );

	return (
		<div className="NotificationSidePanel">
			<div className={backdropStyle} onClick={onClosePanel} />
			<div className={panelStyle} onTransitionEnd={onTransitionEnd}>
				<NotificationsHeader
					onClose={onClosePanel}
					selectedFilter={selectedFilter}
					onFilterChange={onFilterChange}
				/>
				<div className="notifications">
					{selectedProjectID ? (
						<NotificationsList
							projectID={selectedProjectID}
							selectedFilter={selectedFilter}
							onResetFiltersClick={onResetFiltersClick}
						/>
					) : <div className="empty-list" />}
					<NotificationsProjectsSidebar
						selectedProjectID={selectedProjectID}
						onProjectClick={onProjectClick}
					/>
				</div>
			</div>
		</div>
	);
};

export default NotificationsSidePanel;
