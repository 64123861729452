import { useMemo } from 'react';
import { Text as MantineText, TextProps as MantineTextProps } from '@mantine/core';
import { textVariants } from '@ui/tokens/typography/typography';

type TextVariant = keyof typeof textVariants;
interface TextProps extends MantineTextProps {
	variant?: TextVariant
}

const Text = ( { variant = 'body', style: styleProp, ...props }: TextProps = {} ) => {
	const style = useMemo( () => (
		{ ...textVariants[ variant ], ...( styleProp || {} ) }
	), [ styleProp, variant ] );

	return <MantineText style={style} {...props} />
};

export default Text;
