import { CSSObject } from '@mantine/core';
import { textVariants } from '@ui/tokens/typography/typography';
import { MantineThemeComponent } from '../../types';

// Workaround to default styles in DesignSystem.scss that set
// ul and li list-style-type to none
const CheckboxDefaultConfig: MantineThemeComponent = {
	styles: (): Record<string, CSSObject> => ( {
		body: {
			alignItems: 'center',
			gap: '4px'
		},
		label: {
			paddingLeft: 0,
			...( textVariants.footnote as CSSObject )
		}
	} )
};

export default CheckboxDefaultConfig;
