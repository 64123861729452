import ProjectUploadsList from '../../ProjectUploadsList/ProjectUploadsList';
import styles from './UploadsList.module.scss';

type UploadsListProps = {
	projectIDs: number[]
};

const UploadsList = ( { projectIDs }: UploadsListProps ) => (
	<div className={styles.UploadsList}>
		{projectIDs.map( projectID => (
			<ProjectUploadsList key={projectID} projectID={projectID} />
		) )}
	</div>
);

export default UploadsList;
