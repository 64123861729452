import Icon from 'src/new_arch/components/Icon/Icon';
import { MaterialIconString } from 'src/new_arch/components/Icon/MaterialIcon/MaterialIcon';

import './NotificationOptionText.scss';

type NotificationOptionTextProps = {
	icon?: MaterialIconString;
	title: string;
	description: string;
	className?: string;
};

const NotificationOptionText = ( { icon, title, description, className } : NotificationOptionTextProps ) => (
	<div className={`NotificationOptionText ${className}`}>
		<div className="icon">
			{icon && <Icon icon={icon} size={16} />}
		</div>
		<div className="text">
			<h3 className="title">{title}</h3>
			<p className="description">{description}</p>
		</div>
	</div>
);

export default NotificationOptionText;
